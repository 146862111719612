import { Knex } from 'knex'
import { castValuesWithColumnType, FindFilter, ModelFactory } from '../../common/$models'
import { parseValidationConfigIntoColumnDefValidateProp } from '../../common/modelDefinitions/validationConfigs'
import { dateFormatter, unFlattenObject } from '../../common/utils'
import { ColumnDefByColName } from '../../types'
import { validateWithColDef } from '../ModelForm/ModelInput/validateWithColDef'

export interface ImportType {
  key: string
  modelName: string
  virtualModelName: string
  fieldConvertDefinition: FieldConvertDefinition
  reformatExcelJson?: (excelRawRows: any[]) => any[]
}

interface FieldConvertDefinition {
  [keyName: string]: (originalRow, saveRecord) => any | string | false
}

const fixedExcludeFields = [
  'createdAt',
  'updatedAt',
  '作成日時',
  '更新日時',
  'objectID',
  undefined,
  null,
]

interface DataImportSettingRecord {
  name: string
  targetModelName: string
  desc: string
  pathThroughOriginalProps: boolean
  excludeFields: string[]
  useDateConversion: boolean
  dateConversionFormat: string
  useBeforeFormatFunction: boolean
  beforeFormatFunction: string
  useAfterFormatFunction: boolean
  afterFormatFunction: string
  useBeforeSaveFunction: boolean
  beforeSaveFunction: string
  useAfterSaveFunction: boolean
  afterSaveFunction: string
  beforeEachRowConvertFunction: string
  fields: {
    dataCol: string
    tCol: string
    behavior: 'direct' | 'func'
    tFunc: string
    execPriority: number
    // validationConfigs?: any[] => 廃止
    // useColumnDefValidation: boolean => 廃止
  }[]
  disableImportIfHasValidationErrors: boolean
  validationConfigsByImportColumns?: {
    targetCol: string
    // 取込時の Validation 設定 の 配列
    validationConfigs: any[]
    // もともとの カラムの Validation を使用するかどうか
    useColumnDefValidation: boolean
  }[]
  convertSettingsToRelationId?: {
    relationModelName: string
    relationIdCol: string
    targetCol: {
      dataCol: string // 取込データ列名
      targetCol: string // 変換先モデルの 検索・照合対象カラム
      matchType: string // 照合タイプ
    }[]
  }[]
  // 重複チェックの設定
  duplicateCheckSettings: {
    dataCol: string // 取込データ列名
    targetCol: string // 重複チェック対象カラム
    // matchType: string  // 照合タイプ は常に _eq を使用する
  }[]
}

/**
 *
 */
export class ImportServiceWithDataImportSetting {
  public importTypes: { [key: string]: ImportType }
  public currentSaveTarget: { importType: ImportType | null; data: any[] }
  public dataImportSettingName: string
  public dataImportSettingRecord: DataImportSettingRecord
  public originalDataRows: any[]
  public formattedDataRows: any[]
  public options: {
    useUnflatten: boolean
  }
  public dataForFormattedDataFields: any
  public loadedModels: { [modelName: string]: any }
  public findFunction: (modelName: string, findParam: any) => Promise<any[]>
  public transaction: Knex.Transaction | null
  public displayErrorMessages: string[] = []
  public displayWarningMessages: string[] = []
  public rowValidationFunction: (
    row: Record<string, any>,
    originalRow: Record<string, any>,
  ) => Promise<string[]>
  public progressIfModelNotFound = false

  constructor(
    dataImportSettingName,
    { useUnflatten = true } = {},
    dataImportSettingRecord: DataImportSettingRecord | any = null,
    $models = null,
  ) {
    this.importTypes = {}
    this.currentSaveTarget = { importType: null, data: [] }
    this.dataImportSettingName = dataImportSettingName
    if (dataImportSettingRecord) {
      this.dataImportSettingRecord = dataImportSettingRecord
    }
    this.options = { useUnflatten }
    this.dataForFormattedDataFields = {}
    this.findFunction = async (modelName, findParam) => {
      return globalThis.$core?.$models?.[modelName]?.find(findParam) || []
    }
  }

  async _initData(): Promise<void> {
    if (this.dataImportSettingRecord) {
      return
    }
    await this._setDataImportSettingRecord()
    if (!this.dataImportSettingRecord) {
      throw new Error(
        `[ImportServiceWithDataImportSetting] 設定名 "${this.dataImportSettingName}" のインポート設定が見つかりませんでした`,
      )
    }
    if (!this.targetModelDef && this.progressIfModelNotFound === false) {
      throw new Error(
        `[ImportServiceWithDataImportSetting] this.modelName: "${this.modelName}" のモデル定義が見つかりませんでした`,
      )
    }
  }

  get modelName() {
    return this.dataImportSettingRecord
      ? this.dataImportSettingRecord.targetModelName
      : ''
  }

  get $models() {
    return this.loadedModels || $core.$models
  }

  get targetModelDef(): ModelFactory | null {
    return this.$models[this.modelName] || null
  }
  get targetModelPrimaryKeyColName() {
    return this.targetModelDef?.primaryKeyColName || 'id'
  }

  get colNames(): string[] {
    return this.targetModelDef?.colNames || []
  }

  get columns(): ColumnDefByColName {
    return this.targetModelDef?.columns || {}
  }

  /**
   * @param dataRows
   */
  async reformatDataWithImportSettings(dataRows: any[]) {
    // reset validation
    this.displayErrorMessages = []
    this.displayWarningMessages = []
    // 1. 設定を読み込んでおく
    await this._initData()
    // Validation 設定を runnable に変換
    await this._prepareValidationFunction()
    // Convert flattered Nested object
    this.originalDataRows = this.options.useUnflatten
      ? dataRows.map((row) => {
          return unFlattenObject(row, { splitString: '.' })
        })
      : dataRows
    this.dataForFormattedDataFields = {} // init

    // convertSettingsToRelationId の処理
    if (
      this.dataImportSettingRecord.convertSettingsToRelationId &&
      this.dataImportSettingRecord.convertSettingsToRelationId.length > 0
    ) {
      await this.processConvertSettingsToRelationId()
    }

    // duplicateCheckSettings の処理を追加
    await this.processDuplicateCheckSettings()

    // 2. 各行フォーマット
    // 2-1. useBeforeFormatFunction
    if (
      this.dataImportSettingRecord.useBeforeFormatFunction === true &&
      this.dataImportSettingRecord.beforeFormatFunction
    ) {
      try {
        this.originalDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: {
            originalDataRows: this.originalDataRows,
            instance: this,
          },
          functionString: `${this.dataImportSettingRecord.beforeFormatFunction}; return originalDataRows`,
        })
      } catch (e) {
        $core.$errorReporter.r(e, this)
        console.error(`beforeFormatFunction の実行時にエラーが発生しました: ${e.message}`)
        $core.$toast.errorToast(
          `beforeFormatFunction の実行時にエラーが発生しました: ${e.message}`,
        )
        throw e
      }
    }
    // 2-2. 各行 フォーマット
    const originalRowLength = this.originalDataRows.length
    const formattedRow: any[] = []
    let dataForFormattedDataFields = {}
    for (let i = 0; originalRowLength > i; i++) {
      const originalRow = this.originalDataRows[i]
      // パススルー取込みの際に、Label行を排除するために設定している
      if (i === 0 && this.dataImportSettingRecord.pathThroughOriginalProps === true) {
        const rowKeys = Object.keys(originalRow)
        // label が2つ以上合致するなら
        const isLikeLabelRow =
          (this.columns[rowKeys[1]]?.label || '') === originalRow[rowKeys[1]] &&
          (this.columns[rowKeys[2]]?.label || '') === originalRow[rowKeys[2]]
        if (isLikeLabelRow) {
          continue
        }
      }
      const formatted = await this._convertRowToRecordWithFieldConvertDefinition(
        originalRow,
        i,
      )
      // Validation error である場合は null になる
      if (formatted) {
        formattedRow.push(formatted)
        // ヘッダ行をlabel付きで整形するためのオブジェクト
        dataForFormattedDataFields = Object.assign(dataForFormattedDataFields, formatted)
      }
    }
    this.formattedDataRows = formattedRow
    // この時点で 零件である場合は ここで終了
    if (this.formattedDataRows.length === 0) {
      $core.$toast.warning('取り込み可能データが0件です。')
      return
    }
    this.dataForFormattedDataFields = dataForFormattedDataFields
    // 3. useAfterFormatFunction
    if (
      this.dataImportSettingRecord.useAfterFormatFunction === true &&
      this.dataImportSettingRecord.afterFormatFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: {
            formattedDataRows: this.formattedDataRows,
            instance: this,
            originalDataRows: this.originalDataRows,
          },
          functionString: `${this.dataImportSettingRecord.afterFormatFunction}; return formattedDataRows`,
          errorThrow: true,
        })
      } catch (e) {
        console.error(e)
        $core.$toast.errorToast(
          `afterFormatFunction の実行時にエラーが発生しました: ${e.message}`,
        )
        throw e
      }
    }
    return this
  }

  /**
   * Original 1行をFormatted 1行へ変換
   * @param originalRow
   * @param index
   * @private
   */
  async _convertRowToRecordWithFieldConvertDefinition(
    originalRow: any,
    index: number,
  ): Promise<Record<string, any> | null> {
    let formattedRow = {} as any
    // id があれば付与
    if (originalRow.id) {
      formattedRow.id = originalRow.id
    }
    // パススルー取込
    if (this.dataImportSettingRecord.pathThroughOriginalProps) {
      formattedRow = this.colNames.reduce((res, colName) => {
        if (originalRow[colName] !== undefined) {
          res[colName] = originalRow[colName]
        }
        return res
      }, formattedRow)
      debugger
    }
    // beforeRowConvertFunction
    if (this.dataImportSettingRecord.beforeEachRowConvertFunction) {
      try {
        formattedRow = await $core.$utils.executeStringDefinedFunction({
          functionString: `${this.dataImportSettingRecord.beforeEachRowConvertFunction}; return row`,
          functionArgValues: {
            row: formattedRow,
            sourceDataRow: originalRow,
            allSourceDataRows: this.originalDataRows,
            sourceDataRowIndex: index,
          },
        })
      } catch (e) {
        window.alert(
          `[ImportServiceWithDataImportSetting] beforeEachRowConvertFunction の実行に失敗しました。e.message: ${e.message}`,
        )
        throw e
      }
    }
    // import定義に従ってLoop
    for (let i = 0; this.dataImportSettingRecord.fields.length > i; i++) {
      const fieldConvDef = this.dataImportSettingRecord.fields[i]
      const targetModelColumnDef = this.columns[fieldConvDef.tCol]
      let value = this._valueFilter(originalRow[fieldConvDef.dataCol])
      // MULTISELECT or ARRAY_OB_OBJECT である場合には、 JSON.parse() をTryする
      if (
        targetModelColumnDef &&
        ['MULTISELECT', 'ARRAY_OB_OBJECT'].indexOf(targetModelColumnDef.type) >= 0 &&
        value
      ) {
        try {
          value = JSON.parse(value)
        } catch (e) {
          // try with comma sep
          if (
            targetModelColumnDef.type === 'MULTISELECT' &&
            typeof value === 'string' &&
            value.indexOf(',')
          ) {
            value = value.split(',')
          }
        }
      }
      if (fieldConvDef.behavior === 'func' && fieldConvDef.tFunc) {
        // A. functionパターン
        try {
          formattedRow = await $core.$utils.executeStringDefinedFunction({
            functionString: `${fieldConvDef.tFunc}; return row`,
            functionArgValues: {
              row: formattedRow,
              dataColName: fieldConvDef.tCol,
              value,
              sourceDataRow: originalRow,
              allSourceDataRows: this.originalDataRows,
              sourceDataRowIndex: index,
            },
          })
        } catch (e) {
          window.alert(
            `[ImportServiceWithDataImportSetting] tFunc の実行に失敗しました。e.message: ${
              e.message
            }, ${JSON.stringify({
              formattedRow,
              fieldConvDef,
              value,
            })}`,
          )
          throw e
        }
      } else if (fieldConvDef.tCol) {
        // B. directパターン
        formattedRow[fieldConvDef.tCol] = value
      }
    }
    if (this.columns) {
      formattedRow = castValuesWithColumnType(formattedRow, this.columns)
    }
    // excludeFields
    fixedExcludeFields.map((exCol) => {
      if (exCol) {
        delete formattedRow[exCol]
      }
    })
    if (
      this.dataImportSettingRecord.excludeFields &&
      this.dataImportSettingRecord.excludeFields.length
    ) {
      this.dataImportSettingRecord.excludeFields.map((exCol) => {
        delete formattedRow[exCol]
      })
    }

    // 変換完了したうえで 各 fields に定義されている Validation を実行
    const validationErrors =
      await this.runValidateToFormattedRowWithFieldValidationConfigs(
        formattedRow,
        originalRow,
      )
    if (validationErrors.length > 0) {
      this.displayErrorMessages.push(
        `[値チェックエラー][${index + 1}行目] ${validationErrors.join(', ')}`,
      )
      return null
    }

    return formattedRow
  }

  /**
   * Date型を突っ込むと変になるので、Dateを YYYY-MM-DD へ変換 等
   * @private
   */
  _valueFilter(val) {
    if (val && typeof val.getMonth === 'function') {
      // Date型なので
      return dateFormatter('YYYY-MM-DD', val)
    }
    return val
  }

  async saveCurrentTargets() {
    if (!this.formattedDataRows || this.formattedDataRows.length === 0) {
      return
    }
    await this._executeBeforeSaveFunctionDefinedOnSettingIfEnabled()
    await this._execSave()
    await this._executeAfterSaveFunctionDefinedOnSettingIfEnabled()
  }

  async _execSave() {
    // 行数が多すぎるとErrorになるので、1000行ずつ保存する
    const rowsLength = this.formattedDataRows.length
    // @2024-08-06: configVarsにてsliceサイズを変更できるように変更
    const tmpSliceSize = $core.$configVars.get('dataImportSetting.sliceSize', 1000)
    const sliceSize = tmpSliceSize > 0 ? tmpSliceSize : 1000
    const loopCount = Math.ceil(rowsLength / sliceSize)
    for (let i = 0; loopCount > i; i++) {
      const start = i * sliceSize
      const end = Math.min(start + sliceSize, rowsLength)
      const data = this.formattedDataRows.slice(start, end)
      await $core.$storeMethods.bulkUpsert({
        modelName: this.dataImportSettingRecord.targetModelName,
        data,
        quiet: true,
      })
    }
    console.log(`[ImportServiceWithDataImportSetting] Saved ${rowsLength} records.`)
  }

  /**
   * 保存処理前関数を実行する
   * @private
   */
  private async _executeBeforeSaveFunctionDefinedOnSettingIfEnabled() {
    if (
      this.dataImportSettingRecord.useBeforeSaveFunction === true &&
      this.dataImportSettingRecord.beforeSaveFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: { formattedDataRows: this.formattedDataRows },
          functionString: `${this.dataImportSettingRecord.beforeSaveFunction}; return formattedDataRows`,
        })
      } catch (e) {
        console.error(e)
        if (globalThis.alert) {
          globalThis.alert(
            `[ImportServiceWithDataImportSetting] beforeSaveFunction の実行に失敗しました。e.message: ${e.message}`,
          )
        }
        throw e
      }
    }
  }

  /**
   * 保存処理後関数を実行する
   * @private
   */
  private async _executeAfterSaveFunctionDefinedOnSettingIfEnabled() {
    if (
      this.dataImportSettingRecord.useAfterSaveFunction === true &&
      this.dataImportSettingRecord.afterSaveFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: { formattedDataRows: this.formattedDataRows },
          functionString: `${this.dataImportSettingRecord.afterSaveFunction}; return formattedDataRows`,
        })
      } catch (e) {
        console.error(e)
        if (globalThis.alert) {
          globalThis.alert(
            `[ImportServiceWithDataImportSetting] afterSaveFunction の実行に失敗しました���e.message: ${e.message}`,
          )
        }
        throw e
      }
    }
  }

  async _setDataImportSettingRecord() {
    // すでにsetされている場合は、fetchしない
    if (this.dataImportSettingRecord) {
      return
    }
    this.dataImportSettingRecord = (
      await this.findFunction('dataImportSettings', {
        filter: { name: { _eq: this.dataImportSettingName } },
      })
    )[0] as DataImportSettingRecord
  }

  /**
   * 表示用, データカラムのlabelで表示するために
   */
  get formattedDataFields(): { key: string; label?: string }[] {
    return Object.keys(this.dataForFormattedDataFields).map((colName) => {
      const label = this.columns[colName]?.label
      return label ? { key: colName, label } : { key: colName }
    })
  }

  /**
   * rowValidationFunction を準備する
   */
  private async _prepareValidationFunction() {
    const functions = []

    // validationConfigsByImportColumns を使用して検証関数を準備
    if (this.dataImportSettingRecord.validationConfigsByImportColumns) {
      for (const validationConfig of this.dataImportSettingRecord
        .validationConfigsByImportColumns) {
        const colDef = this.columns[validationConfig.targetCol]
        if (!colDef) continue

        const validateFunction = generateValidateFunctionWithColDef({
          instance: this,
          colDef,
          value: validationConfig.targetCol,
          validationConfigs: validationConfig.validationConfigs,
          useColumnDefValidation: validationConfig.useColumnDefValidation,
          errorMessageFormatterFunction: (errorMessage, formattedRow, originalRow) => {
            return ` ${errorMessage}`
          },
        })

        if (typeof validateFunction === 'function') {
          functions.push(validateFunction)
        }
      }
    }

    this.rowValidationFunction = async (formattedRow, originalRow) => {
      const errors = await Promise.all(functions.map((f) => f(formattedRow, originalRow)))
      return errors.filter((e) => !!e)
    }
  }

  /**
   * Validation を 実行する
   */
  async runValidateToFormattedRowWithFieldValidationConfigs(
    formattedRow,
    originalRow,
  ): Promise<string[]> {
    return this.rowValidationFunction(formattedRow, originalRow)
  }

  private async processConvertSettingsToRelationId() {
    const convertSettings = this.dataImportSettingRecord.convertSettingsToRelationId
    if (!convertSettings || convertSettings.length === 0) {
      return
    }

    for (const convertSetting of convertSettings) {
      const { relationModelName, relationIdCol, targetCol } = convertSetting
      if (!relationModelName || !relationIdCol || !targetCol || targetCol.length === 0) {
        this.displayWarningMessages.push(
          `不完全な変換設定: ${JSON.stringify(convertSetting)}`,
        )
        continue
      }

      try {
        const allRelationData = await this.findFunction(relationModelName, { limit: -1 })
        const relationDataMap = this.createRelationDataMap(allRelationData, targetCol)

        this.originalDataRows = this.originalDataRows.map((row, index) =>
          this.processRowForRelationConversion(
            row,
            index,
            convertSetting,
            relationDataMap,
          ),
        )
      } catch (error) {
        this.displayErrorMessages.push(
          `リレーションデータの取得中にエラーが発生しました: ${error.message}`,
        )
      }
    }
  }

  private createRelationDataMap(
    allRelationData: any[],
    targetCol: any[],
  ): Map<string, string> {
    const relationDataMap = new Map()
    for (const data of allRelationData) {
      const key = targetCol
        .map((tc) => {
          const value = data[tc.targetCol]
          return value !== undefined && value !== null ? `${tc.targetCol}:${value}` : null
        })
        .filter(Boolean)
        .join('|')
      if (key) {
        relationDataMap.set(key, data.id)
      }
    }
    return relationDataMap
  }

  private processRowForRelationConversion(
    row: any,
    index: number,
    convertSetting: any,
    relationDataMap: Map<string, string>,
  ): any {
    const { relationModelName, relationIdCol, targetCol } = convertSetting
    const keyParts = this.createKeyParts(row, targetCol)

    if (keyParts.some((kp) => kp.value === undefined || kp.value === null)) {
      this.displayWarningMessages.push(
        `[${index + 1}行目] 変換に必要な値が不足しています: ${JSON.stringify(keyParts)}`,
      )
      return row
    }

    const key = keyParts.map((kp) => `${kp.col}:${kp.value}`).join('|')
    const relationId = relationDataMap.get(key)

    if (relationId) {
      row[relationIdCol] = relationId
    } else {
      this.addWarningMessage(index, relationModelName, keyParts, row)
    }

    return row
  }

  private createKeyParts(
    row: any,
    targetCol: any[],
  ): Array<{ col: string; value: string | RegExp }> {
    return targetCol.map((tc) => {
      const value = row[tc.dataCol]
      let matchValue
      switch (tc.matchType) {
        case '_starts_with':
          matchValue = new RegExp(`^${this.escapeRegExp(value)}`, 'i')
          break
        case '_ends_with':
          matchValue = new RegExp(`${this.escapeRegExp(value)}$`, 'i')
          break
        case '_contains':
          matchValue = new RegExp(this.escapeRegExp(value), 'i')
          break
        default:
          matchValue = value
      }
      return { col: tc.targetCol, value: matchValue }
    })
  }

  private addWarningMessage(
    index: number,
    relationModelName: string,
    keyParts: Array<{ col: string; value: string | RegExp }>,
    row: any,
  ) {
    this.displayWarningMessages.push(
      `[${index + 1}行目] リレーション先 "${relationModelName}" の値が見つかりません: ${keyParts.map((kp) => `${kp.col}=${row[kp.col]}`).join(', ')}`,
    )
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  private async processDuplicateCheckSettings() {
    const duplicateCheckSettings = this.dataImportSettingRecord.duplicateCheckSettings
    if (!duplicateCheckSettings || duplicateCheckSettings.length === 0) {
      return
    }

    const targetModelName = this.dataImportSettingRecord.targetModelName
    if (!targetModelName) {
      this.displayErrorMessages.push('ターゲットモデル名が設定されていません。')
      return
    }

    // 1. 重複チェック設定による filter 値の整理
    const filters: FindFilter<any> = {}
    for (const setting of duplicateCheckSettings) {
      const { dataCol, targetCol } = setting
      if (!dataCol || !targetCol) {
        this.displayWarningMessages.push(
          `不完全な重複チェック設定: dataCol: ${dataCol}, targetCol: ${targetCol}`,
        )
        continue
      }

      const values = this.formattedDataRows
        .map((row) => row[dataCol])
        .filter((v) => v !== undefined && v !== null)

      if (values.length > 0) {
        filters[targetCol] = { _in: values }
      } else {
        this.displayWarningMessages.push(`${dataCol} の有効な値が見つかりません。`)
      }
    }

    if (Object.keys(filters).length === 0) {
      this.displayWarningMessages.push('有効な重複チェック条件がありません。')
      return
    }

    // 2. レコードを一括取得
    try {
      const existingRecords = await this.findFunction(targetModelName, {
        filter: filters,
        fields: [
          this.targetModelPrimaryKeyColName,
          ...duplicateCheckSettings.map((s) => s.targetCol),
        ],
      })

      // 既存レコードをマップ化して高速検索できるようにする
      const existingRecordsMap = new Map<string, any>()
      for (const record of existingRecords) {
        const key = duplicateCheckSettings.map((s) => record[s.targetCol]).join('|')
        existingRecordsMap.set(key, record)
      }

      // 3. マッチした レコードの primaryKey をセット
      for (const row of this.formattedDataRows) {
        const key = duplicateCheckSettings.map((s) => row[s.dataCol]).join('|')
        const existingRecord = existingRecordsMap.get(key)

        if (existingRecord) {
          row[this.targetModelPrimaryKeyColName] =
            existingRecord[this.targetModelPrimaryKeyColName]
          this.displayWarningMessages.push(
            `重複レコードが見つかりました: ${key}. 更新モードで処理します。`,
          )
        }
      }
    } catch (error) {
      this.displayErrorMessages.push(
        `レコードの取得中にエラーが発生しました: ${error.message}`,
      )
    }
  }
}

/**
 * バリデーション関数を生成する
 */
const generateValidateFunctionWithColDef = ({
  colDef,
  value,
  validationConfigs,
  useColumnDefValidation,
  errorMessageFormatterFunction,
  instance,
}): ((formattedRow, originalRow) => Promise<string>) => {
  let validatesObject = parseValidationConfigIntoColumnDefValidateProp(validationConfigs)
  if (useColumnDefValidation) {
    if (Object.keys(colDef?.validate || {}).length > 0) {
      validatesObject = {
        ...colDef.validate,
        ...validatesObject,
      }
    }
  }
  if (Object.keys(validatesObject).length === 0) {
    return null
  }
  return async (formattedRow, originalRow) => {
    try {
      const errorMessage = await validateWithColDef({
        value,
        colDef,
        modelName: instance.modelName,
        initialValue: undefined,
        record: formattedRow,
        recordRoot: formattedRow,
        overwriteValidateObject: validatesObject,
      })
      if (typeof errorMessageFormatterFunction === 'function') {
        return errorMessageFormatterFunction(errorMessage, formattedRow, originalRow)
      }
      return errorMessage ? `${colDef.label || colDef.name}: ${errorMessage}` : null
    } catch (e) {
      $core.$toast.errorToast(
        `取込列名 "${colDef.label}" の値チェック実行時にエラーが発生しました: ${e.message}`,
      )
      return null
    }
  }
}
