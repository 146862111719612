import { AsyncComponentLoader, Component, ComponentPublicInstance } from 'vue'
import { stringFilterTypes } from '../../front/ModelIndex'
import { ComposableDataListService } from '../../plugins/ComposableDataListComponents/front/ComposableDataListService'
import {
  ModelExtension,
  ModelExtensions,
} from '../../plugins/ModelExtensionModelsLoader/common'
import {
  DirectusColumnMetaDef,
  DirectusColumnSchemaDef,
  FindFilter,
  FindQuery,
} from '../../types'
import { BulkDeleteParam, BulkUpsertParam } from '../storeMethods/storeMethodTypes'

type ColumnName = string
export type ErrorMessagesObject = Record<ColumnName, string[]>
export type ErrorMessagesResult = {
  colName: string
  errorMessages: {
    message: string
  }[]
}

/**
 * ModelDef の Validation の設定オブジェクト
 */
export type ModelValidationConfigItem = {
  // 関数で 設定する場合
  isInvalidCheckFunction?: (row: EditingRecord) => Promise<boolean>
  // UI or API で 設定する場合 (高度なフィルタ による 複雑な条件オブジェクト)
  isInvalidCheckConditionObject?: FindFilter<any>
  // 上記 validation 関数 or 条件 に合致する場合に、どのカラムに どのような エラーメッセージを付与するか の オブジェクト
  errorMessageByColumnName: ErrorMessagesResult[]
  // UI 設定用
  name?: string
  // UI 設定用, 説明文
  description?: string
  // UI 設定用
  isDefinedAsFunction?: boolean
}

export const colGroupTypes = {
  tab: { label: 'タブ' },
  accordion: { label: 'アコーディオン' },
}
export type ColorLabelSelectionConditon = {
  valueCondition: string
  useRegExp: boolean
  color: string
  borderColor: string
  textColor: string
}
export const colGroupTypeKeys = Object.keys(colGroupTypes)
export type ColGroupTypes = keyof typeof colGroupTypes

type TagOrComponentName = string
/**
 * Javascript, or json 上で Vue コンポーネントを定義する場合の型定義
 */
type VueComponentOptionalAPIDefinition = {
  template: string
} & Component

/**
 * コンポーネントの型定義
 *
 * @example
 * ```ts
 * // タグ名
 * 'div'
 * // コンポーネント定義
 * {
 *   template: '<div>Hello {{ record.name }}, <a @click="onClick">click</a></div>',
 *   props: ['record'],
 *   methods: {
 *     onClick() {
 *       console.log('clicked')
 *     },
 *   },
 * }
 * ```
 */
export type ComponentResolver =
  | TagOrComponentName
  | VueComponentOptionalAPIDefinition
  | Component
  | AsyncComponentLoader
type ColumnBeforeOrAfterComponent = string | ComponentResolver

export type PrimaryKeyValue = number | string
type AnyTruthyValue = any
type AnyFalsyValue = any
type AnyTruthyOrFalsyValue = AnyTruthyValue | AnyFalsyValue

/**
 * ModelDef.columns[colName].type に指定する型
 */
export const ColumnTypes = {
  String: 'STRING',
  Text: 'TEXT',
  RichText: 'RICHTEXT',
  Number: 'NUMBER',
  DateOnly: 'DATEONLY',
  Datetime: 'DATETIME',
  Time: 'TIME',
  Boolean: 'BOOLEAN',
  Reference: 'REFERENCE',
  ArrayOfObject: 'ARRAY_OF_OBJECT',
  File: 'FILE',
  FileUpload: 'FILEUPLOAD',
  Select: 'SELECT',
  MultiSelect: 'MULTISELECT',
  RelationshipManyToOne: 'RELATIONSHIP_MANY_TO_ONE',
  RelationshipOneToMany: 'RELATIONSHIP_ONE_TO_MANY',
  RelationshipManyToAny: 'RELATIONSHIP_MANY_TO_ANY',
  UUID: 'UUID',
  Float: 'FLOAT',
  BigInteger: 'BIGINTEGER',
  Decimal: 'DECIMAL',
  Double: 'DOUBLE',
  ConditionalExpression: 'CONDITIONAL_EXPRESSION',
  JSON: 'JSON',
} as const

export const columnTypesWithDecimals: ColumnType[] = [
  ColumnTypes.Float,
  ColumnTypes.Decimal,
  ColumnTypes.Double,
]
export const columnTypesNumber: ColumnType[] = [
  ColumnTypes.Number,
  ColumnTypes.Float,
  ColumnTypes.Decimal,
  ColumnTypes.Double,
]

export type ValueOf<T> = T[keyof T]

export type EditCallbackArgs = {
  /**
   * 編集時のレコードデータ
   */
  row: EditingRecord
  /**
   * 編集されたカラム名
   */
  key: string
  newValue: any
  oldValue: any
  /**
   * 新規レコードかどうか
   */
  isNewRecord: boolean
  /**
   * 編集を行ったVueインスタンス
   * - ModelForm.vue の VueInstance となる
   * - 特殊な処理を実施したい場合に、VueInstance に直接アクセス可能
   */
  callerVueInstance?: VueComponentInstance
}

/**
 * Form上での編集時に、カラムの値を変換する関数
 */
export interface EditCallback {
  ({
    row,
    key,
    newValue,
    oldValue,
    isNewRecord,
    callerVueInstance,
  }: EditCallbackArgs): Promise<EditingRecord> | EditingRecord | any
}

export interface EditCallbackForColumn {
  ({
    row,
    newValue,
    oldValue,
    isNewRecord,
    callerVueInstance,
  }: {
    row: EditingRecord
    newValue: any
    oldValue: any
    isNewRecord: boolean
    callerVueInstance?: VueComponentInstance
  }): Promise<EditingRecord> | EditingRecord | any
}

/**
 * 編集可能かどうかを判定する関数
 * - レコードの状態に応じて、編集可能かどうかを動的に変更可能
 *
 * @example
 * ```ts
 * // Model定義にて
 * {
 *  columns: {
 *    // ...
 *    name: {
 *      type: 'STRING',
 *      editable: (row) => row.status === 'draft',
 *    },
 *    // ...
 *    }
 *  }
 *  ```
 */
export type EditableFunc = (
  /**
   * any 編集時のレコードデータ (ARRAY_OF_OBJECT の場合には、編集中の子レコードが渡される)
   */
  row: EditingRecord | EditingChildRecord,
  /**
   * any 編集時のVueInstance, src/front/ModelForm/ModelInput/ModelInput.vue の VueInstance となる
   */
  callerVueInstance: VueComponentInstance,
  /**
   * any 編集時のレコードデータのルート (ARRAY_OF_OBJECT 等 の場合に、親レコードが渡される)
   */
  recordRoot?: EditingRecord,
) => boolean | any | Promise<boolean | any>

export type findAllByArgument =
  | Function
  | string
  | boolean
  | number
  | {
      [colName: string]: string | boolean | number
    }
  | FindFilter

/**
 * カラムの type に指定する値
 */
export type ColumnType = ValueOf<typeof ColumnTypes>

const _iconTypesForColType = {
  STRING: 'font',
  DATEONLY: 'calendar',
  DATETIME: 'clock',
  NUMBER: 'sort-numeric-down',
}
/**
 * アイコン指定 by ColumnType
 */
export const iconTypeByColumnType: {
  [key in ColumnType]: string
} = {
  STRING: _iconTypesForColType.STRING,
  DATEONLY: _iconTypesForColType.DATEONLY,
  DATETIME: _iconTypesForColType.DATETIME,
  TIME: _iconTypesForColType.DATETIME,
  NUMBER: _iconTypesForColType.NUMBER,
  DECIMAL: _iconTypesForColType.NUMBER,
  BOOLEAN: 'check-circle',
  MULTISELECT: 'tags',
  TEXT: _iconTypesForColType.STRING,
  JSON: _iconTypesForColType.STRING,
  RICHTEXT: _iconTypesForColType.STRING,
  REFERENCE: '',
  ARRAY_OF_OBJECT: '',
  FILE: '',
  FILEUPLOAD: '',
  SELECT: '',
  RELATIONSHIP_MANY_TO_ONE: '',
  RELATIONSHIP_ONE_TO_MANY: '',
  RELATIONSHIP_MANY_TO_ANY: '',
  UUID: '',
  FLOAT: _iconTypesForColType.NUMBER,
  BIGINTEGER: _iconTypesForColType.NUMBER,
  DOUBLE: _iconTypesForColType.NUMBER,
  CONDITIONAL_EXPRESSION: '',
}

/**
 * ## ColumnDef.enableIf に指定する 関数定義
 * - 編集フォーム内で、入力の状態に応じて、カラムを表示/非表示を関数でコントロール可能
 * @example
 * ```ts
 * // 例: "status" が "却下" の場合にのみ、却下理由を入力するカラムを表示する
 * {
 *   columns: {
 *     // ...
 *     status: {
 *       type: 'STRING',
 *       selections: () => ['承認', '却下'],
 *       defaultValue: '承認',
 *     },
 *     rejectReason: {
 *       type: 'TEXT',
 *       enableIf: (row) => row.status === '却下',
 *       defaultValue: '',
 *       width: { xs: 48 },
 *       validate: { notEmpty: true },
 *     }
 *   }
 * }
 *
 */
export type EnableIfFunction =
  | ((row: EditingRecord) => boolean | Promise<boolean> | AnyTruthyOrFalsyValue)
  | ((
      childRow: EditingChildRecord,
      row: EditingRecord,
    ) => boolean | Promise<boolean> | AnyTruthyOrFalsyValue)

/**
 * FilterByAttrsFunction
 */
type FilterByAttrsFunction = ({
  record,
  recordRoot,
  callerVueInstance,
}: {
  record: EditingRecord | EditingChildRecord
  recordRoot?: EditingRecord
  callerVueInstance?: VueComponentInstance
}) => FindFilter

/**
 * カラムを タブ もしくは アコーディオン でグループ化するための定義
 * ModelDef.formColumnGroups に設定する
 */
export type ColumnDefGroupDef = {
  type: ColGroupTypes
  label: string
  key?: string // グループ制御のkey, label と 制御 key を別にしたい場合に設定
  order?: number // 並び順を制御したい場合
  groupKey?: string // グループの開閉状態を同期するためのキー, groupKey を合わせると、開閉状態が同期される。タブの場合は、同じタブリストに含まれる。
  icon?: string // アイコンタイプ 文字列で選択
}

export type ModelDefFormColumnGroups = {
  [key: string]: Omit<ColumnDefGroupDef, 'key'>
}

export type SelectOptionItemObject = {
  label: string
  value: any
}

type ColumnUniqueConditionFunction = (
  record: EditingRecord,
  recordRoot: EditingRecord,
) => FindFilter | any

/**
 * 編集中のレコードデータ
 */
type EditingRecord = Record<string, any>
/**
 * 編集中の子レコードデータ (ARRAY_OF_OBJECT or ONE_TO_MANY_RELATIONSHIP で `inputComponent: 'SortableRecordList'` を利用している 等 の場合には、編集中の子レコードが渡される)
 */
type EditingChildRecord = Record<string, any>

/**
 * インスタンス化された Vue コンポーネント
 */
type VueComponentInstance = any

type HTMLContentString = string

/**
 * カラムの選択肢の値 (ColumnDef.selections() の返り値) として有効な型
 */
export type SelectOptionItem = string | number | any | SelectOptionItemObject

export type CustomLabelFunction = (
  value: any,
  callerVueInstance?: VueComponentInstance,
  recordRoot?: EditingRecord,
) => string

export type FunctionString = string

/**
 * カラムの定義
 */
export class ColumnDef {
  /**
   * @group Basics
   * @hidden モデル定義上では、カラム定義オブジェクトのキーが name に利用されるため、 name をプロパティとして直接指定する必要はない
   */
  name?: string
  /**
   * ※ カラム型は MySQL の場合で表現しています。
   *
   * | 値 | カラム型 (デフォルト) | フォームの表示, 特記事項 |
   * |-----|-----|-----|
   * | `STRING` | VARCHAR(255) | 文字入力 or 選択肢 |
   * | `TEXT` | TEXT | テキスト入力 `<textarea/>` |
   * | `RICHTEXT` | TEXT | WYSIWYGエディタ |
   * | `NUMBER` | INT(11) | 数値入力 `<input type="number"/>` |
   * | `DATEONLY` | DATE | 日付選択 |
   * | `DATETIME` | DATETIME | DateTimePicker |
   * | `BOOLEAN` | TINYINT(1) | 真/偽チェックボックス |
   * | `FILE` | VARCHAR(255) | ファイルアップロード (ファイル選択モーダル有り)。DBに保存される値は、保存先ストレージのファイルパス (ドメインなしの絶対参照パス) となります。 |
   * | `FILEUPLOAD` | VARCHAR(255) | ファイルアップロード (ファイル選択モーダルなし)。 保存される値は、保存先ストレージのファイルパス |
   * | `MULTISELECT` | VARCHAR(255) | 複数選択可能な入力フォームを表示します |
   * | `RELATIONSHIP_MANY_TO_ONE` | ※ 参照先カラムの型に準ずる | 1対多のリレーションシップを定義します。 DBに保存される値は、参照先テーブルのプライマリキーカラムの値となり、また、型定義は参照先テーブルに応じて変更となります。 |
   * | `RELATIONSHIP_ONE_TO_MANY` | ※ 物理カラム無し | 多対1のリレーションシップを定義します。参照先のフィールドには `RELATIONSHIP_MANY_TO_ONE` が予め設定されている必要があります。 |
   * | `UUID` | CHAR(36) | UUIDを生成します |
   * | `DECIMAL` | DECIMAL | 数値入力 (小数点許可) |
   * | `BIGINTEGER` | BIGINT | 64bitの整数を入力します |
   * | `JSON` | JSON | ※ 特殊な処理を実施する場合に利用, inputComponent で独自の内容を指定します |
   *
   * @group データ型・入力フォーム
   */
  type: ColumnType

  /**
   * 管理用コメント (設計・仕様に関する注記) を 記載します。
   * ※ 見れば分かる情報は記載しない, 特殊な仕様や、設計上の考え方及び 込み入ったユースケースの場合にのみ記載
   * 入力する際のユーザへの補助的な情報は inputHelpText で記載してください
   */
  adminComment?: string
  /**
   * フォーム上で 入力コンポーネントを独自定義の Vue コンポーネントに差し替えたい場合に指定します。
   * - 編集不可状態の 表示を変更する場合には `disabledComponent` を指定してください
   *
   * @example
   * ### 例1: Vueコンポーネントを カラム定義にて オブジェクトとして指定する
   * ```ts
   * // カラム定義にて
   * {
   *   // Vue コンポーネント オブジェクトを渡すことが可能です
   *   inputComponent: {
   *     template: `<div>
   *       <div>独自の入力フォームの例:</div>
   *       <label>
   *         <input type="checkbox" v-model="editMode" />
   *         {{ col.label }} の値を編集する
   *       </label>
   *       <div v-if="editMode">
   *         <input type="text" v-model="newValue" />
   *         <button @click="() => updateValue()">値を更新</button>
   *       </div>
   *     </div>`,
   *     // this.$emit('update:modelValue', newValue) を実行することで初めて、値の更新を親コンポーネントに伝達します
   *     emits: ['update:modelValue'],
   *     props: {
   *       modelValue: {}, // 現在の値 を受け取ります 参考: https://ja.vuejs.org/guide/components/v-model.html#handling-v-model-modifiers
   *       record: {},
   *       recordRoot: {},
   *       col: {},
   *       modelName: {},
   *       virtualModelName: {},
   *       editButtonLabel: {} // ColumnDef.inputAttrs で設定した値を受け取る
   *     },
   *     data() {
   *       return {
   *         editMode: false,
   *         newValue: ''
   *       }
   *     },
   *     methods: {
   *       updateValue() {
   *         this.$emit('update:modelValue', this.newValue)
   *       }
   *     }
   *   },
   * }
   * ```
   *
   * ### 例2: 予め定義した Vue コンポーネントを カラム定義にて 文字列で指定する
   * ```ts
   * // カラム定義にて
   * {
   *   // Vue コンポーネント名を文字列で指定
   *   inputComponent: 'MyCustomInputComponent',
   * }
   * ```
   *
   * ```ts
   * // UI から Vue コンポーネントを 登録する場合の例:
   * // - CORE 管理画面から "フロントカスタムHooks" (モデル frontSideApphooks) にて appHookName: "CORE.frontApp.afterInit" を指定した状態で、下記のように関数を登録しておきます。
   * // - この例では、MyCustomInputComponent という名前でコンポーネントを登録しています。
   * $core.VueClass.component('MyCustomInputComponent', {
   *   template: `<div>...</div>`,
   *   data() {
   *     return {
   *       // ...
   *     }
   *   },
   *   methods: {
   *     // ...
   *   }
   * })
   * ```
   *
   * @group カスタマイズ
   */
  inputComponent?: ComponentResolver
  /**
   * 編集不可のときの表示をカスタマイズするための Vue コンポーネントを指定します。
   *
   * @example
   * ```ts
   * // Vue コンポーネント名を文字列で指定
   * disabledComponent: 'MyCustomDisabledComponent',
   * // or, Vue コンポーネント オブジェクト で指定
   * disabledComponent: {
   *   template: `<div class="text-muted small">{{ modelValue }} 円</div>`,
   *   props: ['modelValue', 'record', 'model', 'col', 'ModelFormService', 'colInputSelection'],
   * }
   * ```
   */
  disabledComponent?: ComponentResolver
  /**
   *
   * 検索用の独自コンポーネント
   * @group カスタマイズ
   */
  searchInputComponent?: ComponentResolver
  /**
   * 検索対象から除外する場合には true を設定します。
   * 一覧表示時の 検索フィルタ条件 および キーワード検索 から除外されます。
   * @group カスタマイズ
   */
  excludeFromSearch?: boolean
  /**
   * フォームの wrap tag などの設定
   *
   * @group カスタマイズ
   */
  formGroup?: {
    tag?: Function | any
    attrs?: Object | any
  }
  /**
   * true に設定すると 編集画面で 入力欄のラベルを非表示にします。
   */
  hideLabel?: boolean
  /**
   * DBカラムの NOT NULL 制約を設定します。 (default false)
   * @group 入力挙動設定
   */
  notNull?: true

  /**
   * bulkEditable: 一括編集対象外としたい場合に false を設定します。 (default true)
   */
  bulkEditable?: boolean

  /**
   * @deprecated `defaultValue` を利用してください
   */
  default?: any
  /**
   * デフォルト値を設定します。関数を指定することも可能です。 (await 可能)
   * @group 入力挙動設定
   * @example
   * ```ts
   * // 本日日付を設定する例
   * defaultValue: () => $core.$dayjs().format('YYYY-MM-DD'),
   * // ログイン中の ユーザID を設定する例
   * defaultValue: () => $core.$emberAuth.user.id,
   * // 非同期処理を行う例
   * defaultValue: async () => {
   *   const products = await $core.$models.products.findAllBy({ status: 'published' })
   *   return products.length
   * }
   * ```
   */
  defaultValue?: any | Promise<any> | (() => any | Promise<any>)
  /**
   * カラムの表示名を設定します。 ※ 必須
   * @group Basics
   */
  label?: string
  /**
   * カラムのコメントを設定します。編集フォーム上では、 ツールチップとして表示されます。
   * 基本的には inputHelpText を利用してください。
   * @group フォーム表示
   */
  comment?: string

  /**
   * プライマリキーカラムである場合に設定します。
   * 注意事項: プライマリキーは、必ず1つのカラムに設定してください。
   * @group Basics
   */
  primaryKey?: boolean
  /**
   * カラムにユニークキー制約を付与する場合には true に設定します。
   * - true を 設定した場合にのみ DB の Constraint が設定されます。
   * - `conditions` に関数を指定することで、Validation を複雑な条件で設定することが可能です。
   *
   * @group Basics
   */
  unique?: boolean | { conditions: FindFilter | ColumnUniqueConditionFunction } // Function should return conditions of model.findAllBy(conditions)

  /**
   * 表示 / 非表示を設定
   * false に設定すると、一覧 / 編集フォーム 全てで非表示となります。 (default: true)
   */
  visible?: boolean
  /**
   * 一覧表示時の表示 / 非表示を設定
   * false に設定すると、一覧Table表示時に 当該カラムが非表示となります。 (default: true)
   */
  visibleOnIndex?: boolean
  /**
   * 編集可否を設定
   * - false に設定すると、編集フォームで編集不可となります。
   * - 関数で 設定する場合には、レコードの値に応じて編集可能かどうかを動的に変更可能です。
   */
  editable?: boolean | EditableFunc
  /**
   * 新規レコード作成時のみ編集可能とする場合に、 true を設定します。 (editable: false と組み合わせて利用)
   */
  editableOnCreate?: boolean
  /**
   * @hidden
   */
  replicable?: boolean
  /**
   * 文字列型などのカラムにおいて、空文字列の場合に DB値として null を保存時に設定する場合に true を設定します。
   */
  setNullIfEmpty?: boolean

  /**
   * 編集直後に実行する関数を設定します。
   * - ユースケース: 他のカラムの値を変更 or リセットする
   *
   * @example
   * ```ts
   * // 数量 と 単価 から 合計金額 を計算 する シンプルな例 (2つのカラム `price_per_quantity` および `quantity` の editCallback 属性として 下記 同じ内容を 設定)
   * editCallback: ({row, newValue, oldValue, isNewRecord, callerVueInstance}) => {
   *   if (row.price_per_quantity && row.quantity) {
   *     row.price_total = (row.price_per_quantity || 0) * (row.quantity || 0)
   *   }
   *   return row
   * },
   * ```
   *
   * ```ts
   * // M2O リレーションのカラムで 選択肢を変更した際に、他のカラムの値を変更する例
   * editCallback: async ({row, newValue, oldValue}) => {
   *   if (newValue && newValue !== oldValue) {
   *     // 指示書を選択したら、、撚糸を自動で入れる
   *     const s = await $core.$models.ce_material_trans.findById(newValue)
   *     const matId = s.material?.id || s.material || null
   *     if (matId) {
   *       row.material = matId
   *       row.amount_total = s.amount_remain_total || 0
   *     }
   *   }
   *   return row
   * }
   * ```
   */
  editCallback?: EditCallbackForColumn // Transform record data

  /**
   * 選択肢以外の入力 (ユーザの自由入力) を禁止する場合に true を設定
   */
  strictSelections?: boolean

  /**
   * 選択肢を動的に生成する関数 (await 利用可能)
   * - 注意: 選択肢を設定する方法は 下記 `selections()` だけではありません。
   *   - 他Modelを参照する場合: `type: 'RELATIONSHIP_MANY_TO_ONE'`, および `relationshipManyToOne` プロパティを設定
   *   - 選択肢マスタを利用する場合: `selectionsWithSelectOptionsMasterGroupName` プロパティを設定
   *   - Facet (SELECT DISTINCT) を利用する場合: `selectionsWithColNameFacet` プロパティを設定
   *   - 関数で複雑な処理を行う場合: `selections()` プロパティを設定
   */
  selections?(
    record?: EditingRecord | EditingChildRecord,
    currentValue?: any,
    initialValue?: any,
    recordRoot?: EditingRecord,
    callerVueInstance?: VueComponentInstance,
  ): Promise<SelectOptionItem[]> | SelectOptionItem[]

  /**
   * 選択肢を動的に生成する必要がある際に true を設定します。
   * - 編集中のレコードの状態に応じて 選択肢を動的に変更する場合に利用します。
   * - 特に 上記 selections 関数で `record` の 値を利用して 選択肢を生成する場合に true に設定します。
   * - true の場合には、 入力欄にフォーカスがあたった場合に毎回 選択肢を動的に生成します。そのため、選択肢の取得処理が高頻度で実行され、処理が重くなる可能性があります。
   */
  dynamicSelections?: true

  /**
   * 関数で 選択肢の表示名を指定することが可能
   * @param value - 選択肢の "値"
   * @param callerVueInstance
   * @param recordRoot
   */
  customLabel?: CustomLabelFunction
  /**
   * (廃止予定) 他のモデルの値を参照して選択肢を生成する場合に指定します。
   * @deprecated
   */
  referenceOfStore?: {
    // reference another model
    storeName: string
    label: string
    labelFormatter?: (record: EditingRecord) => string
    refLabelColName?: string // 参照先のLabelを保管するField名を定義 (無ければ作成される)
    key: string
    filterByAttrs?: findAllByArgument | FilterByAttrsFunction
    allowEmptySelection?: boolean
    filterSelectionsByRowState?: ({ selections, row, dataList }) => any
  }
  /**
   * 選択肢マスタ (Model `selectOptionsMaster`) から 選択肢を 指定する場合にselectOptionsMaster.group の値を設定します。
   */
  selectionsWithSelectOptionsMasterGroupName?: string
  /**
   * `selectionsWithSelectOptionsMasterGroupName` が設定されている場合に、新規選択肢を自動登録する場合に true を設定します。
   */
  createSelectOptionsMasterOnAddNewSelection?: boolean
  additionalSelectOptions?: {
    prepend?: SelectOptionItem[]
    append?: SelectOptionItem[]
  }
  /**
   * Facet を利用したselectionsの生成 をしたい場合 (API 側では SQL で GROUP BY を 利用して抽出)
   * (同一Model内のレコードに存在する 特定カラム の値を取得し、選択肢として利用する)
   *
   * ## 利用例
   *
   * ```ts
   * // Model定義にて
   * {
   *   columns: {
   *     // ...
   *     category: {
   *       type: 'STRING',
   *       label: 'カテゴリ',
   *       selectionsWithColNameFacet: 'category', // 同一Model内のレコードに存在する 'category' の値一覧を取得し、選択肢として利用する
   *     }
   *   }
   * }
   * ```
   */
  selectionsWithColNameFacet?: string

  colorLabelSelectionConditon?: ColorLabelSelectionConditon[]

  /**
   * Validations
   */
  validate?: IValidates
  /**
   * Validations
   */
  // validationConfigs?: ValidationConfigValueType[]

  /**
   * 編集フォーム上で、このカラムの入力欄の 表示/非表示を 他のカラムの値に応じてを切り替える場合に指定します。
   *
   * @example
   * ```ts
   * // 例1: "却下理由" (TEXT) カラムにて, "status" が "却下" の場合にのみ表示する
   * enableIf: (row) => row.status === '却下',
   *
   * // 例2: 割引金額 (NUMBER) カラムにて, "商品名" (STRING) が Model `products` に存在する場合にのみ表示する
   * enableIf: async (row) => {
   *   const product = await $core.$models.products.findOne({ filter: {name: { _eq: row.product_name }} })
   *   return !!product
   * }
   * ```
   */
  enableIf?: { [keyName: string]: string | number | boolean | any } | EnableIfFunction

  /**
   * 編集フォーム上で、このカラムの入力欄の 表示/非表示を 他のカラムの値に応じてを切り替える場合に指定します。
   * - enableIf とは異なり、 enableIfByFilterQuery では 編集中のレコードを 検索条件 として指定することで、検索結果に応じて表示/非表示を切り替えることが可能です。
   */
  enableIfByFilterQuery?: FindFilter
  /**
   * @deprecated
   * TODO: Algolia 廃止だが絞り込み要素として利用可能...?
   */
  facet?: any

  /**
   * @deprecated
   */
  searchable?: boolean // algolia index searchable or not

  /**
   * 複数レコードをグルーピングして一括編集する 設定 (ModelDef 側で `groupedEditKeys` が 設定されている) の場合にのみ, 共通カラムとして 編集させたい場合に true を設定します。
   */
  groupedEdit?: boolean // Should be in parent record's columns when edit data

  /**
   * カラムの input および wrapper element の 属性を指定します。
   * - 通常の HTML の属性すべてを指定可能
   * - inputComponent に対して props として渡される値も指定可能
   */
  inputAttrs?: {
    [attrName: string]: any
    rows?: number
    /**
     * 編集フォーム上の このカラムの input 要素 を 囲う wrapper の class を指定可能です。
     */
    wrapperClass?: string | string[]
    /**
     * 編集フォーム上の このカラムの input 要素 を 囲う wrapper の style を指定可能です。
     * 参照: https://vuejs.org/guide/essentials/class-and-style#binding-inline-styles
     * "inputAttrs_wrapperStyle_maxWidth": "480px",
     *
     * @example
     * ```ts
     * inputAttrs: {
     *   // 幅を指定する 例 ※ フォームの並び・幅 を指定して 見た目を整理することは 非常に重要であるため 下記例 もしくは ColumnDef.width プロパティで制御することを推奨します。
     *   wrapperStyle: {
     *     // 残りの幅を埋める指定
     *     flexGrow: 1,
     *     // or, 自動幅
     *     maxWidth: 'max-content',
     *     // or, 固定値で指定
     *     maxWidth: '280px',
     *     // or, 最小値を指定
     *     minWidth: '200px',
     *   }
     * }
     * ```
     */
    wrapperStyle?: string | Record<string, any> | Array<Record<string, any>>
    /**
     * 編集画面にて 接尾辞を表示する場合に指定します。
     * - 例: kg, 円, M, cm など
     */
    suffix?: string
    /**
     * 編集画面にて 接頭辞を表示する場合に指定します。
     * - 例: ￥, $, € など
     *
     * ```ts
     * // 高度な例
     * webBookingAvailableDays: {
     *   type: 'NUMBER',
     *   label: 'Web予約可能期間',
     *   defaultValue: 60,
     *   inputAttrs: {
     *     prefix: '本日から',
     *     suffix: '日後まで',
     *   // ...
     * ```
     */
    prefix?: string
    /**
     * (RELATIONSHIP_MANY_TO_ONE 等の場合のみ) 編集画面にて 選択されたレコードを開くためのリンクテキストを変更する場合に指定します。
     */
    detailLinkText?: string | ((row: any, recordRoot: any) => string)
  }
  /**
   * フォームにおけるカラム幅を 横幅の比率で指定可能です。
   * - 1 から 48 までの数値を設定
   * - 48 が全幅, 24 が半分幅, 12 が 1/4 幅
   * - md を 設定しない場合は、sm or xs が 適用されます (例: 常に全幅で表示する場合には xs: 48 のみを設定するだけでOK)
   * - px 単位で指定する場合は inputAttrs.wrapperStyle.minWidth / maxWidth で指定してください。
   * - type: 'TEXT' | 'RICHTEXT' | 'ONE_TO_MANY_RELATIONSHIP' の場合には、 48 (全幅) 推奨
   *
   */
  width?: {
    md?: number | 'grow-1' // PCサイズ以上で適用, default: 12 (1/4幅)
    sm?: number | 'grow-1' // タブレットサイズ以上で適用, default: 16 (1/3幅)
    xs?: number | 'grow-1' // スマホサイズ以上で適用, 通常 48を推奨, default: 48 (全幅)
  }
  /**
   * 入力欄すぐ下に 小さい文字で表示される 入力補助テキストを設定します。
   */
  inputHelpText?: string | HTMLContentString

  /**
   * ネストしたレコードの 最大数を設定します
   * - ONE_TO_MANY_RELATIONSHIP, or ARRAY_OF_OBJECT の場合に適用
   */
  maxValueLength?: number
  /**
   * ネストしたレコードの 最小数を設定します
   * - ONE_TO_MANY_RELATIONSHIP, or ARRAY_OF_OBJECT の場合に適用
   */
  minValueLength?: number
  /**
   * ARRAY_OF_OBJECT の場合にのみ利用, ネストしたカラム定義を設定します
   * 例:
   * ```ts
   * columns: {
   *   // ...
   *   item_name: {
   *     label: '商品名',
   *     type: 'STRING',
   *   },
   * }
   * ```
   */
  columns?: ColumnDefByColName

  /**
   * @deprecated
   */
  private?: boolean

  /**
   * DB用のSchema定義
   */
  columnSchema?: Partial<DirectusColumnSchemaDef>
  /**
   * DB用のSchema定義
   */
  directusMeta?: Partial<DirectusColumnMetaDef>

  /**
   * For editing styles
   * Hide input elem(s) on initial state
   */
  displayAsDetail?: true

  /**
   * 一覧検索時の検索フィールドとしての挙動を定義
   * falseなら検索フィールドとして表示しないようにする
   */
  searchBehavior?:
    | {
        displaySelectionAsSelectbox?: boolean
        allowMultiSelect?: boolean
        inputType?:
          | 'selections'
          | 'daterange'
          | 'numberrange'
          | 'boolean'
          | 'like-contain'
        selectOptions?: any[] | Function
        forceOperationKey?: keyof typeof stringFilterTypes
        placeholder?: string
        gridItemClass?: string // table の場合のみ
      }
    | false

  /**
   * type: 'RELATIONSHIP_MANY_TO_ONE' の場合に 設定を保持
   */
  relationshipManyToOne?: {
    /**
     * 参照先のモデル名
     */
    collectionName: string
    /**
     * 参照先の VirtualModel名 (あれば)
     */
    virtualModelName?: string
    /**
     * 一覧および 選択肢で表示する場合のラベルフォーマット関数を指定します。
     * 例:
     * ```ts
     * // 参照先のModelにて name と code というカラムがある場合
     * labelFormatter: (record) => `${record.name} (${record.code})`
     * ```
     */
    labelFormatter?: ((record: any) => string) | FunctionString
    /**
     * フォーム上での選択肢を取得する際の絞込条件を指定します。
     * 例:
     * ```ts
     * // 例1: シンプルな 参照先モデルのカラム値でのフィルタリング
     * filterByAttrs: {
     *   status: { _eq: 'active' }
     *   // 他の条件も指定可能
     *   // ...
     * }
     *
     * // 例2: カスタム関数でのフィルタリング
     * filterByAttrs: (record) => {
     *   return {
     *     status: { _eq: 'active' },
     *     // 編集中の レコードの category と同じカテゴリのもののみを選択可能とする
     *     category: { _eq: record.category }
     *   }
     * }
     * ```
     */
    filterByAttrs?: findAllByArgument
    /**
     * 選択肢に空の選択肢を許可する場合に true を設定
     */
    allowEmptySelection?: boolean
    /**
     *
     */
    emptyLabel?: string
    /**
     * 編集画面上で 選択肢として取得する場合に、取得するフィールドを指定します。
     * 特に、より nest したデータを label表示に利用する等で取得したい場合に利用します。
     *
     * @example
     * ```ts
     * // 参照先のModelにて material, prev_tran_record というカラムが それぞれ M2O Relationship である場合の例
     * findQueryFields: ['*', 'material.*', 'prev_tran_record.*', 'prev_tran_record.*.*', 'prev_tran_record.prev_tran_record.*.*.*']
     * ```
     */
    findQueryFields?: string[]
    /**
     * 選択肢をフィルタリングするための関数を指定します。
     * - この関数 `filterSelectionsByRowState` は 選択肢の取得後に Front で 実行されるため、通常は 上記 `filterByAttrs` の利用を推奨します。複雑な処理を実施して 選択肢の絞込 および Label の加工 を行いたい場合にのみ利用します。
     *
     * ```ts
     * // 順序の変更と label の 条件付け生成の例
     * filterSelectionsByRowState: ({ selections, row, dataList }) => {
     *   // displayOrder で sort 昇順
     *   return dataList
     *     .sort((a, b) => {
     *       return a.displayOrder - b.displayOrder
     *     })
     *     .reduce((acc, cur) => {
     *       if (cur.name !== '本部') {
     *         acc.push({
     *           key: cur.id,
     *           label:
     *             $core.$models.workResults.columns?.officeName?.labelFormatter?.(
     *               cur,
     *             ) || `${cur.code}_${cur.name}`,
     *         })
     *       }
     *       return acc
     *     }, [])
     * ```
     *
     * @param selections - 生成された選択肢のリスト (value は primaryKey)
     * @param row - 編集中のデータの状態
     * @param dataList - 取得された リレーション先のレコードリスト
     */
    filterSelectionsByRowState?: ({
      selections,
      row,
      dataList,
    }: {
      selections: SelectOptionItemObject[]
      row: EditingRecord
      dataList: Record<string, any>[]
    }) => Promise<SelectOptionItem[]> | SelectOptionItem[]
    /**
     * (RELATIONSHIP_MANY_TO_ONE の場合のみ) 編集画面にて リレーション先のレコードを新規追加するリンクを表示する場合に true を設定します。
     */
    enableAddNewLink?: boolean
  }
  /**
   * # before / afterComponents
   *
   * 編集フォームの入力コンポーネントの "前" or "後" に表示する Vue コンポーネントを指定可能です。
   *
   * ## Component で 受け取り可能な props
   *
   * コンポーネント定義としては `props: string[]` で指定, もしくは this.$attrs.record などで参照します
   * - value // カラムの 編集中の値
   * - col // カラムの ColumnDef
   * - record // 編集中のレコード
   * - colName // カラム名
   * - recordRoot // 編集中のレコードのルート (ARRAY_OF_OBJECT の場合のみ, それ以外は record と同じ)
   * - modelName // モデル名
   * - inputAttrs // カラムの inputAttrs (ColumnDef.inputAttrs と 同じ)
   * - virtualModelName? // VirtualModel名 (あれば)
   * - ModelFormService // ModelFormServiceInstance
   *
   * ## 使用例
   *
   * ### 例: 編集フォーム内で 小見出しを表示 (beforeComponents) ※ よくあるケース
   * ```ts
   * beforeComponents: [
   *   { template: '<h4>商品情報</h4>', props: [] }
   * ]
   * ```
   *
   * ### 例: 編集フォーム内で リンク を表示 (afterComponents)
   * ```ts
   * afterComponents: [
   *   {
   *     props: ['record'],
   *     template: `
   *       <a v-if="selectOptionGroupName" class="small" href="#" @click.prevent="() => openListModal()">選択肢マスタ "{{ selectOptionGroupName }}" 一覧
   *         <ficon type="external-link-alt"/>
   *       </a>`,
   *     computed: {
   *       selectOptionGroupName() {
   *         return this.record?.selectionsWithSelectOptionsMasterGroupName
   *       },
   *     },
   *     methods: {
   *       openListModal() {
   *         $core.$modals.openListViewModal({
   *           modelName: 'selectOptionsMaster',
   *           filters: {
   *             group: { _eq: this.selectOptionGroupName },
   *           },
   *         })
   *       },
   *     },
   *   }
   * ]
   * ```
   *
   */
  beforeComponents?: ColumnBeforeOrAfterComponent[]
  /**
   * @inheritdoc beforeComponents
   */
  afterComponents?: ColumnBeforeOrAfterComponent[]
  /**
   * @deprecated `beforeComponents` を利用してください
   */
  beforeComponent?: ColumnBeforeOrAfterComponent
  /**
   * @deprecated `afterComponents` を利用してください
   */
  afterComponent?: ColumnBeforeOrAfterComponent

  // list形式の表示の場合のみ
  listLabelFormatter?: LabelFormatterFunction
  // こちらは、Disabled時にも利用される
  labelFormatter?: LabelFormatterFunction
  /**
   * `virtualColumnOf` を設定したフィールドは実カラムを持たない。代わりに、virtualColumnOf で設定したカラムに保存されるオブジェクトのプロパティとして取り扱われる。
   */
  virtualColumnOf?: string

  // set length of digits and decimals for FLOAT and DECIMAL column type
  numericPrecision?: number
  // set length of decimals for FLOAT and DECIMAL column type
  numericScale?: number

  // @deprecated use numericPrecision
  numeric_precision?: number
  // @deprecated use numericScale
  numeric_scale?: number

  /**
   * type: 'RELATIONSHIP_ONE_TO_MANY' (1対多 のリレーションシップ) の設定
   */
  relationshipOneToMany?: {
    /**
     * 参照先のモデル名
     * - 例: 本モデルが `orders` で `order_details` という 1対多 のリレーションシップを持つ場合 `order_details` のモデル名を指定します。
     */
    collectionName: string
    /**
     * 参照先の VirtualModel名 (あれば)
     */
    virtualModelName?: string
    /**
     * 参照先から 本モデルへの リレーションされるカラム名
     * - 本モデルのプライマリキーを参照するカラム名 (type: 'RELATIONSHIP_MANY_TO_ONE') を指定します。 (例: `order_id` など)
     */
    fieldName: string
    /**
     * 一覧画面で表示する場合のラベルフォーマット関数を指定します。 ※ 編集画面では利用されません
     * 例:
     * ```ts
     * // 参照先のModel `order_details` にて product_name と quantity というカラムがある場合
     * labelFormatter: (records) => records.map((record) => `${record.product_name} (${record.quantity})`).join(', ')
     * ```
     **/
    labelFormatter?: ((records: Record<string, any>[] | any) => string) | FunctionString
    /**
     * @deprecated
     */
    // customLabelFunction?: LabelFormatterFunction
    /**
     * TODO: 未実装
     * @notImplemented
     */
    // filterByAttrs?: findAllByArgument
    /**
     * 選択肢に空の選択肢を許可する場合に true を設定
     */
    // allowEmptySelection?: boolean
    // filterSelectionsByRowState?: ({ selections, row, dataList }) => any
    // maxSelectable?: number
    /**
     * フォーム表示内でリレーション先のデータを並び替えるためのキーを指定します
     * - 例: `order_details` 内の `sort_in_order` (type: 'NUMBER') カラムを指定して、 orders 内の 順番を保持
     */
    sortFieldName?: string
  }
  /**
   * syncModel (カラム定義を同期させる) から排除する
   * - true に指定した場合は DBテーブルのカラムは作成されません
   */
  doNotSyncModel?: true
  // 条件式ビルダで使用
  conditionalExpression?: {
    builderLabels?: {
      defaultValue?: string
      propName?: string
      relationalOperator?: string
      threshold?: string
      logicalOperator?: string
      returnValue?: string
    }
    propSelections?(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ): Promise<SelectOptionItem[]> | SelectOptionItem[]
  }

  /*
   * 編集画面で 参照先の情報をモーダルで開くリンクを非表示にする際に true に設定 (type が M2O,M2M の時のみ)
   */
  hideOpenDataLink?: boolean

  /**
   * 入力フォームにおける並び順を定義 ASC
   * - この値に設定した内容が 編集フォーム上 本カラムの wrapper に CSS で `order: ${orderOnForm};` として適用されます。 (display: flex で制御しています。)
   */
  orderOnForm?: number

  /**
   * 編集画面での カラム表示のグルーピングの設定を実施, Model定義側の formColumnGroups で定義した key を指定します
   */
  groupKey?: string

  /**
   * MANY_TO_ANY の 設定
   *
   * @notImplemented
   */
  relationshipManyToAny?: {
    junctionCollectionParentReferenceColName?: string
    junctionCollectionName?: string
    junctionItemReferenceColName?: string // default 'item'
  }

  /**
   * 一覧表示 アイテムごとの 表示時 (Table Cell等) の HTML属性を オブジェクトで設定
   * - セルごとに色を変える, セルの幅を変更する 等
   *
   * @example
   * ```ts
   * // 一覧セルへの css class を 付与 (文字を省略しない & 背景赤色設定)
   * listItemAttrs: { class: { 'no-ellipsis': true, 'bg-danger': true } }
   * ```
   */
  listItemAttrs?: { [key: string]: any } & { class?: Record<string, boolean> }

  /**
   * 一覧表示 アイテムごとの 表示時 (Table Cell等) の Vue コンポーネントを設定可能
   *
   * ## props
   * - `colName`: カラム名 (string)
   * - `column`: カラム定義 `ColumnDef`
   * - `DataListDisplayServiceInstance`: `DataListDisplayService` のインスタンス
   * - `item`: 対象のレコード
   *
   * @example
   * ```ts
   * // 登録しておいたVueコンポーネント名を指定
   * listItemComponent: 'OrderNumberListItemDisplay',
   * // or Object 形式でVueコンポーネントを指定
   * listItemComponent: {
   *   template: `<div>{{ item[colName] }}</div>`,
   *   props: ['colName', 'column', 'DataListDisplayServiceInstance', 'item']
   *   // ...
   * }
   * ```
   */
  listItemComponent?: ComponentResolver
}

// key は 物理カラム名
export type ColumnDefByColName = { [colName: string]: ColumnDef }

// TODO: 重複修正
export interface EditCallback {
  ({
    row,
    key,
    newValue,
    oldValue,
    isNewRecord,
    callerVueInstance,
  }: {
    row: any
    key: string
    newValue: any
    oldValue: any
    isNewRecord: boolean
    callerVueInstance?: ComponentPublicInstance
  }): Promise<object> | object
}

export type LabelFormatterFunction = (record: any) => string

// TODO: 重複感
export type ModelRowEditCallbackFunctionType = EditCallback

export type ModelDatasourceType = 'directus'

export type ModelFunctionOverrides = {
  find?: (query: FindQuery<any> | null) => Promise<any>
  bulkUpsert?: (args: BulkUpsertParam) => Promise<any>
  bulkDelete?: (args: BulkDeleteParam) => Promise<any>
  findById?: (id: number | string) => Promise<any>
}

export type sortableFieldObject = { value: string; text: string }

type RecordStateBasedModifyableJudgeFunction = (
  record: any,
  callerVueInstance: boolean,
) => Promise<boolean> | boolean

/**
 * Model定義
 */
export class ModelDef {
  /**
   * モデル名
   * - `$core.$models[modelName]` のキーとなる
   * - DBテーブル名としても利用
   * - 英数字とアンダースコアのみ利用可能
   */
  tableName: string
  /**
   * カラム定義
   * - カラム名 (DBカラムの物理名) がキー
   * - 本 columns の並びが 編集フォーム上の表示順となる (ColumnDef.orderOnForm で 調整可能)
   */
  columns: { [colName: string]: ColumnDef }
  // モデルの名称 (表示名)
  tableLabel: string
  // 管理用説明
  tableComment?: string
  /**
   * - カラム `createdAt` および `updatedAt` が自動で追加される
   * - default true であり、明示的に false を設定することで無効化可能
   */
  timestamps?: boolean
  // false の場合、画面からの新規作成不可 (API の権限とは異なる)
  creatable?: boolean
  // false の場合、画面からの更新不可 (API の権限とは異なる)
  updatable?: boolean
  // false の場合、画面からの削除不可 (API の権限とは異なる)
  deletable?: boolean
  /**
   * レコードの状態に応じて 編集, 削除可能かどうかを判定する関数を定義可能
   * - 関数が false を返す場合にのみ 編集, 削除が不可となる
   *
   * @example
   * ```ts
   * // レコードのステータスと ユーザの権限 ($core.$embAuth.user.coreRoles: string[]) によって編集可能かどうかを判定する例
   * isUpdatableByRecordState: async (record, callerVueInstance) => {
   *   if (record.status === 'draft') {
   *     if(!$core.$embAuth.user.coreRoles.includes('admin')) {
   *       return false // 管理者以外は draft のレコードは編集不可
   *     }
   *   }
   * }
   * ```
   *
   */
  isUpdatableByRecordState?: RecordStateBasedModifyableJudgeFunction
  isDeletableByRecordState?: RecordStateBasedModifyableJudgeFunction
  /**
   * 保存時の排他制御, 同時更新を防ぐために利用します。
   * true を設定すると、保存時に更新日時のチェックを行い、更新されている場合はエラーを返します。
   */
  enableCheckDuplicateUpdateOnBeforeSave?: boolean
  // 複製可能かどうか
  replicable?: boolean
  /**
   * @deprecated
   * データソース先を定義。デフォルトは 20210604現在 'directus' である
   */
  datasource?: ModelDatasourceType
  /**
   *
   */
  anotherDataSourceName?: string

  // validation
  validates?: ModelValidationConfigItem[]

  /**
   * Define default values object if needed
   */
  defaultValues?: () => Promise<{ [colName: string]: any }> | { [colName: string]: any }

  /**
   * Function called on input. With this can transform record on input (calc other properties and so on)
   * @param row - record data
   * @param key - edited column name
   * @param newValue
   * @param oldValue
   * @param isNewRecord
   */
  editCallback?: ModelRowEditCallbackFunctionType

  /**
   * # ModelDef.beforeSave: Model定義のBeforeSave関数を利用してデータ保存前の処理を行う
   *
   * ## 機能概要
   * BeforeSave関数を使うことで、データベースのレコードが新規作成・更新・削除される際（一括更新・削除を含む）に、データの整形や処理が行えます。この関数を設定する方法は、Model定義にbeforeSave関数を登録する方法と、$appHookで関数を登録する方法の2つがあります。
   *
   * ```ts
   * // Model定義内のbeforeSave関数
   * beforeSave?(saving: any, index?: number, allSaving?: any[]): Promise<any> | object
   * ```
   * ## 実装例
   *
   * ### A. データを保存する直前に整形する
   * 以下の例では、データベースにデータを保存する前に、カラムAとカラムBを掛け算して、新たなカラムに格納しています。
   * ```ts
   * // Model定義にて
   * const testProducts: ModelDef = {
   *  ...
   *  async beforeSave(saving, index, allSaving) {
   *       // カラムAとBを掛け算して、新たなカラムに格納
   *       saving.total = saving.price * saving.amount
   *    return saving // データは返却が必須
   *  }
   *  ...
   * }
   *
   * ///////////////
   *
   * // $appHookを利用する場合
   * // Hook名: `${modelName}.beforeSave`
   * $core.$appHook.on('testProducts.beforeSave', async (data) => {
   *  // カラムAとBを掛け算して、新たなカラムに格納
   *  data.total = data.price * data.amount
   *  return data
   * })
   * ```
   *
   * ### B. 保存前にバリデーションを実施する
   * 保存する前にデータのバリデーションを行い、バリデーションが不正な場合はエラーをスローして処理を停止することができます。
   * ```ts
   * // Model定義にて
   * const testProducts: ModelDef = {
   *  ...
   *  async beforeSave(saving, index, allSaving) {
   *    // バリデーション
   *    // バリデーションがNGの場合
   *    if (invalidData) {
   *      // エラーをスローすると、ErrorToastでメッセージが表示される。1件でもエラーをスローすると、一括更新の場合でも処理が停止し、保存処理が行われない。
   *      throw new Error('フィールドXXXはYYYYでなければなりません')
   *    }
   *    return saving // データは返却が必須
   *  }
   *  ...
   * }
   * ```
   *
   */
  beforeSave?(saving: any, index?: number, allSaving?: any[]): Promise<any> | object

  /**
   * Browser でのみ挙動する afterSave
   */
  afterSave?(saved: any, index?: number, allSaved?: any[]): Promise<any> | object

  beforeDelete?(
    deletingId: PrimaryKeyValue,
    index: number,
    allDeletingIds: PrimaryKeyValue[],
  ): Promise<any> | any

  /**
   * Browserでのみ挙動するafterDelete
   */
  afterDelete?(
    deletedId: PrimaryKeyValue,
    index: number,
    allDeletedIds: PrimaryKeyValue[],
  ): Promise<any> | any

  defaultSort?: { key: string; order: 'desc' | 'asc' } | string | string[]

  /**
   * 一覧表示時の、表示順制御にカラムを指定することができる
   * - columnName の配列で指定する場合: ['createdAt', 'colA', 'colB']
   * - 表示順選択肢ラベルも含めて直接指定する場合: [{value: 'colA', text: 'A 昇順 (ASC)'}, {value: '-colA', text: 'A 降順 (DESC)'}]
   */
  sortableFields?: (string | sortableFieldObject)[]

  /**
   * キーワード検索の対象になるカラムを指定することができる
   * ['*', 'hospital.name', 'hospital.slug']
   */
  keywordSearchTargetColumns?: string[]

  // TODO: 再考... コレで良いんだろうか, 良い気もする
  groupedEditKeys?: ['groupedKey'] // | Function

  /**
   * 一覧画面での 一括操作の設定 (default true)
   */
  bulkControllable?: boolean
  /**
   * 一覧画面での 一括操作を 関数で追加可能
   *
   * @example
   * ```ts
   * // 例: 一括承認 操作を追加
   * bulkControlActions: {
   *     approve : {
   *       label: '一括承認'
   *       function: async ({modelName, virtualModelName, targetIds}) => {
   *         // targetIds: チェックを入れたレコードの id の配列
   *         if (await $core.$toast.confirmDialog(`${targetIds.length}件を一括承認します。よろしいですか？`, {
   *           okVariant: 'success',
   *           okTitle: '承認'
   *         }) === false) {
   *           return
   *         }
   *         const updateWorkflows = []
   *         const updateActivities = []
   *
   *         for (const id of targetIds) {
   *           const record = await $core.$models.activities.findOne({filter: {id: {_eq: id}}})
   *           const workflow = await $core.$models.activityWorkflows.findOne({
   *             filter: {activityId: {_eq: id}},
   *             sort: ['-id']
   *           })
   *           if (!workflow) {
   *             // 承認対象なし
   *             await $core.$toast.errorToast('承認に失敗しました。画面を再読込してください。')
   *             return
   *           } else {
   *             // 承認処理
   *
   *             const newStatus = getNextStatus(workflow)
   *             // ワークフロー更新
   *             updateWorkflows.push({
   *               id: workflow.id,
   *               status: newStatus,
   *             })
   *             if (newStatus === '承認済み') {
   *               // 承認済みの場合、実績ステータスも変更する
   *               updateActivities.push({
   *                 id: id,
   *                 approvalStatus: '完了',
   *               })
   *             }
   *           }
   *         }
   *         // ワークフローを更新する
   *         await $core.$storeMethods.bulkUpsert({
   *           modelName: 'activityWorkflows',
   *           data: updateWorkflows,
   *           quiet: true
   *         })
   *         // 実績を更新する
   *         await $core.$storeMethods.bulkUpsert({
   *           modelName: 'activities',
   *           data: updateActivities,
   *           disableBeforeSaveFunction: true,
   *           disableAfterSaveFunction: true,
   *           quiet: true,
   *         })
   *         await $core.$toast.successToast('一括承認を実行しました。')
   *       },
   *     }
   *   },
   */
  bulkControlActions?: BulkControlActions

  /**
   * 一括編集を 無効にしたい場合にのみ false を設定 (default true)
   */
  bulkEditable?: boolean

  /**
   * データ取得時、常にフィルタをかけた状態にしておきたい場合に利用する。
   * 例: `{deletedAt: {_eq: null}}` (論理削除の例)
   */
  dataFilters?: { [colName: string]: string | any } | false

  /**
   * 一覧 アイテムクリック時の デフォルトの挙動 (編集モーダルを開く) を書き換える
   *
   * ```ts
   * {
   *   indexListItemOnClick: (id, event, record) => {
   *     // Modal ではなく 編集画面を表示
   *     $core.$router.push(`/m/theModelName/edit/${id}`)
   *   }
   * }
   * ```
   */
  indexListItemOnClick?(
    id: PrimaryKeyValue,
    event: Event,
    record: Record<string, any>,
  ): Promise<any> | any

  /**
   * 一覧表示時の レコードアイテム (行) の属性を 関数で設定可能
   * 利用例: status ごとに (アイテムの行の) 色を変える 等
   * @example
   * ```ts
   * // status が 'NG' の場合、背景色を赤色にする
   * listRecordItemAttrsFunction: (record, index, ComposableDataListServiceInstance) => {
   *   return { class: { 'bg-danger': record.status === 'NG' } }
   * }
   * ```
   */
  listRecordItemAttrsFunction?: (
    record: Record<string, any>,
    index: number,
    ComposableDataListServiceInstance: ComposableDataListService,
  ) => {
    [key: string]: any
  }

  /**
   * "admin" - 管理者用Model, 通常 FrontApp には表示しない
   * ※ 通常は指定不要
   */
  modelType?: 'admin' | ''

  /**
   * プライマリキーの型を指定
   */
  primaryKeyColType?: 'NUMBER' | 'STRING' | 'UUID'
  /**
   * 一覧画面での設定, 検索条件を保存する機能を有効化する (default false)
   */
  enableSearchConditionSave?: boolean

  /**
   * find 関連の挙動のoverrides AnotherDataSourcesHandler 関連などで利用
   */
  overrides?: ModelFunctionOverrides

  /**
   * 特殊なケースのみで利用。true の場合, syncModel (カラム定義を同期させる) から排除する
   */
  doNotSyncModel?: true

  // 一覧表示時、キーワード検索を有効化するかどうか
  enableKeywordSearch?: boolean

  /**
   * 一覧表示時 の デフォルトの fields 値指定, 一覧表示時に ネストしたリレーション先まで取得する必要がある場合に指定する
   * 例1: 特定のModelのみ、ネストを深く取りたいとき `['*.*.*']` と指定
   * 例2: 特定のフィールドのリレーションのみ取りたいとき `['user_id.*', 'productId.*.*', 'paymentId.*']` と指定
   * 例3: いくつかのフィールドのみ取得 `['id', 'username', 'email']` と指定
   */
  defaultFieldsParamExpression?: string[]

  /**
   * Edit Form にて、fetch しておく fields を指定する。編集フォーム内でのリレーション先のデータの表示をコントロールする際に利用する。
   * - default では、編集フォーム上では リレーション先は 取得しない (fetchFieldsOnEditForm: ['*'] と同じ)
   * 例1: 特定の Relation field のみ、ネストを深く取りたいとき `['*.*.*']` と指定
   * 例2: 特定のフィールドのリレーションのみ取りたいとき `['*', 'user.*', 'product.*.*', 'payment.*']` などと指定
   *
   * ## ユースケース: リレーション先のデータをフィールドとして表示する
   * - この設定と カラム定義の `virtualColumnOf` を組み合わせると、リレーション先のデータでも あたかも そのカラムが実カラムであるかのように表示することが可能になる。 ※ Javasctipt のプログラム上では、実カラムではないので注意
   *   - これにより、UI・使い勝手上での "モデル定義の継承" が可能になる。
   *     - 例えば、 `user` モデルを継承した `adminUser` モデルを作成する場合に: `adminUser` モデルの カラム `user_relation` を設定, type を `RELATIONSHIP_MANY_TO_ONE` に指定しつつ、 email フィールドにて `virtualColumnOf: 'user_relation'` に設定, `fetchFieldsOnEditForm` に `['*.*']` を指定することで、 `adminUser` モデルの編集フォーム上に `user` モデルのカラムが表示されるようになる。
   */
  fetchFieldsOnEditForm?: string[]

  /**
   * フォームのstyleを変更できる
   * type: 'table' にすると、テーブルlikeなフォームになる。
   */
  formStyle?: {
    type: 'normal' | 'table' | 'labeled'
  }

  /**
   * フォーム上でのカラムグルーピングの定義を指定
   * accordion, tab などを指定できる
   * カラム定義側では groupKey: string で指定する
   */
  formColumnGroups?: ModelDefFormColumnGroups

  /**
   * 拡張機能のオプション定義
   */
  extensions?: ModelExtensions

  /**
   * ModelExtensionModel (Model定義に拡張機能を付与するModel) としてのModelである場合にのみ存在。拡張機能付与先のModel名を指定。
   */
  extensionBaseModelName?: string
  /**
   * ModelExtensionModel (Model定義に拡張機能を付与するModel) としてのModelである場合にのみ存在。拡張機能のカテゴリを指定。
   */
  extensionType?: ModelExtension['extensionType']
}

type BulkControlActionFunction = ({
  modelName,
  virtualModelName,
  targetIds,
}: {
  modelName: string
  virtualModelName?: string
  targetIds: PrimaryKeyValue[]
}) => Promise<any>

export interface BulkControlActions {
  [actionName: string]: {
    function: BulkControlActionFunction
    label?: string
  }
}

export type CustomValidationFunction = (
  value: any,
  col: ColumnDef,
  modelName: string,
  record: any,
  recordRoot: any,
) => string | boolean | void | Promise<string | boolean | void>

export interface IValidates {
  [builtInValidateOrCustomValidateFunction: string]:
    | CustomValidationFunction
    | number
    | boolean
    | undefined
    | string
    | [any, any]

  isInt?: true
  isFloat?: true
  notEmpty?: true
  isEmail?: true
  isValidJsObject?: true
  min?: number
  max?: number
  isSingleByte?: true
  isAlpha?: true
  isAlphanumeric?: true
  isKatakana?: true
  isHiragana?: true
  isPhoneNumberWithoutHyphen?: true
  isPhoneNumberWithHyphen?: true
  isPhoneNumberGlobalHeadPlus?: true
  contains?: string
  len?: [number, number] // [min, max]
  regex?: [string, string] // [pattern, regexpFlags]
}
