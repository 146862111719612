<template>
  <div class="small text-muted modelInput-inputHelpText input-help-text" v-html="renderValue"/>
</template>
<script lang="ts">
export default {
  name: 'InputHelpTextRenderer',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    renderValue() {
      return this.value //.replace(/\n/g, '<br>');
    }
  }
}
</script>
