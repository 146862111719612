<template>
  <div class="d-flex flex-column gap-1">
    <div
      class="d-flex flex-wrap gap-1 align-items-center"
      v-if="FilterControlsService?.initialized"
      :key="collectionName"
    >
      <FilterItemAppend :addFilterButtonText="addFilterButtonText" />
      <ModernFilter v-if="!!FilterControlsService.filterGroupService" />
      <FilterResultItem
        v-for="(
          filterItemService, colNamePathString, index
        ) in FilterControlsService.filterItemServices"
        :key="index"
        :filterItemService="filterItemService"
      />
    </div>
    <a
      v-if="showOpenListLink"
      class="small d-inline-block"
      href="#"
      @click.prevent="() => openListModal()"
      ><ficon type="external-link-alt" /> 一覧で見る</a
    >
  </div>
</template>
<script lang="ts">
import { computed, inject, PropType, provide } from 'vue'
import { ColumnDef, ColumnDefByColName } from '../../../../common/$models/ModelDef'
import { ModelFormService } from '../../../../front/ModelForm/ModelFormService'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { ComposableDataListService } from '../ComposableDataListService'
import { FilterControlsService } from '../FilterControlsService'
import FilterItemAppend from './FilterControls/FilterItemAppend.vue'
import FilterResultItem from './FilterControls/FilterResultItem.vue'
import ModernFilter from './FilterControls/ModernFilter.vue'

const name = 'FilterResultDisplayContainer'
registerComposableComponentSettings(name, {
  label: name,
  hasDefaultSlot: true,
  configColumns: {
    addFilterButtonText: {
      label: 'フィルターボタンのテキスト',
      type: 'STRING',
      defaultValue: 'フィルターを追加',
    },
  },
  defaultProps: {},
  description: `データのフィルター機能を提供します。`,
  images: [
    {
      img: '/images/cc_images/FilterResultDisplayContainer1.png',
    },
    {
      img: '/images/cc_images/FilterResultDisplayContainer2.png',
    },
    {
      img: '/images/cc_images/FilterResultDisplayContainer3.png',
    },
  ],
})
export default {
  name,
  props: {
    modelValue: {
      type: [String, Object],
      required: false,
    },
    collectionName: {
      type: String,
      required: false,
    },
    col: {
      type: Object as PropType<ColumnDef>,
      required: false,
    },
    record: {
      type: Object,
      required: false,
    },
    addFilterButtonText: {
      type: String,
      default: 'フィルターを追加',
    },
    emitValueAsObject: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object as PropType<ColumnDefByColName>,
      required: false,
    },
    showOpenListLink: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  components: {
    FilterItemAppend,
    FilterResultItem,
    ModernFilter,
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
        null,
      ),
      ModelFormServiceInstance: inject<ModelFormService>(
        'ModelFormServiceInstance',
        null,
      ),
    }
  },
  data() {
    return {
      // FilterControlsService
      FilterControlsService: null as FilterControlsService,
    }
  },
  computed: {
    searchConditionSaveKey() {
      if (this.ComposableDataListServiceInstance) {
        return this.ComposableDataListServiceInstance.modelName
      } else {
        return null
      }
    },
  },
  watch: {
    collectionName(newValue, oldValue) {
      // リセットする
      if (newValue !== oldValue) {
        if (this.FilterControlsService) {
          this.FilterControlsService.initialized = false
        }
        this.$nextTick(() => {
          this.FilterControlsService = null
          this.initService()
          this.$emit('update:modelValue', {})
        })
      }
    },
    record: {
      handler(val) {
        const relationModelName = val.relationshipManyToOne_collectionName
        if (relationModelName) {
          this.FilterControlsService.relationModelName = relationModelName
        }
      },
      deep: true,
    },
    columns: {
      handler(val) {
        this.FilterControlsService.setValiadtionColumns(val)
      },
      deep: true,
    },
  },
  created() {
    this.initService()
    // FilterControlsServiceをproviderする
    provide(
      'FilterControlsServiceInstance',
      computed(() => this.FilterControlsService),
    )
  },
  methods: {
    initService() {
      if (this.collectionName) {
        this.FilterControlsService = new FilterControlsService({
          ComposableDataListServiceInstance: null,
          ModelFormServiceInstance: null,
          vueInstance: this,
          modelName: this.collectionName,
          emitValueAsObject: this.emitValueAsObject,
        })
      } else {
        if (
          (this.ModelFormServiceInstance &&
            this.ModelFormServiceInstance.modelName == 'modelDefinitions') ||
          this.ComposableDataListServiceInstance
        ) {
          this.FilterControlsService = this.ComposableDataListServiceInstance
            ? this.ComposableDataListServiceInstance.FilterControlsServiceInstance
            : new FilterControlsService({
                ComposableDataListServiceInstance: null,
                ModelFormServiceInstance: this.ModelFormServiceInstance,
                vueInstance: this,
                emitValueAsObject: this.emitValueAsObject,
              })
        }
      }
      if (!this.FilterControlsService) {
        return // do nothing
      }
      if (this.columns) {
        this.FilterControlsService.setValiadtionColumns(this.columns)
      }

      if (this.collectionName) {
        if (this.modelValue) {
          this.FilterControlsService.setFilterFromQuery(this.modelValue)
        } else {
          this.FilterControlsService.initialized = true
        }
      } else {
        if (this.ModelFormServiceInstance) {
          if (this.modelValue) {
            this.FilterControlsService.setFilterFromQuery(this.modelValue)
          } else {
            this.FilterControlsService.initialized = true
          }
        } else {
          this.FilterControlsService.initialized = true
        }
      }
    },
    openListModal() {
      $core.$modals.openListViewModal({
        modelName: this.collectionName,
        filter: this.modelValue || {},
        otherComponentProps: {},
      })
    },
  },
}
</script>
