<template>
  <open-d-admin-with-i-frame
    v-if="pagePath"
    :page-path="pagePath"
    :key="pagePath"
    style="height: 100vh; width: 100%; overflow: hidden"
    :full-overlay="fullOverlay"
  />
</template>
<script>
import OpenDAdminWithIFrame from './openDAdminWithIFrame.vue'

const hideClass = 'hide-header'
// route に設定された meta => params => query の順に評価する
export default {
  components: {
    OpenDAdminWithIFrame,
  },
  computed: {
    pagePath() {
      return (
        this.$route.meta?.pagePath ||
        this.$route.params?.pagePath ||
        this.$route.query?.pagePath
      )
    },
    fullOverlay() {
      return !!(
        this.$route.meta?.fullOverlay ||
        this.$route.params?.fullOverlay ||
        this.$route.query?.fullOverlay
      )
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!window.document.body.classList.contains(hideClass)) {
        window.document.body.classList.add(hideClass)
      }
      setTimeout(() => {
        $core.$uiState.isSidebarOpened = false
      }, 100)
    })
  },
  beforeUnmount() {
    window.document.body.classList.remove(hideClass)
    $core.$uiState.isSidebarOpened = true
  },
}
</script>
