import { singletonInstanceSummoner } from '../singletonInstanceSummoner'

/**
 * # $core.$dict
 *
 * - COREアプリケーション内各所のテキスト表記を管理 (変更するため) のサービス
 * - このサービスを利用していくことで、プロジェクト個別事情に応じたのテキストの変更や、翻訳を実施する
 * - コンポーネント側では、Vueテンプレート, およびJSパート内でシンプルに `$core.$dict.get('Excelエクスポート')` とすることで、書き換え可能な対象となる
 *   - キーを個別で設定したい場合は `{{ $core.$dict.get('chartBuilder.exportAsExcel', 'Excelエクスポート') }}`
 *
 * @category $core/40.customize/403.dict
 */
export class DictionaryService {
  dictionary: { [keyName: string]: string }

  constructor() {
    this.dictionary = {}
  }

  get(key, fallbackContent = key) {
    return this.dictionary[key] || fallbackContent
  }

  set(key, value) {
    this.dictionary[key] = value
  }

  // alias for configure
  get bulkSet() {
    return this.configure
  }

  /**
   * 一括で用語登録を実施する
   *
   * ```ts
   * $core.$dict.configure({
   *   チャートを表示: '一覧表示'
   *   Excelエクスポート: 'エクセル出力'
   * })
   * ```
   */
  configure(dictionary: { [keyName: string]: string }) {
    this.dictionary = {
      ...this.dictionary,
      ...dictionary,
    }
  }

  static get instance() {
    return singletonInstanceSummoner('DictionaryService', DictionaryService)
  }
}

export const $dict = DictionaryService.instance
