<template>
  <nav
    v-if="initialized"
    id="sidebar"
    class="sidebar sidebar-offcanvas position-fixed core-app-sidebar core-app-definition-menu-bg-color"
    :class="[
      $core.$uiState.isSidebarActiveOnMobile ? 'active' : '',
      sidebarDisplayStatus ? '' : 'shrink',
    ]"
  >
    <div class="navbar-sidebar-active-dark-overlay" />
    <ul class="nav core-app-sidebar--app-name">
      <li>
        <span
          class="nav-link d-flex align-items-center position-relative hover-show-parent"
          :style="{
            backgroundColor: 'rgba(0, 0, 0, 0.52)',
            position: 'relative',
            height: '53px',
            padding: 0,
          }"
        >
          <AdminRoleOnly
            class="hover-show-child position-absolute small"
            style="
              white-space: nowrap;
              z-index: 99;
              top: -1px;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              border-radius: 2px;
              padding: 2px 4px;
              font-size: 11px;
            "
          >
            <a
              href="#"
              style="cursor: pointer"
              @click.prevent="
                () =>
                  $core.$modals.openEditViewModal({
                    modelName: 'appDefinitions',
                    id: $core.$appDefinitionLoader.appDefinition?.id,
                    successCallback: () => {
                      $core.$utils.getWindowObject().location.reload()
                    },
                  })
              "
              >アプリケーション定義を編集 <ficon type="external-link-alt"
            /></a>
          </AdminRoleOnly>
          <div
            class="core-app-header-logo d-flex align-items-center"
            :style="{
              flex: '1 1 auto',
            }"
          >
            <div
              class="logo"
              :style="{
                maxWidth: '100%',
                height: '30px',
                marginLeft: '30px',
              }"
            >
              <app-hookable-component
                resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.appName"
              >
                <AppLogoAndName />
              </app-hookable-component>
            </div>
          </div>
          <div
            v-single-click="() => toggleSidebar()"
            class="toggel_button"
          >
            <ficon
              type="angle-double-left"
              class="small"
            />
          </div>
        </span>
      </li>
    </ul>
    <hr class="mt-0 core-app-sidebar--app-name-after-hr" />
    <app-hookable-component
      resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.before"
    />
    <AppDynamicMenu />
    <app-hookable-component
      resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.after"
    />
    <ul class="nav core-app-sidebar--user-menu">
      <li class="nav-item nav-category font-weight-bold mt-4">
        ログイン中: {{ name }} さん
      </li>
      <app-hookable-component
        resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.afterUserName"
      />
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          v-single-click="logout"
          >ログアウト</a
        >
      </li>
    </ul>
  </nav>
</template>
<script lang="ts">
import AppDynamicMenu from '../../AppMenu/AppDynamicMenu.vue'
import AppLogoAndName from './AppLogoAndName.vue'

export default {
  props: {
    initialSidebarDisplayStatus: { default: true, type: Boolean },
  },
  data() {
    return {
      initialized: false,
    }
  },
  components: {
    AppLogoAndName,
    AppDynamicMenu,
    // langSelect: $frameworkUtils.defineAsyncComponent(
    //   () => import('../../I18nService/langSelect.vue'),
    // ),
  },
  computed: {
    name() {
      const user = $core.$embAuth.user
      return (user || {}).name || (user || {}).email || ''
    },
    sidebarDisplayStatus: {
      get() {
        return $core.$uiState.isSidebarOpened
      },
      set(value: boolean) {
        $core.$uiState.isSidebarOpened = !!value
      },
    },
  },
  async mounted() {
    // Wait until
    await $core.$appDefinitionLoader.loggedInExecuteOnceServiceBaseInstance.initPromise
    if (this.initialSidebarDisplayStatus !== $core.$uiState.isSidebarOpened) {
      $core.$uiState.isSidebarOpened = this.initialSidebarDisplayStatus
    }
    this.initialized = true
  },
  methods: {
    async logout() {
      if (
        (await $core.$toast.confirmDialog(`ログアウトします、よろしいですか？`, {
          okVariant: 'success',
        })) === false
      ) {
        return
      }
      await $core.$embAuth.signOut()
    },
    toggleSidebar() {
      $core.$lsCache.set('sidebarDisplayStatus', !this.sidebarDisplayStatus)
      this.sidebarDisplayStatus = $core.$lsCache.get('sidebarDisplayStatus')
      this.$parent.sidebarDisplayStatus = $core.$lsCache.get('sidebarDisplayStatus')
      this.$emit('onToggleSidebar', this.sidebarDisplayStatus)
    },
  },
}
</script>
