// @ts-nocheck
// import Vue from 'vue'
/* Load global components */
import { App } from 'vue'
import AppHookableComponent from '../../common/$appHook/AppHookableComponent.vue'
import ControlDisplayOfAppHookDetail from '../../common/$appHook/ControlDisplayOfAppHookDetail.vue'
import Dict from '../../common/$dict/Dict.vue'
import AdminRoleOnly from '../../plugins/AppAndPageLayouts/front/components/AdminRoleOnly.vue'
import FullsizedPageLayout from '../../plugins/AppAndPageLayouts/front/components/FullsizedPageLayout.vue'
import PageHeader from '../../plugins/AppAndPageLayouts/front/components/PageHeader.vue'
import { registerComposableComponentBuilderComponents } from '../../plugins/ComposableComponentBuilder/front/registerComposableComponentBuilderComponents'
import HistoryBackButton from '../historyBackButton.vue'
import Ficon from '../Icons/Ficon.vue'
import MemoryUsageDisplayPanel from '../Layouts/partials/MemoryUsageDisplayPanel.vue'
import RawHTML from '../Layouts/partials/RawHTML.vue'
import ModalContainer from '../Modal/ModalContainer.vue'
import { registerModelFormComponents } from '../ModelForm/registerModelFormComponents'
import { registerModelIndexComponents } from '../ModelIndex/registerModelIndexComponents'
import OfflineDetectMessage from '../OfflineDetectMessage.vue'
import { loadBootstrapVueComponents } from './loadBootstrapVueComponents'

export const CORELoadCommonComponent = (
  Vue: App,
  { loadBootstrap = true, loadModelForm = true, loadModelIndex = true } = {},
) => {
  if (loadBootstrap) {
    loadBootstrapVueComponents(Vue)
  }
  Vue.config.globalProperties.location = window.location

  Vue.component('RawHTML', RawHTML)
  Vue.component(
    'Devcomment',
    $frameworkUtils.defineAsyncComponent(() => import('../devcomment.vue')),
  )
  Vue.component('JustRedirect', {
    props: {
      to: { required: true },
    },
    created() {
      // TODO: 戻るボタンで戻ってきているときは... さらに1つ戻す
      $core.$router.push({ path: this.to, replace: true })
    },
    template: '<span></span>',
  })
  Vue.component(
    'Loading',
    $frameworkUtils.defineAsyncComponent(() => import('../loading.vue')),
  )
  Vue.component('Ficon', Ficon)
  Vue.component('HistoryBackButton', HistoryBackButton)
  Vue.component('AppHookableComponent', AppHookableComponent)
  // 共通箇所
  if (loadModelForm) {
    registerModelFormComponents(Vue)
  }
  if (loadModelIndex) {
    registerModelIndexComponents(Vue)
  }
  Vue.component(
    'ModelDataFilterPanel',
    $frameworkUtils.defineAsyncComponent(
      () => import('../ModelIndex/ModelDataFilterPanel.vue'),
    ),
  )
  Vue.component(
    'ModelDataFilterQueryInput',
    $frameworkUtils.defineAsyncComponent(
      () => import('../ModelForm/ModelInput/ModelDataFilterQueryInput.vue'),
    ),
  )
  Vue.component(
    'CodeEditor',
    $frameworkUtils.defineAsyncComponent(
      () => import('../ModelForm/ModelInput/CodeEditor/CodeEditor.vue'),
    ),
  )
  Vue.component(
    'CodePreview',
    $frameworkUtils.defineAsyncComponent(() => import('../CodeEditor/CodePreview.vue')),
  )
  Vue.component(
    'CodeSampleWithDemo',
    $frameworkUtils.defineAsyncComponent(
      () => import('../CodeEditor/CodeSampleWithDemo.vue'),
    ),
  )
  Vue.component(
    'SelectButtonGroup',
    $frameworkUtils.defineAsyncComponent(
      () => import('../ModelForm/ModelInput/SelectButtonGroup.vue'),
    ),
  )
  Vue.component(
    'ModelColumnTypeFiltering',
    $frameworkUtils.defineAsyncComponent(
      () => import('../ModelIndex/ModelColumnTypeFiltering.vue'),
    ),
  )

  // portal
  // Vue.use(PortalVue)

  Vue.component(
    'RecordCounter',
    $frameworkUtils.defineAsyncComponent(() => import('../RecordCounter.vue')),
  )
  Vue.component('Dict', Dict)

  Vue.component('FullsizedPageLayout', FullsizedPageLayout)
  Vue.component('AdminRoleOnly', AdminRoleOnly)
  Vue.component('PageHeader', PageHeader)

  registerComposableComponentBuilderComponents(Vue)

  // Layouts basics
  Vue.component('ControlDisplayOfAppHookDetail', ControlDisplayOfAppHookDetail)
  Vue.component('ModalContainer', ModalContainer)
  Vue.component('OfflineDetectMessage', OfflineDetectMessage)
  Vue.component('MemoryUsageDisplayPanel', MemoryUsageDisplayPanel)
}

/* End of load global components */
