/**
 * 管理AppのメニューをLoadする。
 */
import {
  AppMenuItem,
  genLinkWithModelDef,
  genLinkWithModelNames,
} from './appMenuDefinitionParser'
import { generateAppLink } from '../../plugins/AppDefinitions/front/AppDefinitionLoaderService'
import { generateLinkComponentDef } from './generateSidebarLinksToOtherApps'

export const generateAdminAppMenu = async () => {
  const appDefinitions = Object.values($core.$appDefinitionLoader.allAppDefinitionsByKey)
  const appLinks: AppMenuItem[] = appDefinitions.map((appDef) => ({
    type: 'component',
    componentDefinition: generateLinkComponentDef(
      generateAppLink(appDef.key),
      appDef.name,
      'nav-link',
      'external-link-alt',
    ),
  }))
  const menu: AppMenuItem[] = [
    { type: 'heading', label: 'アプリケーション' },
    { type: 'link', label: 'アプリケーション定義一覧', to: '/m/appDefinitions' },
    ...appLinks,
    { type: 'heading', label: 'データモデル定義' },
    { type: 'link', label: 'モデル定義', to: '/m/modelDefinitions' },
    {
      type: 'nested',
      label: 'モデル一覧',
      children: genLinkWithModelNames(
        Object.keys($core.$models).filter(
          (modelName) => $core.$models[modelName].modelType !== 'admin',
        ),
      ),
    },
    {
      type: 'link',
      label: 'Virtualモデル定義',
      to: '/m/virtualModelDefinitions',
    },
    {
      type: 'nested',
      label: 'Virtualモデル一覧',
      children: Object.keys($core.$virtualModels)
        .filter((modelName) => $core.$virtualModels[modelName].modelType !== 'admin')
        .map((modelName) => genLinkWithModelDef($core.$virtualModels[modelName])),
    },
    { type: 'heading', label: '管理' },
    {
      type: 'nested',
      label: 'データ・マスタ管理',
      children: [
        ...genLinkWithModelNames(['dataImportSettings']),
        {
          type: 'link',
          label: 'データインポート実行',
          to: '/importDataFromExternal',
        },
        ...genLinkWithModelNames(['dataExportSettings']),
        {
          type: 'link',
          label: 'データエクスポート実行',
          to: '/exportData',
        },
        ...genLinkWithModelNames([
          'selectOptionsMaster',
          'textTemplates',
          'files',
          'directus_revisions',
          'appDocumentPages',
        ]),
        {
          type: 'link',
          label: 'SQLマネージャ',
          to: '/db-direct-query-executor',
        },
        // {
        //   type: 'link',
        //   label: 'アプリテンプレート',
        //   to: '/app-template',
        // },
      ],
    },
    {
      type: 'nested',
      label: 'ユーザ・権限管理',
      children: [
        genLinkWithModelDef($core.$virtualModels.coreAppUsers),
        genLinkWithModelDef($core.$models.user_roles),
        {
          type: 'link',
          label: '組織',
          to: '/editOrg',
        },
        ...genLinkWithModelNames([
          // 'user_roles',
          // 'organizations',
          'positions',
          // 'userGroups',
          // 'statusBasedWorkflowDefinitions',
        ]),
        // {
        //   type: 'link',
        //   label: 'APIロール',
        //   to: '/api-roles-and-permissions',
        // },
        // genLinkWithModelDef($core.$models.core_user_group_definitions),
        // genLinkWithModelDef($core.$models.core_front_model_permission_config),
      ],
    },
    {
      type: 'nested',
      label: 'コンポーネント管理',
      children: [
        {
          type: 'link',
          label: 'コンポーザブルコンポーネント',
          to: '/m/core_composable_component_definitions',
        },
        {
          type: 'link',
          label: 'チャートビルダー',
          to: '/m/customComponentDefinitions?virtualModel=chartDefinitions',
        },
      ],
    },
    {
      type: 'nested',
      label: 'API・データソース設定',
      children: [
        { type: 'link', label: 'API一覧', to: '/api/list' },
        { type: 'link', label: 'サーバーログ', to: '/server-logs' },
        // TODO:
        // { type: 'link', label: 'APIロール', to: '/da/d/admin/settings/roles', },
        // TODO:
        // { type: 'link', label: 'Webhook設定', to: '/da/d/admin/settings/webhooks' },
        ...[
          'core_another_data_source_connection_definitions',
          'core_another_data_source_dialects',
        ].map((modelName) => genLinkWithModelDef($core.$models[modelName])),
        {
          type: 'component',
          componentDefinition: {
            template: `<a v-if='$core.$embAuth?.user?.isAdmin' class='nav-link cursor-pointer' @click.prevent='startApiDeployment'>APIデプロイの開始</a>`,
            methods: {
              async startApiDeployment(): Promise<void> {
                try {
                  if((await $core.$toast.confirmDialog('APIデプロイを初期化しますか？') === false)) {
                    return
                  }
                  $core.$loading.start(null, 'overlay')
                  await $core.$d.transport.post('/core/startApiDeployment')
                  $core.$toast.successToast('APIデプロイが正常に初期化されました.')
                } catch (e) {
                  $core.$toast.errorToast(e.message)
                  console.error(e)
                } finally {
                  $core.$loading.finish()
                }
              }
            }
          }
        },
      ],
    },
    {
      type: 'nested',
      label: 'カスタマイズ',
      children: [
        ...genLinkWithModelNames(['serverSideAppHooks', 'frontSideAppHooks']),
        {
          type: 'link',
          label: 'フローエディタ',
          to: '/da/d/core/redAdmin',
        },
      ],
    },
    // {
    //   type: 'nested',
    //   label: 'Tasks',
    //   children: [
    //     {
    //       type: 'link',
    //       label: '承認待ち一覧',
    //       to: '/task/appro',
    //     },
    //     ...genLinkWithModelNames([
    //       'core_tasks',
    //       'core_task_relations',
    //       'core_task_assignees_states',
    //       'core_task_action_histories',
    //       'core_task_type_behavior_definitions',
    //       'core_task_related_items',
    //     ]),
    //   ],
    // },
    // {
    //   type: 'link',
    //   label: 'CORE Templates',
    //   to: '/appTemplates'
    // }
  ]
  if ($core.$appHook.hasHook('adminAppMenu')) {
    return $core.$appHook.emit('adminAppMenu', menu)
  }
  return menu
}
