<template>
  <div class="inject-place-component d-flex flex-row flex-wrap">
    <pre class="d-none">lastInjectedAt: {{ lastInjectedAt }}</pre>
    <component
      v-for="({ componentDefinition, componentRefName }, index) in injectedComponents"
      :key="`${componentRefName}-${index}`"
      :is="componentDefinition"
    />
  </div>
</template>

<script>
export default {
  name: 'InjectPlaceComponent',
  props: {
    injectPlaceKey: {
      type: String,
      required: true,
      validator: (value) => ['default-main-before', 'default-main-after'].includes(value),
    },
  },
  data() {
    return {
      // lastInjectedAt: 0,
    }
  },
  computed: {
    injectedComponents() {
      if (
        $core.$componentStore.injectedComponentsByKey[this.injectPlaceKey]?.lastInjectedAt
          ?.value < 0
      ) {
        return []
      }
      return (
        $core.$componentStore.injectedComponentsByKey[this.injectPlaceKey]?.components ||
        []
      )
    },
    lastInjectedAt() {
      return $core.$componentStore.injectedComponentsByKey[this.injectPlaceKey]
        ?.lastInjectedAt
    },
  },
}
</script>

<style scoped>
.inject-place-component {
  display: contents;
}
</style>
