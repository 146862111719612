<template>
  <div
    @dragover="handleDragover"
    @dragenter="handleDragover"
    @dragleave="handleDragleave"
    @drop="handleDrop"
    :class="dragging ? 'bg-light' : ''"
    class="input-image-uploader"
  >
    <div
      v-if="val"
      class="_mb-2 position-relative"
    >
      <a
        :href="$core.$imgix.buildUrl(val)"
        style="
          bottom: -22px;
          right: 1px;
          background-color: white;
          border-radius: 0;
          width: 20px;
          height: 20px;
          text-align: center;
        "
        :target="isImage ? '_blank' : '_self'"
        :download="isImage ? 'true' : null"
        class="position-absolute ignoreItemClick d-block clearfix"
      >
        <ficon
          type="external-link-alt"
          class="ignoreItemClick"
        ></ficon>
      </a>
      <img
        v-if="showPreviewImage && isImage"
        ref="test"
        :src="previewImageUrl"
        style="width: auto"
        :style="{
          maxWidth: maxImageWidth || '280px',
          maxHeight: maxImageHeight || '280px',
          ...(imageStyle || {}),
        }"
      />
      <div>
        <code>{{ displayFileName }}</code>
      </div>
    </div>
    <div
      v-if="contentDisplayFunction && typeof contentDisplayFunction === 'function'"
      v-html="contentDisplayFunction(val)"
    ></div>
    <div v-if="!disableEdit">
      <input
        ref="imageInput"
        type="file"
        label="画像を選択"
        @change="upload()"
        class="d-none"
      />
      <span
        v-if="!autoUpload"
        :class="btnClass"
        v-single-click="() => $refs.imageInput.click()"
        >{{
          buttonText || `ファイルを${previewImageUrl ? '変更' : 'アップロード'}`
        }}</span
      >
      <b-button
        v-if="enableDeleteButton && val"
        :class="btnClass"
        class="ml-2"
        :variant="`outline-danger`"
        v-single-click="deleted"
      >
        削除
      </b-button>
    </div>
  </div>
</template>
<script lang="ts">
import { $imgix } from '../$libs/$imgix'
import {
  generateStrongTimeStampString,
  isImageLikeExtensionFileName,
} from '../../common/utils'
import {
  $fileManager,
  filenameSeparator,
  getOriginalFileNameFromFilePath,
} from './FileManager'
import { uploadPathPrefixProps } from './uploadPathPrefixProps'
// Default 50MB
const maxFileSize = process.env.CORE_UPLOAD_MAX_FILESIZE || 51200000

export default {
  props: {
    ...uploadPathPrefixProps,
    modelValue: {},
    filenameDefinitionLogicFunction: { type: Function, required: false },
    disableEdit: { type: Boolean, default: false },
    btnClass: {
      default: 'btn btn-sm btn-outline-primary mt-2',
      type: String,
    },
    autoUpload: {
      default: false,
      type: Boolean,
    },
    showPreviewImage: {
      default: true,
      type: Boolean,
    },
    buttonText: {
      default: '',
      type: String,
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    successCallbackFunction: {
      default: null,
      type: Function,
    },
    contentDisplayFunction: {
      default: null,
      type: Function,
    },
    useOriginalFileName: {
      default: true,
      type: Boolean,
    },
    enableDeleteButton: {
      default: true,
      type: Boolean,
    },
    uploadMaxFileSize: {
      default: maxFileSize,
      type: Number,
    },
    fileValidationFunction: {
      default: null,
      type: Function,
    },
    maxImageHeight: {
      default: null,
      type: String,
    },
    maxImageWidth: {
      default: null,
      type: String,
    },
    imageStyle: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
  computed: {
    val() {
      return this.modelValue
    },
    domain() {
      return $imgix.domain
    },
    previewImageUrl() {
      return this.val ? $imgix.buildUrl(this.val, { w: 450 }) : null
    },
    isImage() {
      return isImageLikeExtensionFileName(this.val)
    },
    shouldUseOriginalFileName() {
      if (this.useOriginalFileName === false) {
        return false
      }

      if (
        $core.$utils.findNearestParentVueComponentByName(this, 'modelInput')?.colDef
          ?.inputAttrs?.useOriginalFileName === false
      ) {
        return false
      }

      return true
    },
    // shouldUseOriginalFileName の場合には、sliceして表示してあげる
    displayFileName() {
      return this.shouldUseOriginalFileName
        ? getOriginalFileNameFromFilePath(
            this.val.replace(new RegExp(`^.+${filenameSeparator}(.+)$`), '$1'),
          )
        : this.val
    },
  },
  methods: {
    async upload() {
      if (this.disableEdit) {
        return // do nothing
      }
      try {
        const file = this.$refs.imageInput.files[0]
        if (!file) {
          return // do nothing
        }
        if (
          this.fileValidationFunction &&
          typeof this.fileValidationFunction === 'function'
        ) {
          const validationResult = await this.fileValidationFunction(file)
          if (!validationResult) {
            return
          }
        }
        $core.$loading.start('アップロードしています...')
        // 36進数で getTime() を文字列化
        if (this.uploadMaxFileSize > 0 && file.size > this.uploadMaxFileSize) {
          window.alert(
            `選択されたファイルのサイズが大き過ぎます。別のファイルを選択して下さい。 (size: ${file.size})`,
          )
          return
        }
        const { filePath, uploadResult } = await $fileManager.upload(
          file,
          null,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
              $core.$loading.start(`アップロードしています... (${percentCompleted}%)`)
            },
          },
          this.filePathPrefix,
          this.filePathGenerateFunction,
        )
        this.$emit('update:modelValue', filePath)
        if (this.showSuccess) {
          window.alert(`アップロード成功`)
        }
        if (
          this.successCallbackFunction &&
          typeof this.successCallbackFunction === 'function'
        ) {
          this.successCallbackFunction({ filePath, uploadResult })
        }
        if (this.contentDisplayFunction) {
          this.$nextTick(() => {
            this.$forceUpdate()
          })
        }
      } catch (e) {
        console.error(e)
        $core.$toast.errorToast(`アップロードに失敗しました。`)
      } finally {
        $core.$loading.finish()
      }
    },
    getUserDirPath() {
      return `user/${$core.$embAuth.user.id}/`
    },
    async generateFileName(file) {
      // MIMEタイプと拡張子のマッピング
      const mimeTypeToExtension = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'application/pdf': '.pdf',
        'image/jpeg': '.jpg',
        'image/png': '.png',
        // 他の一般的なMIMEタイプと拡張子のマッピング
      }

      // MIMEタイプに基づいて拡張子を取得するか、もしくはファイル名から拡張子を抽出
      let fileExtension =
        mimeTypeToExtension[file.type] || file.name.substr(file.name.lastIndexOf('.'))

      // オリジナルのファイル名を基本として生成
      const originalFileName = file.name
        .normalize('NFC')
        .substr(0, file.name.lastIndexOf('.'))
        .replace(/&/g, '＆')
        .replace(/\//g, '／')

      // ユニークなタイムスタンプを生成する関数 (この部分は独自のロジックに依存します)
      const timestamp = generateStrongTimeStampString()

      // 最終的なファイル名の生成
      const fileName = `file-${timestamp}/${originalFileName}${fileExtension}`
      return `u/${fileName}`
    },
    deleted() {
      this.$emit('update:modelValue', '')
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      this.dragging = true
    },
    handleDragleave(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      this.dragging = false
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      this.dragging = false
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        $core.$toast.errorToast('1ファイルのみ選択してください')
        return
      }
      // this.upload(rawFile)
      this.$refs.imageInput.files = files
      e.stopPropagation()
      e.preventDefault()
      this.$nextTick(() => {
        this.upload()
      })
    },
  },
}
</script>
