import ImgixClient from '@imgix/js-core'
import * as qs from 'querystring'
import { embAuthHooks } from '../auth/$embAuth/$embAuth'

/**
 * 画像変換サービスに渡すクエリパラメータの型定義
 */
export type QueryParamsInput = {} & any // TODO: より詳細な型定義

/**
 * ImgixClientのインスタンス
 * - `domain`: 画像配信元のドメイン
 */
const imgixClient = new ImgixClient({
  domain:
    globalThis.IMGIX_DOMAIN || process.env.IMGIX_DOMAIN || 'nocodeframework.imgix.net', // TODO: fix me later it was undefiled
})

// const url = client.buildURL('/path/to/image.png', { w: 400, h: 300 })
/**
 * Minify時の 画像の幅 (default)
 */
const minWidth = 640

/**
 * 画像メタデータのレスポンスタイプ
 */
type ImageMetaDataResponse = {
  'Content-Length': string // "125755"
  'Content-Type': string // "application/pdf"
  Output?: {}
  PDF?: {
    Version: number // 1
    PageCount: number // 1
  }
  PixelHeight?: number // 595.276
  PixelWidth?: number // 841.89
  [otherAttr: string]: any
}

/**
 * # $core.$imgix
 *
 * ## 概要
 * - `$core.$imgix` は、画像変換サービス (imgix, CloudFront + Lambda@Edge など) を利用して、画像のURLを生成するためのサービスです。
 * - 画像のサイズ変更、フォーマット変換、画質調整など、様々な変換処理をURLのクエリパラメータで指定することができます。
 *
 * - CORE FW では、デフォルトで Imgix を利用する設定になっていますが、CloudFront + Lambda@Edge を利用した画像変換の仕組みも利用可能です。
 * - どちらの仕組みを利用する場合でも、 `$core.$imgix` のインターフェースは共通です。
 * - 内部的には、CloudFront SignedURL を利用して、S3 に保存されている画像ファイルへのアクセスを保護しています。
 *
 * ## 認証関連についての補足事項
 * - CloudFront + Lambda@Edge を利用する場合は、`IS_S3_CORE_UPLOADS_BUCKET_PUBLIC` 環境変数を `false` に設定する必要があります。
 * - CloudFront + Lambda@Edge を利用する場合、`cloudfrontSignedUrlForCoreFiles` プロパティに署名付きURLが設定されます。
 *
 * ## ユースケース
 * - 画像のサイズ変更: 画像の幅と高さを指定して、リサイズした画像のURLを生成
 * - フォーマット変換: PNG, JPEG, WebPなど、用途に合わせて画像のフォーマットを変換
 * - 画質調整: 画像の画質を調整して、ファイルサイズを削減した画像のURLを生成
 * - 画像メタデータの取得: 画像のメタデータ (サイズ、フォーマットなど) を取得
 *
 */
export class ImageTransformer {
  /**
   * ImgixClientのインスタンス
   */
  private imgixClient: ImgixClient
  /**
   * 画像配信元のドメイン
   */
  public readonly domain: string
  /**
   * ファイル保存先 が 公開かどうかを判定するフラグ
   * - `false` の場合は、CloudFront Signed URL を使用する
   */
  public readonly isBucketPublic: boolean
  /**
   * CloudFront署名付きURL
   */
  private cloudfrontSignedUrlForCoreFiles: string

  constructor() {
    this.imgixClient = imgixClient
    this.domain = globalThis.IMGIX_DOMAIN || process.env.IMGIX_DOMAIN
    // ファイル保存先 が 公開かどうかを判定
    this.isBucketPublic =
      // @ts-ignore
      process.env.IS_S3_CORE_UPLOADS_BUCKET_PUBLIC === true ||
      this.domain.includes('imgix.net')
    if (!this.isBucketPublic) {
      this.registerHookForGetCFSignedUrlForCoreFiles()
    }
  }

  /**
   * CloudFront署名付きURLを取得するためのフックを登録
   * - ログイン時に、署名付きURLをLocalStorageに保存する
   */
  registerHookForGetCFSignedUrlForCoreFiles() {
    $core.$appHook.registerHook(
      embAuthHooks.getUserData.AFTER,
      'getCloudfrontSignedUrlForCoreFiles',
      async (user) => {
        this.cloudfrontSignedUrlForCoreFiles = await $core.$lsCache.getWithFetchFunc(
          'cloudfrontSignedUrlForCoreFiles',
          async () => {
            try {
              const { raw } = await $core.$d.transport.get(
                '/core/getCloudfrontSignedUrlForCoreFiles',
              )
              return raw
            } catch (e) {
              console.error(`Error on cloudfrontSignedUrlForCoreFiles()`, e)
              return ''
            }
          },
        )
        return user
      },
    )
  }

  /**
   * 画像を圧縮 (サイズ変更, 画質調整) したURLを生成します
   * @param imgPath 画像のパス
   * @param options 画像変換サービスに渡すクエリパラメータ, imgix の場合 [https://docs.imgix.com/apis/url](https://docs.imgix.com/apis/url) を参照
   * @returns 変換後の画像のURL
   */
  public minify(imgPath: string, options: QueryParamsInput = {}): string {
    return this.buildUrl(imgPath, { w: minWidth, q: 0, ...options })
  }

  /**
   * 画像変換サービスを利用して、画像のURLを生成します。
   * @param imgPath 画像のパス
   * @param options 画像変換サービスに渡すクエリパラメータ
   * @returns 変換後の画像のURL
   * @example
   * ```ts
   * const imageUrl = $core.$imgix.buildUrl('/images/sample.jpg', { w: 400, h: 300 })
   * ```
   */
  public buildUrl(imgPath: string, options: QueryParamsInput = {}): string {
    if (!imgPath) {
      console.warn('[$imgix] buildUrl(): invalid imgPath', imgPath)
      return ''
    }
    // CloudFront Signed URL を使用する必要がある場合は、クエリパラメータを追加する
    if (this.shouldUseCloudfrontSignedUrl(imgPath, options)) {
      const signedUrlQueryParams = this._getQueryParamsFromCloudfrontSignedUrl()
      options = {
        ...options,
        ...signedUrlQueryParams,
      }
    }
    // ImgixClient を使用して、画像のURLを生成する
    return imgixClient.buildURL(imgPath, options)
  }

  /**
   * 画像のパスから、Cloudfront Signed URLを使うかどうかを判定する
   * - 関数によって制御することが可能:
   *   - `$core.$configVars.set('imageShouldUseCloudfrontSignedUrlResolveFunction', (imgPath, options) => { ... })`
   *     - この関数は、画像パスとオプションを受け取り、 `true` または `false` を返す必要がある
   *     - `true` を返すと、CloudFront Signed URL を使用する
   *     - `false` を返すと、CloudFront Signed URL を使用しない
   *
   * ```ts
   * // 例
   * $core.$configVars.set('imageShouldUseCloudfrontSignedUrlResolveFunction', (imgPath, options) => {
   *   // 画像パスが 'publicFiles/' で始まる場合は、Cloudfront Signed URL を使わない
   *   if (imgPath.startsWith('publicFiles/')) {
   *     return false
   *   }
   *   return true
   * })
   * ```
   *
   * @param imgPath 画像のパス
   * @param options 画像変換サービスに渡すクエリパラメータ
   * @private
   */
  private shouldUseCloudfrontSignedUrl(
    imgPath: string,
    options: QueryParamsInput,
  ): boolean {
    // `imageShouldUseCloudfrontSignedUrlResolveFunction` という名前の関数が `$core.$configVars` に登録されている場合は、その関数を実行して結果を返す
    const func = $core.$configVars.get(
      'imageShouldUseCloudfrontSignedUrlResolveFunction',
      null,
    )
    if (typeof func === 'function') {
      return func(imgPath, options)
    }
    return !this.isBucketPublic
  }

  /**
   * 画像のURLから、画像のパスを抽出します
   * @param url 画像のURL
   * @returns 画像のパス
   * @example
   * ```ts
   * const imagePath = $core.$imgix.extractImagePathFromUrl('https://example.com/images/sample.jpg?w=400&h=300')
   * console.log(imagePath) // '/images/sample.jpg'
   * ```
   */
  public extractImagePathFromUrl(url: string): string {
    return decodeURIComponent(new URL(url).pathname.replace(/^\//, ''))
  }

  /**
   * CloudFront署名付きURLからクエリパラメータを取得する
   * @private
   */
  private _getQueryParamsFromCloudfrontSignedUrl(): qs.ParsedUrlQuery {
    const url = new URL(this.cloudfrontSignedUrlForCoreFiles)
    const querystring = url.search.substring(1)
    return qs.parse(querystring)
  }

  /**
   * 画像のURLから、ドメイン部分を削除します。
   * @param imgFullUrl 画像のURL
   * @returns ドメイン部分を削除した画像のURL
   * @example
   * ```ts
   * const imageUrl = $core.$imgix.removeDomainPartFromImgPath('https://example.com/images/sample.jpg')
   * console.log(imageUrl) // '/images/sample.jpg'
   * ```
   */
  public removeDomainPartFromImgPath(imgFullUrl: string): string {
    return imgFullUrl.replace(`https://${this.domain}`, '')
  }

  /**
   * 画像のメタデータを取得します。
   * @param imgPath 画像のパス
   * @returns 画像のメタデータ, 取得に失敗した場合は `null`
   * @example
   * ```ts
   * const imageMetaData = await $core.$imgix.getMeta('/images/sample.jpg')
   * console.log(imageMetaData.PixelWidth)
   * console.log(imageMetaData.PixelHeight)
   * ```
   */
  public async getMeta(imgPath: string): Promise<ImageMetaDataResponse | null> {
    const url = this.buildUrl(imgPath, { fm: 'json' })
    try {
      const res = await $core.$axios.get(url, { withCredentials: false })
      return res.data
    } catch (e) {
      console.warn(e)
      return null
    }
  }
}

/**
 * ImageTransformer のインスタンス
 */
export const $imgix = new ImageTransformer()
