import { ChartOptions, Plugin } from 'chart.js'
import { codeInputCommonAttrs } from '../../../common/$models'
import { ColumnDef, ColumnDefByColName } from '../../../common/$models/ModelDef'
import { builderTypeModelColumnsTemplates } from '../../../common/$models/modelDefinitionCommonTemplates'
import { VirtualModel } from '../../../common/$virtualModels/VirtualModel'
import { VirtualFieldDefinition } from '../../VirtualFieldBuilder/VirtualFieldBuilder'
import { COREChartDataset } from '../renderer/ChartDataset'
import { ChartXDataCol } from '../renderer/ChartRenderService'

// const ch = new Chart({})
export const availableChartTypes = {
  bar: { label: 'バー' },
  stackedBar: { label: '積み上げバー', type: 'bar' },
  line: { label: '折れ線' },
  // scatter: { label: '散布図' },
  // bubble: { label: '分布' },
  pie: { label: '円' },
  doughnut: { label: 'ドーナツ' },
}

const dataFilterSampleDisplayComponent = {
  template: `<a class="small" href="#" @click="() => $refs.sampleModal.show()">
関数によるフィルタ表現のサンプルを表示
<b-modal title="関数によるフィルタ表現のサンプル" ref="sampleModal" size="xl" ok-only ok-variant="outline-secondary">
<p>dayjsライブラリ <code>$core.$dayjs</code> を利用すると様々な日付表現が可能です。 ドキュメント: <a href="https://day.js.org/docs/en/manipulate/add" target="_blank">https://day.js.org/docs/en/manipulate/add</a></p>
<p>フィルタの書き方については <a href="https://youtu.be/J6HZIReGkrE" target="_blank">https://youtu.be/J6HZIReGkrE</a> を参照してください。</p>
<h5>例: 過去30日から本日までを対象に</h5>
<code style="white-space: pre; background-color: #f5f5f5;" class="d-block p-2 mb-3">return {
  someDateColumnName: {
    _between: [$core.$dayjs().add(-30, 'days').format('YYYY-MM-DD'), $core.$dayjs().format('YYYY-MM-DD')]
  }
}</code>
<h5>例: 2ヶ月前の月初以降を対象に</h5>
<code style="white-space: pre; background-color: #f5f5f5;" class="d-block p-2 mb-3">return {
  someDateColumnName: {
    _gte: $core.$dayjs().add(-2, 'month').startOf('month').format('YYYY-MM-DD')
  }
}</code>
</b-modal>
</a>`,
}

export const chartTypeSelections = Object.keys(availableChartTypes).map((value) => ({
  value,
  label: availableChartTypes[value]?.label || value,
}))

// 値を変換するタイプ 集計, 集計(関数), 件数カウント, 件数カウント(関数), 平均値, 合計, 最大値, 最小値 にする
type ChartColValueConvertType = {
  sum: { label: '合計値' }
  count: { label: '件数' }
  avg: { label: '平均値' }
  max: { label: '最大値' }
  min: { label: '最小値' }
}

const _tmpLsCache = 'core-chart-builder'

type ChartGroupingType = {
  modelName: string
  colName: string
  virtualFieldDef?: boolean
  valueConvertType: keyof ChartColValueConvertType
}

export type ChartSplitConfig = {
  seriesName
  type
  axis_label
  borderColor
  borderWidth
  backgroundColor
  axis
  axisUnit
  min
  max
}

export type ChartDefinitionDataType = {
  // 名称設定
  name: string
  // 表示title
  options_plugins_title_text?: string
  dataSourceType: string
  dataSourceTargetName: string
  // コレはデータではないなと。
  // modelColumnTreeSelections: string
  // 算出フィールドの定義を保持
  virtualFields: VirtualFieldDefinition[]
  // フィルタ指定のカラムを定義
  dataFilter: any
  // ソート順
  dataSort: string
  // グルーピング設定(X軸)の型を定義
  dataGroupings: ChartGroupingType[]
  // y軸の設定 (値) を定義
  yAxis: []
  chartOptions: ChartOptions<'line'>
  chartPlugins: Plugin<'line'>[]
  dataCols: ChartXDataCol[]
  dataYCols: ChartXDataCol[]
  dataRows: ChartXDataCol[]
  chartSplitConfig: ChartSplitConfig[]
  chartYGroupConfig: {
    seriesName
    type
    borderColor
    backgroundColor
  }[]
  displayAsTable: boolean
  setFilterAsFunction: boolean
  dataFilterFunction: string
  useChartTransformFunc: boolean
  chartTransformFunc: string
  displayMode: string
  colSumFooterEnabled: boolean
  rowSumFooterEnabled: boolean
  comparisonEnable: boolean
  comparisonMode: string
  comparisonName: string
  comparisonBaseYear: string
  chartType: string
}

const pointStyles = [
  'circle',
  'cross',
  'crossRot',
  'dash',
  'line',
  'rect',
  'rectRounded',
  'rectRot',
  'star',
  'triangle',
]

const isBarType = (type: string): boolean => ['bar', 'stackedBar'].indexOf(type) >= 0
const isLineType = (type: string): boolean => ['line'].indexOf(type) >= 0

// カラーコードをバリデーションする関数
const validateColorCode = (colorCode) => {
  const cssColorKeywords = [
    'aliceblue',
    'antiquewhite',
    'aqua',
    'aquamarine',
    'azure',
    'beige',
    'bisque',
    'black',
    'blanchedalmond',
    'blue',
    'blueviolet',
    'brown',
    'burlywood',
    'cadetblue',
    'chartreuse',
    'chocolate',
    'coral',
    'cornflowerblue',
    'cornsilk',
    'crimson',
    'cyan',
    'darkblue',
    'darkcyan',
    'darkgoldenrod',
    'darkgray',
    'darkgrey',
    'darkgreen',
    'darkkhaki',
    'darkmagenta',
    'darkolivegreen',
    'darkorange',
    'darkorchid',
    'darkred',
    'darksalmon',
    'darkseagreen',
    'darkslateblue',
    'darkslategray',
    'darkslategrey',
    'darkturquoise',
    'darkviolet',
    'deeppink',
    'deepskyblue',
    'dimgray',
    'dimgrey',
    'dodgerblue',
    'firebrick',
    'floralwhite',
    'forestgreen',
    'fuchsia',
    'gainsboro',
    'ghostwhite',
    'gold',
    'goldenrod',
    'gray',
    'grey',
    'green',
    'greenyellow',
    'honeydew',
    'hotpink',
    'indianred',
    'indigo',
    'ivory',
    'khaki',
    'lavender',
    'lavenderblush',
    'lawngreen',
    'lemonchiffon',
    'lightblue',
    'lightcoral',
    'lightcyan',
    'lightgoldenrodyellow',
    'lightgray',
    'lightgrey',
    'lightgreen',
    'lightpink',
    'lightsalmon',
    'lightseagreen',
    'lightskyblue',
    'lightslategray',
    'lightslategrey',
    'lightsteelblue',
    'lightyellow',
    'lime',
    'limegreen',
    'linen',
    'magenta',
    'maroon',
    'mediumaquamarine',
    'mediumblue',
    'mediumorchid',
    'mediumpurple',
    'mediumseagreen',
    'mediumslateblue',
    'mediumspringgreen',
    'mediumturquoise',
    'mediumvioletred',
    'midnightblue',
    'mintcream',
    'mistyrose',
    'moccasin',
    'navajowhite',
    'navy',
    'oldlace',
    'olive',
    'olivedrab',
    'orange',
    'orangered',
    'orchid',
    'palegoldenrod',
    'palegreen',
    'paleturquoise',
    'palevioletred',
    'papayawhip',
    'peachpuff',
    'peru',
    'pink',
    'plum',
    'powderblue',
    'purple',
    'rebeccapurple',
    'red',
    'rosybrown',
    'royalblue',
    'saddlebrown',
    'salmon',
  ]
  if (cssColorKeywords.includes(colorCode.toLowerCase())) {
    return true
  }
  if (colorCode.indexOf('rgba(') === 0) {
    // rgba形式の場合は、カンマで分割して、4つの要素があるかをチェックする
    const rgbaArray = colorCode.slice(5, -1).split(',')
    if (rgbaArray.length === 4) {
      const r = parseInt(rgbaArray[0].trim())
      const g = parseInt(rgbaArray[1].trim())
      const b = parseInt(rgbaArray[2].trim())
      const a = parseFloat(rgbaArray[3].trim())

      // r, g, bの値が0~255の間、aの値が0~1の間にあるかチェックする
      if (
        r >= 0 &&
        r <= 255 &&
        g >= 0 &&
        g <= 255 &&
        b >= 0 &&
        b <= 255 &&
        a >= 0 &&
        a <= 1
      ) {
        return true
      }
    }
  } else {
    // 通常のカラーコードの場合は、先頭の#を除いた文字列が3桁または6桁の場合にtrueを返す
    const regex = /^#([0-9A-Fa-f]{3}){1,2}$/
    if (regex.test(colorCode)) {
      return true
    }
  }

  return false
}

export const borderDashes = {
  直線: { value: [8, 0] },
  点線: { value: [5, 5] },
  ダッシュ: { value: [8, 5] },
}

const comparisonSelections = [
  { value: 'all', label: 'すべて' },
  { value: 'before_year', label: '前年度比較' },
  { value: 'base_year', label: '基準年比較' },
]

const comparisonNameSelections = [
  { value: 'all', label: 'すべて' },
  { value: 'difference', label: '差分' },
  { value: 'compare', label: '比較' },
  { value: 'change', label: '増減' },
]

const displayModeSelections = [
  { value: 'chart+table', label: 'チャートを初期表示' },
  { value: 'table+chart', label: '表を初期表示' },
  { value: 'chart', label: 'チャートのみ' },
  { value: 'table', label: '表のみ' },
]

const dataSourceTypes = {
  model: 'モデル定義',
  virtualModel: 'Virtualモデル定義',
  dataExportSetting: 'データエクスポート設定',
}

export const ChartDefinitionVModelColumns: ColumnDefByColName = {
  name: {
    type: 'STRING',
    label: '設定名',
    validate: { notEmpty: true },
    unique: true,
    inputAttrs: { wrapperClass: 'col-12', class: '_model-input-no-border' },
  },
  options_plugins_title_text: {
    label: '表示用タイトル',
    type: 'STRING',
    virtualColumnOf: 'definition',
    inputAttrs: { wrapperClass: 'col-12' },
    editCallback({ row, newValue, oldValue, isNewRecord, callerVueInstance }) {
      row.options_plugins_title_display = !!newValue
      return row
    },
  },
  dataSourceType: {
    label: 'データソース種別',
    type: 'STRING',
    validate: { notEmpty: true },
    defaultValue: 'model',
    editCallback({ row, newValue, oldValue }) {
      if (newValue !== oldValue) {
        if (row.dataSourceTargetName) {
          row.dataSourceTargetName = ''
        }
      }
      return row
    },
    customLabel: (val, callerVueInstance, recordRoot) => {
      return dataSourceTypes[val] || ''
    },
    labelFormatter: (record) => {
      return dataSourceTypes[record.dataSourceType] || ''
    },
    virtualColumnOf: 'definition',
    selections() {
      return ['model'] // ['model', 'virtualModel'] // , 'dataExportSetting'
    },
    inputAttrs: { wrapperClass: 'col-12' },
    // visible: false,
    // formGroup: {
    //   tag: 'portal',
    //   attrs: {
    //     to: 'alternativePanel'
    //   }
    // },
    editable: (row) => !row.dataSourceTargetName,
  },
  dataSourceTargetName: {
    ...builderTypeModelColumnsTemplates.dataSourceTargetName,
    default: '',
    hideLabel: true,
    inputAttrs: { wrapperClass: 'col-12' },
    virtualColumnOf: 'definition',
    async selections(record: any) {
      if (record.dataSourceType === 'model') {
        return $core.$modelsLoader.modelNameSelectOptions.map((o) => o.value)
      }
      if (record.dataSourceType === 'virtualModel') {
        return Object.keys($core.$virtualModels)
      }
      if (record.dataSourceType === 'dataExportSetting') {
        return $core.$models.dataExportSettings.find({ fields: ['id', 'name'] })
      }
    },
    // formGroup: {
    //   tag: 'portal',
    //   attrs: {
    //     to: 'sampleportal'
    //   }
    // },
    // editable: row => !row.dataSourceTargetName,
    // afterComponent: {
    //   template: `<a style="font-size: 12px" class="d-block text-right" v-if="$parent.record.dataSourceTargetName" href="#" @click.prevent="reset">設定をリセット</a>`,
    //   methods: {
    //     async reset() {
    //       if(await $core.$toast.confirmDialog('フィールド設定がすべてリセットされます、よろしいですか？')) {
    //         // @ts-ignore
    //         const r = this.$parent.record
    //         const initRecord: any = {
    //           name: r.name || '',
    //           dataSourceType: r.dataSourceType || '',
    //           dataSourceTargetName: '',
    //         }
    //         if(r.id) {
    //           initRecord.id = r.id
    //         }
    //         const modelFormVue = $core.$utils.findNearestParentVueComponentByName(this, 'ModelForm')
    //         this.$set(modelFormVue, 'data', initRecord)
    //       }
    //     }
    //   }
    // }
  },
  modelColumnTreeSelections: {
    label: 'フィールド',
    type: 'STRING',
    visibleOnIndex: false,
    virtualColumnOf: 'definition',
    inputComponent: $frameworkUtils.defineAsyncComponent(
      () => import('./ModelColumnTreeSelectionForChart.vue'),
    ),
    inputAttrs: { wrapperClass: 'col-12' },
  },
  virtualFields: {
    label: '算出フィールド',
    type: 'ARRAY_OF_OBJECT',
    visibleOnIndex: false,
    virtualColumnOf: 'definition',
    inputComponent: $frameworkUtils.defineAsyncComponent(
      () => import('./ManageChartVirtualFields.vue'),
    ),
    inputAttrs: { wrapperClass: 'col-12' },
    enableIf: (row) => !!row.dataSourceTargetName,
  },
  displayMode: {
    label: '表示モード',
    virtualColumnOf: 'definition',
    hideLabel: true,
    type: 'STRING',
    selections: () => displayModeSelections,
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderBeforeSubmitButton',
        // order: 2,
      },
    },
    enableIf: (row) => !!row.dataSourceTargetName,
    defaultValue: 'chart',
  },
  colSumFooterEnabled: {
    type: 'BOOLEAN',
    label: '列集計を表示',
    virtualColumnOf: 'definition',
    enableIf: (row) => row.displayMode?.indexOf('table') >= 0,
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderAfterSubmitButton',
      },
    },
  },
  rowSumFooterEnabled: {
    type: 'BOOLEAN',
    label: '行集計を表示',
    virtualColumnOf: 'definition',
    enableIf: (row) => row.displayMode?.indexOf('table') >= 0,
    inputAttrs: {
      // wrapperStyle: 'font-weight: normal',
      // wrapperStyle: 'color:red; ',
    },
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderAfterSubmitButton',
      },
    },
  },
  comparisonEnable: {
    type: 'BOOLEAN',
    label: '比較を表示',
    virtualColumnOf: 'definition',
    enableIf: (row) => row.displayMode !== 'chart',
    inputAttrs: {
      // wrapperStyle: 'font-weight: normal',
      // wrapperStyle: 'color:red; ',
    },
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderComparisonShow',
      },
    },
  },
  comparisonMode: {
    label: '比較モード',
    virtualColumnOf: 'definition',
    hideLabel: true,
    type: 'STRING',
    selections: () => comparisonSelections,
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderComparisonType',
        // order: 2,
      },
    },
    enableIf: (row) => !!row.comparisonEnable && !!row.dataSourceTargetName,
    defaultValue: 'all',
  },
  comparisonName: {
    label: '比較名',
    virtualColumnOf: 'definition',
    hideLabel: true,
    type: 'STRING',
    selections: () => comparisonNameSelections,
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderComparisonName',
        // order: 2,
      },
    },
    enableIf: (row) =>
      row.comparisonMode !== 'all' &&
      !!row.comparisonEnable &&
      !!row.dataSourceTargetName,
    defaultValue: 'all',
  },
  comparisonBaseYear: {
    label: '基準年',
    virtualColumnOf: 'definition',
    hideLabel: true,
    type: 'STRING',
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartBuilderComparisonBaseYear',
        // order: 2,
      },
    },
    enableIf: (row) =>
      row.comparisonMode === 'base_year' &&
      !!row.comparisonEnable &&
      !!row.dataSourceTargetName,
    defaultValue: '',
  },
  chartType: {
    label: 'チャートタイプ',
    type: 'STRING',
    selections: () => chartTypeSelections,
    inputAttrs: { wrapperClass: 'col-12 mb-3' },
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartDisplayConfig',
        order: 1,
      },
    },
    defaultValue: 'line',
    enableIf: (row) => !!row.dataSourceTargetName && !row.displayAsTable,
    virtualColumnOf: 'definition',
  },
  chartSplitConfig: {
    label: 'チャート個別設定',
    visibleOnIndex: false,
    type: 'ARRAY_OF_OBJECT',
    enableIf: (row) => !!row.dataSourceTargetName && !row.displayAsTable,
    minValueLength: 0,
    columns: {
      seriesName: {
        label: '系列名',
        type: 'SELECT',
        dynamicSelections: true,
        strictSelections: true,
        selections(
          record: any,
          currentValue: any,
          initialValue: any,
          recordRoot: any,
          callerVueInstance: any,
        ): Promise<any[]> | any[] {
          const builderVMInstance = $core.$utils.findNearestParentVueComponentByName(
            callerVueInstance,
            'ChartBuilder',
          )
          // 選択されているもの（自分除く）以外
          const selectedSeriesNames = recordRoot.chartSplitConfig?.map(
            (item) => item.seriesName,
          )
          return (
            builderVMInstance.$refs?.chartRenderer?.chartService?.seriesLabels || []
          ).filter(
            (label) =>
              record.seriesName === label || selectedSeriesNames.indexOf(label) === -1,
          )
        },
        inputHelpText: 'グラフに表示する系列名を指定',
      },
      type: {
        label: 'チャートタイプ',
        type: 'SELECT',
        dynamicSelections: true,
        strictSelections: true,
        selections: (
          record: any,
          currentValue: any,
          initialValue: any,
          recordRoot: any,
          callerVueInstance: any,
        ) => {
          // bar,line,pie,doughnutから選択
          const base = chartTypeSelections.filter((item) => item.value !== 'stackedBar')
          // barとlineは混在可能, pieとdoughnutは自分以外と混在不可として絞り込み
          const selectedTypes = recordRoot.chartSplitConfig?.map((item) => item.type)
          if (
            selectedTypes.indexOf('bar') !== -1 ||
            selectedTypes.indexOf('line') !== -1
          ) {
            return base.filter(
              (item) => item.value !== 'pie' && item.value !== 'doughnut',
            )
          }
          if (selectedTypes.indexOf('pie') !== -1) {
            return [{ value: 'pie', label: '円' }]
          }
          if (selectedTypes.indexOf('doughnut') !== -1) {
            return [{ value: 'doughnut', label: 'ドーナツ' }]
          }
          return base
        },
      },
      axis_label: {
        label: '凡例名',
        type: 'STRING',
        inputHelpText: 'グラフ凡例に表示する名称を指定',
      },
      borderColor: {
        label: 'ボーダー色',
        type: 'STRING',
        inputHelpText:
          'CSSとして利用できるカラーを設定します。例: #ff0000, #ff000040, rgba(255,0,0,0.3), purple',
        validate: {
          customValidate: (
            value,
            col: any,
            modelName: string,
            childRow: any,
            recordRoot: any,
          ) => {
            if (value && !validateColorCode(value)) {
              return 'カラーを指定してください'
            }
          },
        },
      },
      borderWidth: {
        label: 'ボーダー幅',
        type: 'NUMBER',
        inputAttrs: { suffix: 'px' },
      },
      backgroundColor: {
        label: '背景色',
        type: 'STRING',
        inputHelpText:
          'CSSとして利用できるカラーを設定します。例: #ff0000, #ff000040, rgba(255,0,0,0.3), purple',
        validate: {
          customValidate: (
            value,
            col: any,
            modelName: string,
            childRow: any,
            recordRoot: any,
          ) => {
            if (value && !validateColorCode(value)) {
              return 'カラーを指定してください'
            }
          },
        },
      },
      axis: {
        label: '軸設定',
        type: 'SELECT',
        selections: () => [
          { value: 'y', label: '左' },
          { value: 'y2', label: '右' },
        ],
        validate: {
          notEmpty: true,
        },
        enableIf: (childRow, row) =>
          isBarType(childRow.type) || isLineType(childRow.type),
        defaultValue: 'y',
      },
      axisUnit: {
        label: '単位設定',
        type: 'STRING',
        enableIf: (childRow, row) =>
          isBarType(childRow.type) || isLineType(childRow.type),
        inputHelpText: '軸の単位を指定する場合は値を入力',
      },
      min: {
        label: '最小値',
        type: 'NUMBER',
        enableIf: (childRow, row) =>
          isBarType(childRow.type) || isLineType(childRow.type),
        inputHelpText: '軸の最小値を指定する場合は値を入力',
      },
      max: {
        label: '最大値',
        type: 'NUMBER',
        enableIf: (childRow, row) =>
          isBarType(childRow.type) || isLineType(childRow.type),
        inputHelpText: '軸の最大値を指定を指定する場合は値を入力',
      },
    },
    formGroup: {
      tag: 'portal',
      attrs: {
        to: 'chartDisplayDetailModal',
        // order: 2,
      },
    },
    inputAttrs: {
      wrapperClass: 'col-12',
    },
    // hideLabel: true,
    virtualColumnOf: 'definition',
  },
  dataCols: {
    virtualColumnOf: 'definition',
    type: 'ARRAY_OF_OBJECT',
    defaultValue: [],
    visibleOnIndex: false,
    hideLabel: true,
    inputComponent: $frameworkUtils.defineAsyncComponent(
      () => import('./ChartGroupingDataCol.vue'),
    ),
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="dataColumnPanelX"]',
        defer: true,
        // order: 2,
      },
    },
  },
  dataYCols: {
    virtualColumnOf: 'definition',
    type: 'ARRAY_OF_OBJECT',
    defaultValue: [],
    visibleOnIndex: false,
    hideLabel: true,
    inputComponent: $frameworkUtils.defineAsyncComponent(
      () => import('./ChartGroupingDataCol.vue'),
    ),
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="dataColumnPanelYCols"]',
        defer: true,
        // order: 2,
      },
    },
  },
  dataRows: {
    virtualColumnOf: 'definition',
    type: 'ARRAY_OF_OBJECT',
    defaultValue: [],
    visibleOnIndex: false,
    hideLabel: true,
    inputComponent: $frameworkUtils.defineAsyncComponent(
      () => import('./ChartGroupingDataCol.vue'),
    ),
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="dataColumnPanelY"]',
        defer: true,
        // order: 2,
      },
    },
    // TODO: クソコードになってしまった...
    editCallback: ({ row, newValue, oldValue, isNewRecord, callerVueInstance }) => {
      if (row.dataRows.length >= 2) {
        if (
          row.dataYCols &&
          !row.dataYCols?.find((dr) => dr.name === '集計値' && !dr.modelName) &&
          row.dataCols &&
          !row.dataCols.find((dr) => dr.name === '集計値' && !dr.modelName)
        ) {
          row.dataYCols = row.dataYCols.concat([{ name: '集計値' }])
        }
      } else {
        // 削除
        if (
          row.dataYCols &&
          row.dataYCols.find((dr) => dr.name === '集計値' && !dr.modelName)
        ) {
          row.dataYCols = row.dataYCols.filter(
            (dr) => !(dr.name === '集計値' && !dr.modelName),
          )
        } else if (row.dataCols.find((dr) => dr.name === '集計値' && !dr.modelName)) {
          row.dataCols = row.dataCols.filter(
            (dr) => !(dr.name === '集計値' && !dr.modelName),
          )
        }
      }
      return row
    },
  },
  dataFilter: {
    virtualColumnOf: 'definition',
    type: 'ARRAY_OF_OBJECT',
    defaultValue: [],
    visible: false,
  },
  dataSort: {
    virtualColumnOf: 'definition',
    type: 'STRING',
    defaultValue: [],
    visible: false,
  },
  setFilterAsFunction: {
    label: '関数でフィルタを指定',
    virtualColumnOf: 'definition',
    type: 'BOOLEAN',
    inputAttrs: {
      style: 'margin: .5em 0;',
    },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="chartDisplayDataFilterDetail"]',
        defer: true,
      },
    },
  },
  dataFilterFunction: {
    label: 'データフィルタ変換関数',
    virtualColumnOf: 'definition',
    type: 'TEXT',
    hideLabel: true,
    enableIf: (row) => row.setFilterAsFunction,
    inputHelpText:
      'データフィルタを関数で定義できます。本日時点からの相対日付レンジなどを設定する場合に利用します。',
    afterComponent: dataFilterSampleDisplayComponent,
    inputAttrs: {
      wrapperClass: 'col-12',
      ...codeInputCommonAttrs,
      placeholder: `return { fieldName: { _gte: $core.$dayjs().add(-20, 'days').format('YYYY-MM-DD') } }`,
    },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="chartDisplayDataFilterDetail"]',
        defer: true,
      },
    },
  },
  useChartTransformFunc: {
    label: 'ChartJs設定関数を利用する',
    afterComponent: `<p class="small text-muted">ChartJsの多彩な設定を関数で直接指定可能です。 <a href="https://www.chartjs.org/docs/latest/samples/information.html" target="_blank">ChartJsドキュメント</a></p>`,
    virtualColumnOf: 'definition',
    type: 'BOOLEAN',
    inputAttrs: {
      style: 'margin: .5em 0;',
    },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        // to: '[data-teleport="chart-customize"]',
        to: '#chart-customize',
        order: 1,
        defer: true,
      },
    },
  },
  chartTransformFunc: {
    label: 'ChartJs設定関数',
    virtualColumnOf: 'definition',
    type: 'TEXT',
    hideLabel: true,
    enableIf: (row) => row.useChartTransformFunc,
    // inputHelpText: 'データフィルタを関数で定義できます。本日時点からの相対日付レンジなどを設定する場合に利用します。',
    afterComponent: `<div class="small text-muted">ChartJs初期化直前にオプションを変更するのに利用します。引数 <code>chartOptions</code> (<a href="https://www.chartjs.org/docs/latest/api/interfaces/ChartConfiguration.html" target="_blank">ChartJs初期化時の引数option</a>) を書き換えてください。 また、引数 <code>$chartRender</code> で ChartRenderService のインスタンスを参照可能です。<br/>サンプルコード: <code class="d-block bg-light p-1">chartOptions = $core.$utils.deepmerge(chartOptions, { options: { plugins: { title: { text: 'Sample Title', } }, } })</code></div>`,
    inputAttrs: {
      wrapperClass: 'col-12',
      ...codeInputCommonAttrs,
    },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="chartCustomize"]',
        order: 2,
        defer: true,
      },
    },
  },
  options_interaction_mode: {
    label: 'ホバー時の表示モード',
    virtualColumnOf: 'definition',
    type: 'STRING',
    selections: () => ['index', 'dataset', 'point', 'nearest'],
    inputAttrs: { wrapperClass: 'col-12' },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="chartCustomize"]',
        order: 3,
      },
    },
  },
  options_interaction_axis: {
    label: 'ホバー時の表示軸',
    virtualColumnOf: 'definition',
    type: 'STRING',
    selections: () => ['x', 'y', 'xy', 'r'],
    inputAttrs: { wrapperClass: 'col-12' },
    formGroup: {
      tag: 'Teleport',
      attrs: {
        to: '[data-teleport="chartCustomize"]',
        order: 4,
        defer: true,
      },
    },
  },
}

const type = 'chart'
export const VModelChartDefinition: VirtualModel = {
  tableName: 'chartDefinitions',
  baseModel: 'customComponentDefinitions',
  dataFilters: { type },
  name: 'chartDefinitions',
  tableLabel: 'チャート定義',
  defaultValues: () => ({
    type,
    dataSourceType: 'model',
    options_interaction_axis: 'x',
    options_interaction_mode: 'index',
    displayMode: 'chart+table',
  }),
  columns: ChartDefinitionVModelColumns,
  modelType: 'admin',
  indexListItemOnClick: (itemId) => {
    $core.$router.push(`/chart-builder?id=${itemId}`)
  },
  creatable: false,
}

/**
 * チャートビルダーで設定するオプションの型を定義する
 */
const virtualFieldUpdateTargetProps = [
  'colName',
  'convertType',
  'convertTypeLabel',
  'converter',
  'id',
  'isVirtualField',
  'modelName',
  'name',
]
const dataFieldPropNames = ['dataYCols', 'dataCols', 'dataRows']

type dataFieldType = {
  dataYCols: any[]
  dataCols: any[]
  dataRows: any[]
  dataSourceTargetName?: string
  type?: string
  id?: string
  chartSplitConfig?: any[]
}

// チャートビルダー
export class ChartBuilderManager {
  public cols: ColumnDefByColName
  public data: dataFieldType = {
    dataCols: [],
    dataYCols: [],
    dataRows: [],
    chartSplitConfig: [],
  }
  public errors: any
  public dragging: boolean
  public initPromise: Promise<any>
  public draggingColDef: ColumnDef = null
  initialData: any
  builderVm

  constructor({ initialData = null, builderVm = null } = {}) {
    this.initialData = initialData
    this.cols = ChartDefinitionVModelColumns
    if (!this.data?.dataCols) {
      this.data.dataCols = []
    }
    if (!this.data?.dataYCols) {
      this.data.dataYCols = []
    }
    this.builderVm = builderVm
    if (!this.data?.dataRows) {
      this.data.dataRows = []
    }
    if (!this.data?.chartSplitConfig) {
      this.data.chartSplitConfig = []
    }
    this.errors = {}
    this.dragging = false
  }

  async initOnce() {
    this.initPromise = new Promise(async (resolve, reject) => {
      this.data =
        this.initialData || (await $core.$virtualModels.chartDefinitions.createNew())
      setTimeout(() => {
        this.data = this.builderVm.$refs.modelForm.data
      }, 500)
    })
  }

  get model() {
    return $core.$models[this.data?.dataSourceTargetName]
  }

  get errorMessages() {
    return Object.values(this.errors).filter((e) => !!e)
  }

  onDragStart(event, colDef: ColumnDef) {
    this.draggingColDef = colDef
    $core.$uiState.motion = 'chart-item-dragging'
  }

  /**
   * uiState を変更
   * - dataCols:
   * - dataRows:
   * @param event
   */
  onDragEnd(event) {
    $core.$uiState.motion = ''
    this.__saveLocal()
  }

  __saveLocal() {
    $core.$lsCache.set(_tmpLsCache, this.data)
  }

  async save() {
    if (!this.data.type) {
      // TODO: 美しくする
      this.data.type = 'chart'
    }
    if (this.builderVm.$refs?.modelForm?.errorCount === 0) {
      // 実際に保存メソッドを呼び出す
      await $core.$storeMethods.upsert({
        modelName: 'customComponentDefinitions',
        virtualModelName: 'chartDefinitions',
        data: this.data,
      })
    } else {
      $core.$toast.errorToast('未入力の必須項目があります。')
    }
  }

  async saveSuccessCallback(saved) {
    if (!this.data.id) {
      // Redirect to list page to avoid duplicate saving on creation
      // TODO: もうちょい鮮やかな方法ないもんか...?
      $core.$router.push('/m/customComponentDefinitions?virtualModel=chartDefinitions')
    }
  }

  // VirtualField をアップデートした際に、更新をかける
  updateVirtualField(virtualFieldDef) {
    let hasUpdated = false
    dataFieldPropNames.map((key) => {
      this.data[key]?.map((col, index) => {
        if (col.id === virtualFieldDef.id) {
          // 下記だと... read only prop error になる ので、 updateProps で指定している
          // this.data[key][index] = Object.assign({}, col, virtualFieldDef)
          virtualFieldUpdateTargetProps.map((prop) => {
            this.data[key][index][prop] = virtualFieldDef[prop]
          })
          // ココで, reRender
          hasUpdated = true
        }
      })
    })
    if (hasUpdated) {
      this.renderChart()
    }
  }

  // VirtualField を削除した際に、更新をかける
  deleteVirtualField(virtualFieldId) {
    let hasUpdated = false
    dataFieldPropNames.map((key) => {
      this.data[key]?.map((col, index) => {
        if (col.id === virtualFieldId) {
          this.data[key][index].id = null
          hasUpdated = true
          // 1つとは限らないので... break しない
        }
      })
    })
    if (hasUpdated) {
      this.renderChart()
    }
  }

  refreshBuilderConfig() {
    setTimeout(() => {
      this.builderVm.refreshBuilderConfig()
      setTimeout(() => {
        this.renderChart()
      }, 10)
    }, 10)
  }

  renderChart() {
    setTimeout(() => {
      this.builderVm.$refs.chartRenderer.renderChart()
    }, 10)
  }
}
