import { stringToFunction, tryParseAsObject } from '../utils'
import { ColumnDef, ColumnTypes } from './ModelDef'
import { ModelFactory } from './ModelFactory'
import { initialValueByColumnTypes } from './initialValueByColumnTypes'

/**
 * カラム定義を初期化する
 * - 基本的には Browser load 時の ModelFactory による model 初期化処理内で呼び出される
 */
export const initColumnDef = (
  colName: string,
  columnDef: ColumnDef,
  model: ModelFactory,
): ColumnDef => {
  if (columnDef.type === ColumnTypes.Boolean) {
    // boolean の場合は、falsy or truthy な値に変換する
    columnDef.defaultValue = !!columnDef.defaultValue
  } else if (
    // defaultValue が falsy である場合には、初期値を設定する
    [undefined, null, ''].indexOf(columnDef.defaultValue) >= 0
  ) {
    columnDef.defaultValue = initialValueByColumnTypes(columnDef.type)
  }
  columnDef.name = colName
  if (columnDef.virtualColumnOf && !model.hasVirtualColumn) {
    model.hasVirtualColumn = true
  }
  model.colLabels[colName] = columnDef.label || colName
  // Primary Keys
  if (columnDef.primaryKey === true) {
    model.primaryKeyCol = columnDef
  }
  // editCallback
  if (typeof columnDef.editCallback === 'function') {
    model.editCallbacksByColname[colName] = columnDef.editCallback
  }
  // デフォルトのfilterableの挙動
  // notNull なら... 自動で notEmpty: true を追加する
  if (columnDef.notNull === true && !columnDef.validate) {
    columnDef.validate = {
      notEmpty: true,
    }
  }
  // enableIfByFilterQuery, 文字列ならParseしておく
  if (
    columnDef.enableIfByFilterQuery &&
    typeof columnDef.enableIfByFilterQuery === 'string'
  ) {
    try {
      columnDef.enableIfByFilterQuery = tryParseAsObject(columnDef.enableIfByFilterQuery)
    } catch (e) {
      console.warn(`[${model.tableName}.${colName}]enableIfByFilterQuery が不正です。`, e)
      columnDef.enableIfByFilterQuery = undefined
    }
  }

  // labelFormatter が 文字列なら 関数に変換する
  if (columnDef.labelFormatter && typeof columnDef.labelFormatter === 'string') {
    columnDef.labelFormatter = stringToFunction(
      columnDef.labelFormatter,
      'function (row)',
      colName,
    )
  }
  if (columnDef.listLabelFormatter && typeof columnDef.listLabelFormatter === 'string') {
    columnDef.listLabelFormatter = stringToFunction(
      columnDef.listLabelFormatter,
      'function (row)',
      colName,
    )
  }
  if (columnDef.customLabel && typeof columnDef.customLabel === 'string') {
    columnDef.customLabel = stringToFunction(
      columnDef.customLabel,
      'function (value, callerVueInstance, recordRoot)',
      colName,
    )
  }
  if (
    columnDef.relationshipManyToOne?.labelFormatter &&
    typeof columnDef.relationshipManyToOne?.labelFormatter === 'string'
  ) {
    columnDef.relationshipManyToOne.labelFormatter = stringToFunction(
      columnDef.relationshipManyToOne.labelFormatter,
      'function (row)',
      colName,
    )
  }
  /**
   * selections が 配列で定義されていたら、関数化する
   */
  if (typeof columnDef.selections === 'object' && Array.isArray(columnDef.selections)) {
    const selections = columnDef.selections
    // @ts-ignore
    columnDef.selections = () => selections
  }
  return columnDef
}
