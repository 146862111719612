import axios, { AxiosRequestConfig } from 'axios'

export const filenameSeparator = '--:--:--:--'

export const getOriginalFileNameFromFilePath = (fullFilePath: string) => {
  // `/`区切りでsplitして最後の要素を返す
  return fullFilePath.split('/').pop()
}

/**
 * ファイルパスを生成する関数
 */
export interface FilePathGenerateFunction {
  ({
    fileName,
    fileExtension,
    pathPrefix,
  }: {
    fileName: string
    fileExtension: string
    pathPrefix: string | null
  }): string
}

const getFileExtensionFromFileObject = (file: File) => {
  if (!file?.name) {
    throw new Error('Invalid file object')
  }
  return file.name.split('.').pop()
}

// 半角文字を全角文字に変換する関数（指定された文字は除外）
const convertToFullWidthExcept = (str: string): string => {
  // 除外する文字のリストを正規表現で定義
  const excludeChars = /[-_.*'()]/
  const symbolPattern = /[!-/:-@[-`{-~]/g
  return str.replace(symbolPattern, (s) => {
    // 除外する文字は変換せずそのまま返す
    if (excludeChars.test(s)) {
      return s
    }
    // 半角ASCIIコード(0x21-0x7E)を全角に変換
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
  })
}

// S3等のオブジェクトキーの最大長
const MAX_FILEPATH_KEY_LENGTH = 1024

class FileManager {
  splitKey = '--:--:--:--'

  async upload(
    file: File,
    filePath: string = null,
    uploadAxiosOptions: Partial<AxiosRequestConfig> = null,
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ): Promise<any> {
    // filePathが指定されていないか、またはfilePathPrefixとかfilePathGenerateFunctionが設定されている場合にはpathをGenerateする
    if (!filePath || filePathPrefix || filePathGenerateFunction) {
      filePath = this.generateDefaultFilePath(
        file,
        filePathPrefix,
        filePathGenerateFunction,
      )
    }
    const fileType =
      file.type || file.name.substring(file.name.lastIndexOf('.')).replace('.', '')
    const { raw } = await this._getSignedUrlForPutObject(filePath, fileType)
    const uploadResult = await this._uploadFileWithSignedUrl(
      file,
      raw,
      fileType,
      uploadAxiosOptions,
    )
    // filePathとuploadResultawaitを返す
    return {
      filePath,
      uploadResult,
    }
  }

  getFileExtensionFromFileObject(file: File) {
    return getFileExtensionFromFileObject(file)
  }

  generateDefaultFilePath(
    file: File,
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ) {
    // 1. ファイル名および拡張子を取得
    let fileName = file.name.normalize('NFC').slice(0, file.name.lastIndexOf('.'))

    // ファイル名中の半角文字を全角文字に変換（指定された文字は除外）
    fileName = convertToFullWidthExcept(fileName)

    const fileExtension = this.getFileExtensionFromFileObject(file)

    // 2. pathPrefix を決定
    filePathPrefix = filePathPrefix || 'u'

    // タイムスタンプを生成
    const timeStamp = $core.$utils.generateStrongTimeStampString()

    // 3. ファイルパスを生成
    let filePath = `${filePathPrefix}/${timeStamp}/${fileName}.${fileExtension}`

    // 4. S3等のオブジェクトキーの最大長を超えないようにファイル名をトリム
    const maxFileNameLength =
      MAX_FILEPATH_KEY_LENGTH - `${filePathPrefix}/${timeStamp}/.${fileExtension}`.length
    if (filePath.length > MAX_FILEPATH_KEY_LENGTH) {
      // ファイル名を適切な長さに切り詰める
      const allowedFileNameLength = maxFileNameLength - 1 // '.' の分を引く
      fileName = fileName.substring(0, allowedFileNameLength)
      filePath = `${filePathPrefix}/${timeStamp}/${fileName}.${fileExtension}`
    }

    // 5. カスタムのファイルパス生成関数があればそれを使用
    if (filePathGenerateFunction) {
      return filePathGenerateFunction({
        fileName,
        fileExtension,
        pathPrefix: filePathPrefix,
      })
    }

    // 6. 最終的なファイルパスを返す
    return filePath
  }

  getFileNameAvailableAsPathString(file: File) {
    return file.name
      .slice(0, file.name.lastIndexOf('.'))
      .replace(/&/g, '＆')
      .replace(/\//g, '／')
  }

  async _getSignedUrlForPutObject(filePath: string, fileType: string): Promise<any> {
    return await $core.$directusSdk.transport.get(
      `/core/getSignedUrlForS3PutObject?filePath=${filePath}&ContentType=${fileType}`,
    )
  }

  async _uploadFileWithSignedUrl(
    file: File,
    signedUrl: string,
    fileType: string,
    uploadAxiosOptions: Partial<AxiosRequestConfig> = null,
  ): Promise<any> {
    return await axios.put(signedUrl, file, {
      ...(uploadAxiosOptions || {}),
      headers: { 'Content-Type': fileType },
    })
  }

  async bulkUploadFiles(
    files: File[],
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ): Promise<{
    failedFileNames: string[]
    uploaded: { fileName: string; filePath: string }[]
  }> {
    const uploadingPromises = []
    const failedFileNames = []
    const uploaded = []
    for (const key in files) {
      const file = files[key]
      const fileName = file.name
      const filePath = this.generateDefaultFilePath(
        file,
        filePathPrefix,
        filePathGenerateFunction,
      )
      // 失敗してもPromise all でコケないように...
      uploadingPromises.push(
        this.upload(file, filePath)
          .then(() => {
            uploaded.push({ fileName, filePath: filePath })
          })
          .catch((error) => {
            console.error(error)
            failedFileNames.push(fileName)
            return true
          }),
      )
    }
    await Promise.all(uploadingPromises)
    return {
      failedFileNames,
      uploaded,
    }
  }
}

export const $fileManager = new FileManager()
