<template>
  <div class="boolean-radio-input d-flex flex-wrap gap-2">
    <label
      class="d-flex align-items-center gap-1 pr-1 cursor-pointer"
      v-for="(item, index) in selectOptions"
      :key="index"
    >
      <input
        class="form-check-input m-0"
        type="radio"
        :id="col.name + '_true'"
        :name="col.name + '' + _.uid"
        :value="modelValue"
        :checked="modelValue === item.value"
        @change="() => change(item.value)"
      />
      {{ item.label || item.text }}
    </label>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { SelectOptionItem } from '../../../common/$models/ModelDef'

/**
 *
 */
export default {
  name: 'RadioSelectInput',
  props: {
    record: {},
    modelValue: {
      required: true,
    },
    col: {
      required: true,
    },
    colInputSelection: {
      type: Array as PropType<(string | number)[] | SelectOptionItem[]>,
      required: true,
    },
  },
  computed: {
    options() {
      return this.colInputSelection
    },
    selectOptions(): SelectOptionItem[] {
      if (
        Array.isArray(this.options) &&
        ['string', 'number'].includes(typeof this.options[0])
      ) {
        return this.options.map((value) => ({
          value,
          text: this.col?.customLabel ? this.col.customLabel(value) : value,
        }))
      }
      return this.options
    },
  },
  methods: {
    change(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
