import { frontSideAppHooks } from '../../common/FrontSideAppHooks/frontSideAppHooksModel'
import { ipAccessSettings } from '../../common/IPAccessSettings/ModelIPAccessSettings'
import { notifications } from '../../common/NotificationService/notifications'
import { selectOptionsMaster } from '../../common/SelectOptionsMaster/SelectOptionsMasterModel'
import { textTemplates } from '../../common/TextTemplateService/textTemplates'
import { modelDefinitions } from '../../common/modelDefinitions/modelDefinitions'
import { virtualModelDefinitions } from '../../common/virtualModelDefinitions/virtualModelDefinitions'
import { appDefinitions } from '../../plugins/AppDefinitions/appDefinitions'
import { appDocumentPages } from '../../plugins/AppDocuments/models/appDocumentPages'
import { appTemplateInstallHistory } from '../../plugins/AppTemplate/model/appTemplateInstallHistory'
import { core_composable_component_definitions } from '../../plugins/ComposableComponentBuilder/models/core_composable_component_definitions'
import { directus_permissions } from '../../plugins/Permissions/ModelDirectusPermissions'
import { serverSideAppHooks } from '../../plugins/ServersideAppHooks/models/serverSideAppHooksModel'
import { core_user_group_definitions } from '../../plugins/UserGroupDefinitions/models/core_user_group_definitions'
import { coreUserNotificationModels } from '../../plugins/UserNotifications/models/coreUserNotificationModels'
import * as UserOrgGroupsManagerModels from '../../plugins/UserOrgGroupsManager/models'
import { anotherDataSourceModels } from '../../server/AnotherDataSourcesHandler/defineFromUI/anotherDataSourceModels'
import { userPasswordResetSecrets } from '../../server/DirectusUsersPasswordReset/userPasswordResetSecrets'
import { core_nodered_data } from '../../server/nodeRedIntegrator/ModelCoreNodeRedData'
import { customComponentDefinitions } from '../CustomComponentDefinitions/ModelCustomComponentDefinitions'
import { dataExportSettings } from '../DataExportSettings/ModelDataExportSettings'
import { dataImportSettings } from '../DataImportSettings/ModelDataImportSettings'
import { directus_activity } from '../DirectusActivity/model_directus_activity'
import { files } from '../FileManager/ModelFiles'
import { user_roles } from '../UserRoles/user_roles'
import { directus_roles } from '../Users/directus_roles'
import { directus_users } from '../Users/directus_users'
// import {
//   core_front_model_permission_config
// } from '../../plugins/FrontModelPermissionConfig/model/core_front_model_permission_config'

export const coreDefaultModels = {
  dataImportSettings,
  dataExportSettings,
  selectOptionsMaster,
  modelDefinitions,
  virtualModelDefinitions,
  serverSideAppHooks,
  frontSideAppHooks,
  appDefinitions,
  textTemplates,
  user_roles,
  directus_users,
  directus_roles,
  files,
  notifications,
  userPasswordResetSecrets,
  customComponentDefinitions,
  directus_activity,
  directus_permissions,
  appDocumentPages,
  ...UserOrgGroupsManagerModels,
  ...anotherDataSourceModels,
  // ...coreMessageSenderModels,
  ...coreUserNotificationModels,
  ipAccessSettings,
  core_composable_component_definitions,
  appTemplateInstallHistory,
  core_user_group_definitions,
  // core_nodered_data,
  // core_front_model_permission_config,
}

/**
 * Master seed として, 一般的に言って同期するべきModel名のリスト
 * ※ Model追加時には, 下記判断し追加
 */
export const masterSeedableCoreDefaultModelNames = {
  dataImportSettings: {},
  dataExportSettings: {},
  selectOptionsMaster: {},
  modelDefinitions: {},
  virtualModelDefinitions: {},
  serverSideAppHooks: {},
  frontSideAppHooks: {},
  appDefinitions: {},
  textTemplates: {},
  user_roles: {},
  core_user_group_definitions: {},
  // core_front_model_permission_config: {},
  // directus_roles: {},
  // files: {},
  // notifications: {},
  // ganttDefinitions: {},
  // userPasswordResetSecrets: {},
  customComponentDefinitions: {},
  // directus_activity: {},
  // directus_permissions: {},
  // positions: {},
  organizations: {},
  // userGroups: {},
  // junctionUsersAndGroups: {},
  // junctionUsersAndOrgsAndPositions: {},
  appDocumentPages: {},
  core_another_data_source_dialects: {},
  core_another_data_source_connection_definitions: {},
  core_composable_component_definitions: {},
  // core_nodered_data: {},
}
