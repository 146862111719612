<template>
  <div class="cc-search-filter-input-with-column">
    <slot
      :updateFilterValue="updateFilterValue"
      :applyFilter="applyFilter"
      :filterValue="filterValue"
    >
      <!-- デフォルトのコンテンツ -->
      <input
        type="text"
        v-if="inputType === 'text'"
        v-model="filterValue"
        @change="applyFilter"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { ColumnDefService } from '../../../../../common/$models'
import { getRelationColumnLabelAndKey } from '../../../../../common/modelDefinitions/getRelationColumnLabelAndKey'
import { ColumnTypes } from '../../../../../types'
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { ComposableDataListService } from '../../ComposableDataListService'
import { getComposableDataListFromSettingColumnInstance } from '../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'

type SearchFilterInputInputType =
  | 'text'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'number'
  | 'date'
const name = 'CCSearchFilterInputWithColumn'

/**
 * フィルターオペレータの定義
 * - カラムタイプに応じて利用可能なフィルターオペレータと、そのラベルを定義
 */
const filterOperators = {
  _contains: {
    label: '含む',
    applicableColumnTypes: ['STRING'],
  },
  _ncontains: {
    label: '含まない',
    applicableColumnTypes: ['STRING'],
  },
  _eq: {
    label: '完全一致',
    applicableColumnTypes: ['STRING', 'NUMBER', 'BOOLEAN', 'DATE'],
  },
  _neq: {
    label: '不一致',
    applicableColumnTypes: ['STRING', 'NUMBER', 'BOOLEAN', 'DATE'],
  },
  _lt: {
    label: 'より小さい',
    applicableColumnTypes: ['NUMBER', 'DATE'],
  },
  _lte: {
    label: '以下',
    applicableColumnTypes: ['NUMBER', 'DATE'],
  },
  _gt: {
    label: 'より大きい',
    applicableColumnTypes: ['NUMBER', 'DATE'],
  },
  _gte: {
    label: '以上',
    applicableColumnTypes: ['NUMBER', 'DATE'],
  },
  _between: {
    label: '範囲内',
    applicableColumnTypes: ['NUMBER', 'DATE'],
  },
  _in: {
    label: 'いずれかに一致',
    applicableColumnTypes: ['STRING', 'NUMBER'],
  },
  _nin: {
    label: 'いずれにも一致しない',
    applicableColumnTypes: ['STRING', 'NUMBER'],
  },
  _is_null: {
    label: '未入力',
    applicableColumnTypes: ['STRING', 'NUMBER', 'BOOLEAN', 'DATE'],
  },
  _is_not_null: {
    label: '入力済み',
    applicableColumnTypes: ['STRING', 'NUMBER', 'BOOLEAN', 'DATE'],
  },
}

/**
 * カラムタイプごとのデフォルトフィルターオペレータ
 */
const defaultFilterOperatorByColumnType = {
  STRING: '_contains',
  NUMBER: '_eq',
  BOOLEAN: '_eq',
  DATE: '_eq',
}

const filterOperatorKeys = Object.keys(filterOperators)

/**
 * UI Builder 上での 設定を実施できる項目の定義
 * - Vue props と 対応している
 */
registerComposableComponentSettings(name, {
  label: name,
  hasDefaultSlot: true,
  configColumns: {
    filterOperator: {
      label: '照合条件',
      type: 'STRING',
      selections: () => filterOperatorKeys,
      customLabel: (val) => {
        const label = filterOperators[val as keyof typeof filterOperators]?.label
        if (label) {
          return `${label} ( ${val} )`
        }
        return val
      },
      // ※ TODO: わかりやすい説明
      inputHelpText: '',
      afterComponents: [
        // {
        // TODO: カラムタイプに適さないフィルターオペレータが選択されている場合に 警告を表示する
        //   template: ``,
        //   props: ['record']
        // }
      ],
    },
    targetColName: {
      label: '対象カラム',
      type: 'STRING',
      selections: (
        record: any,
        currentValue: any,
        initialValue: any,
        recordRoot: any,
        callerVueInstance: any,
      ) => {
        const composableDataList = getComposableDataListFromSettingColumnInstance(
          callerVueInstance,
          'ComposableDataList',
        )
        if (!composableDataList || !composableDataList.configuredProps?.modelName) {
          return []
        }
        const model =
          callerVueInstance.$core.$models[composableDataList?.configuredProps?.modelName]
        if (!model) {
          return []
        }
        const columns = Object.values(model.columns) as ColumnDefService[]
        const selections: { value: string; label: string }[] = []
        for (const column of columns) {
          // @ts-ignore
          if (
            [
              ColumnTypes.RelationshipManyToOne,
              ColumnTypes.RelationshipOneToMany,
            ].indexOf(column.type as any) >= 0
          ) {
            const res = getRelationColumnLabelAndKey(
              column,
              column.name as string,
              column.label as string,
            )
            res.forEach(({ label, key }) => {
              if (!!key && !!label) {
                selections.push({
                  value: key,
                  label: label,
                })
              }
            })
          } else {
            selections.push({
              value: column.name as string,
              label: column.label as string,
            })
          }
        }
        return selections
      },
    },
  },
  defaultProps: {},
})

/**
 * # CCSearchFilterInputWithColumn
 *
 * - カラム名を指定してフィルターを適用するためのコンポーネント
 * - このコンポーネントは label 表示などは提供せずに、input のみを描画する
 *
 * ## 残実装TODOs
 *
 *
 */
export default {
  name: 'CCSearchFilterInputWithColumn',
  inject: ['ComposableDataListServiceInstance'],
  props: {
    /**
     * 値の FilterOperator
     * - カラムタイプに応じてデフォルト値を設定
     */
    filterOperator: {
      type: String as PropType<keyof typeof filterOperators>,
      required: true,
    },
    /**
     * 対象カラム名, ドット区切りでネストしたカラム名も可
     */
    targetColName: {
      type: String,
      required: true,
    },
    /**
     * デフォルト値
     */
    defaultFilterValue: {
      type: [String, Number, Boolean, Array],
      required: false,
      default: null,
    },
    /**
     * フィルターグループ名 を 指定
     * - 指定しない場合は、ModelName + カラム名をフィルターグループ名として利用する
     */
    filterGroupName: {
      type: String,
      default: null,
    },
    /**
     * フィルタ値の適用時に, 同一グループのフィルタ値を上書きするかどうか
     * - true に設定すると
     */
    overwriteFilterValue: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String as PropType<SearchFilterInputInputType>,
      default: 'text',
    },
  },
  data() {
    return {
      filterValue: this.defaultFilterValue,
    }
  },
  computed: {
    filtersByKeyForDebug() {
      console.log(
        `this.ComposableDataListServiceInstance:`,
        this.ComposableDataListServiceInstance,
      )
      return this.$cdls?.query?.filtersByKey
    },
    $cdls(): ComposableDataListService {
      return this.ComposableDataListServiceInstance as ComposableDataListService
    },
    modelName(): string {
      return this.$cdls.modelName
    },
    applyFilterGroupKey(): string {
      return this.filterGroupName || `${this.modelName}.${this.targetColName}`
    },
    filteringObject() {
      return $core.$utils.unFlattenObject(
        {
          [this.targetColName]: {
            [this.filterOperator]: this.filterValue,
          },
        },
        {
          splitString: '.',
        },
      )
    },
  },
  methods: {
    /**
     * フィルターを適用するメソッド
     */
    applyFilter() {
      this.$cdls.query.applyFilter(
        this.applyFilterGroupKey,
        this.filteringObject,
        !this.overwriteFilterValue,
      )
    },
    /**
     * フィルターの値を更新し、フィルターを適用するメソッド
     * @param value 新しいフィルター値
     */
    updateFilterValue(value: any) {
      this.filterValue = value
      this.applyFilter()
    },
  },
}
</script>
