export const csvParserLite = (csvString: string): Record<string, string>[] => {
  // BOMを除去
  const csvStringWithoutBom = csvString.replace(/^\ufeff/, '');

  // 全体を一度にパースする
  const allFields = parseCSV(csvStringWithoutBom);

  if (allFields.length === 0) {
    return [];
  }

  // ヘッダ行
  const headerRow = allFields[0];

  // データ行の処理
  return allFields.slice(1).map(values => {
    return headerRow.reduce((record, header, index) => {
      record[header] = index < values.length ? values[index] : '';
      return record;
    }, {} as Record<string, string>);
  });
};

// CSVを全体的にパースする関数
const parseCSV = (csv: string): string[][] => {
  const result: string[][] = [];
  let row: string[] = [];
  let inQuotes = false;
  let currentValue = '';

  for (let i = 0; i < csv.length; i++) {
    const char = csv[i];
    const nextChar = csv[i + 1];

    if (char === '"') {
      if (inQuotes && nextChar === '"') {
        // エスケープされた二重引用符
        currentValue += '"';
        i++;
      } else {
        // 引用符の開始または終了
        inQuotes = !inQuotes;
      }
    } else if (char === ',' && !inQuotes) {
      // フィールドの区切り
      row.push(currentValue.trim());
      currentValue = '';
    } else if ((char === '\r' && nextChar === '\n') || char === '\n') {
      // 行の終わり
      if (!inQuotes) {
        row.push(currentValue.trim());
        result.push(row);
        row = [];
        currentValue = '';
        if (char === '\r') i++; // \r\nの場合、次の文字をスキップ
      } else {
        // 引用符内の改行は保持
        currentValue += char === '\r' ? '\r\n' : '\n';
        if (char === '\r') i++; // \r\nの場合、次の文字をスキップ
      }
    } else {
      currentValue += char;
    }
  }

  // 最後の行を処理
  if (currentValue !== '' || row.length > 0) {
    row.push(currentValue.trim());
    result.push(row);
  }

  return result;
};