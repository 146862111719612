<template>
  <input class="data-list-display-item-checkbox data-list-display-checkbox-bulk" type="checkbox" v-model="checked" />
</template>
<script lang="ts">
import { inject } from 'vue'
import { DataListDisplayService } from '../../../ComposableDataListComponents/front/components/DataListDisplay/DataListDisplayService'

export default {
  setup() {
    return {
      DataListDisplayServiceInstance: inject<DataListDisplayService>(
        'DataListDisplayServiceInstance',
      ),
    }
  },
  computed: {
    checked: {
      get() {
        return this.DataListDisplayServiceInstance.bulkChecked
      },
      set(value) {
        this.DataListDisplayServiceInstance.bulkCheckItemIds(value)
      },
    },
  },
}
</script>
