import { detectVModelNameWithRecord } from '../../common/$virtualModels'
import { ComponentPublicInstance } from 'vue'
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'
import { registerFunctionLoggedInExecuteOnce } from '../../common/utils'
import { ColumnDefByColName } from '../../common/$models/ModelDef'
import { makeItReactive } from '../$frameworkUtils/$frameworkUtils'

export const ModalTypeMap = {
  CreateView: 'CreateView',
  EditView: 'EditView',
  ListView: 'ListView',
  ModelView: 'ModelView',
} as const
type ModalType = keyof typeof ModalTypeMap
type ModalId = string

export interface Modal {
  modalId?: ModalId
  component?: any
  componentType?: ModalType
  componentProps?: any
  modalProps?: any
}

let i = 0

/**
 * # $core.$modals
 *
 * `$core.$modals` のメソッドを利用すると、Javascript内からモーダルをコントロール可能です。
 *
 * ## 機能・ユースケース
 * - モーダルでデータ一覧表示 (モデル名, Virtualモデル名, および絞り込み条件を指定可能)
 * - モデル定義名 と primaryKey値 (id) を元に、編集画面を開く
 * - 独自定義したComponentを表示
 * - 現在開いているすべてのモーダルを閉じる
 *
 * ## データ一覧を表示: `openListViewModal()`
 *
 * <CodeSampleWithDemo pac>
 * <template #title><code>$core.$modals.openListViewModal()</code> example</template>
 * - Model名を指定して、Modal内で データの一覧を表示する例です。
 *
 * ```html
 * <span
 *   class="btn btn-primary"
 *   @click.prevent="() => $core.$modals.openListViewModal({
 *     modelName: 'selectOptionsMaster'
 *   })">
 *   Model定義 "selectOptionsMaster" のデータを一覧表示
 *   <ficon type="external-link-alt"/>
 * </span>
 * ```
 * </CodeSampleWithDemo>
 *
 * ## 編集画面を表示: `openEditViewModal({modelName, })`
 *
 * <CodeSampleWithDemo pac desc="Model名およびレコードのIDを指定して、Modal内で データの一覧を表示します。">
 * <template #title><code>$core.$modals.openEditViewModal()</code> example</template>
 *
 * ```html
 * <template>
 *   <span
 *     class="btn btn-primary"
 *     @click.prevent="() => openModal()">
 *     編集画面を開く
 *     <ficon type="external-link-alt"/>
 *   </span>
 * </template>
 * <script>
 *
 * const recordId = 'e34b84b6-ec2d-4dd4-be52-eb4452edc06c'
 * export default {
 *   methods: {
 *     openModal() {
 *       $core.$modals.openEditViewModal({
 *         modelName: 'selectOptionsMaster', // 必須
 *         id: recordId, // 必須 ※ プライマリキーのカラム名が id でなくても、プロパティとしては "id" を指定します。
 *       })
 *     }
 *   }
 * }
 * </script>
 * ```
 * </CodeSampleWithDemo>
 *
 * @category coreFront/modals
 */
export class ModalService {
  /**
   * @hidden
   */
  modalsByModalId: { [modalId: string]: Modal } = {}
  // Modalを表示している Modal.vue インスタンスへの参照を保持
  $vm: ComponentPublicInstance | null = null

  /**
   * @hidden
   */
  static get instance(): ModalService {
    return singletonInstanceSummoner('ModalService', ModalService)
  }

  /**
   * @hidden
   */
  constructor() {
    this.modalsByModalId = makeItReactive({}) // reactive
  }

  private _addModal(modal: Modal): void {
    this.modalsByModalId[modal.modalId] = modal
  }

  /**
   * ModalIdを指定して、Modalを閉じる
   * @param modalId
   * @param closeEvent
   */
  async closeModal(modalId: string, closeEvent: Event = null): Promise<void> {
    if (closeEvent && $core.$configVars.get('confirmWhenExitWithoutSave', false)) {
      const closeModalCheckFlag = await this.checkModelFormEditChanged(modalId)
      if (!closeModalCheckFlag) {
        closeEvent.preventDefault()
        return
      }
    }

    if (this.modalsByModalId[modalId]?.modalProps) {
      this.modalsByModalId[modalId].modalProps.visible = false
    }
    // delete this.modalsByModalId[modalId] と同義, reactivityのために
    if (this.modalsByModalId[modalId]) {
      delete this.modalsByModalId[modalId]
    }
  }

  /**
   * ModalIdを指定して、VueComponentを取得
   * @param modalId
   */
  findVueComponentByModalId(modalId: string): any {
    const componentType = this.modalsByModalId[modalId]?.componentType
    return componentType ? this.$vm.$refs[componentType][0] : null
  }

  /**
   * モーダル内のModelFormが変更されているかどうかをチェックする
   * @param modalId
   */
  async checkModelFormEditChanged(modalId: string): Promise<boolean> {
    const modalVueComponent = this.findVueComponentByModalId(modalId)
    if (!modalVueComponent) {
      return true
    }
    const ModelForms = modalVueComponent.$refs?.AppHookableComponent?.$refs?.ModelForm
    if (!ModelForms || !ModelForms.length) {
      return true
    }
    const ModelForm = ModelForms[0]
    if (ModelForm.hasDataChanged === true && ModelForm.hasSaved === false) {
      const _msg = '変更が保存されていませんがよろしいですか？'
      if ($core.$configVars.get('useCoreConfirmModal', true)) {
        return await $core.$toast.confirmDialog(_msg);
      } else {
        // 普通のJavascriptモダールを使う
        return window.confirm(_msg);
      }
    }

    return true
  }

  /**
   * 最上部に表示されているModalのModalIdを取得
   */
  get topModalId(): string {
    return Object.keys(this.modalsByModalId || {})[this.openingModalCount - 1]
  }

  /**
   * 最上部に表示されているModalを閉じる
   */
  closeTopModal() {
    if (this.topModalId) {
      this.closeModal(this.topModalId)
    }
  }

  /**
   * すべてのModalを閉じる
   */
  closeAllModal() {
    try {
      // @ts-ignore
      this.$vm.$children.map((_vm) => (_vm?.hide ? _vm?.hide() : null))
    } catch (e) {
      $core.$errorReporter.r(e, this)
    }
  }

  /**
   * モーダルを開く with VueComponent
   *
   * 引数 modalProps は、Bootstrap Vue の Modal Propsである https://bootstrap-vue.org/docs/components/modal#comp-ref-b-modal-props
   *
   *
   * ##### 例: Vueコンポーネント (ファイル) を dynamic import しつつ開く
   * ```ts
   * $core.$modals.openModal({
   *   component: () => import('./someDefinedComponent.vue'),
   *   modalProps: {
   *     title: 'モーダルタイトルとなるテキスト',
   *     onClose: () => {
   *       window.removeEventListener('fileSelectorModal:selected', selectedCallback)
   *     },
   *   },
   * })
   * ```
   * ##### 例: Vueコンポーネント (ファイル) を 直接定義して開く
   * ```ts
   * const someVariable = 'sample text'
   * const anotherVariable = { a: 'abc' }
   * $core.$modals.openModal({
   *   modalProps: {
   *     hideHeader: true, // ヘッダーを非表示に
   *   },
   *   // Vueコンポーネント定義をオブジェクトで渡すパターン
   *   component: {
   *     template: `<div>サンプル モーダルContent: aValueOfAnotherVariable: {{aValueOfAnotherVariable}}, ${someVariable}</div>`,
   *     data() {
   *       return {
   *         autoLoad: true,
   *         hideControls: false,
   *         displayType: type,
   *       }
   *     },
   *     computed: {
   *       aValueOfAnotherVariable() {
   *         return anotherVariable.a
   *       }
   *     },
   *     methods: {
   *       ...
   *     }
   *   }
   * })
   * ```
   * @param modal
   */
  openModal(modal: Modal) {
    // configure default modal props
    // generate modal id if not specified or already exists
    const shouldGenerateModalId = !modal.modalId || !!this.modalsByModalId?.[modal.modalId]
    const modalId = shouldGenerateModalId ? `modal_${i++}` : modal.modalId
    modal.modalId = modalId
    modal.modalProps = Object.assign(
      {
        centered: false,
        size: 'lg',
        okVariant: 'outline-secondary',
        okTitle: '閉じる',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        visible: true,
      },
      modal.modalProps,
    )

    // merge onClose
    const { onClose } = modal.modalProps
    modal.modalProps.onClose = (event) => {
      event.preventDefault() //
      if (onClose) {
        onClose(event)
      }
      this.closeModal(modalId, event)
      return false
    }
    // merge onOk
    const { onOk } = modal.modalProps
    modal.modalProps.onOk = (event) => {
      if (onOk) {
        onOk(event)
      }
    }

    if (modal.modalProps.autoFocusToCancel) {
      modal.modalProps.onShown = () => {
        $core.$utils.programmaticFocusWithToggleClass(document.querySelector(
          `#modal-${modalId} .modal-footer button.btn-cancel`,
        ))
      }
    }
    if (modal.modalProps.autoFocusToOk) {
      modal.modalProps.onShown = () => {
        $core.$utils.programmaticFocusWithToggleClass(document.querySelector(
          `#modal-${modalId} .modal-footer button.btn-ok`
        ))
      }
    }

    // show
    this._addModal(modal)
    return modal.modalId
  }

  /**
   * モデル名 or Virtualモデル名 & id を渡して、編集モーダルを開く
   * @param id レコードのプライマリキーの値
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param successCallback optional: 保存成功時に呼ばれるコールバック
   * @param deleteCallback optional: 削除時に呼ばれるコールバック
   * @param otherComponentProps optional: その他の編集コンポーネントに渡すprops
   * @param modalProps optional: その他のモーダルに渡すprops
   */
  async openEditViewModal({
    id,
    modelName,
    virtualModelName,
    successCallback,
    deleteCallback,
    otherComponentProps,
    modalProps,
  }: {
    id: string | number
    modelName: string
    virtualModelName?: string
    successCallback?: any
    deleteCallback?: any
    otherComponentProps?: Record<string, any>
    modalProps?: Record<string, any>
  }): Promise<ModalId> {
    const modal: Modal = {
      componentType: ModalTypeMap.EditView,
      componentProps: {
        ...(otherComponentProps || {}),
        passedId: id,
        modelName,
        virtualModelName,
        columns: (globalThis.$core.$virtualModels[virtualModelName] || {}).colNames,
        successCallback,
        deleteCallback,
      },
      modalProps: modalProps || {}
    }
    return this.openModal(modal)
  }

  /**
   * モデル名 or Virtualモデル名 を元に、新規作成モーダルを開く
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param defaultValues optional: デフォルト値, オブジェクト
   * @param successCallback optional: 保存成功時に呼ばれるコールバック関数
   * @param deleteCallback optional: 削除時に呼ばれるコールバック関数
   * @param otherComponentProps optional: その他の ModelForm コンポーネントに渡すprops
   * @param modalProps optional: その他の モーダルに渡すprops
   */
  openCreateViewModal({
    modelName,
    virtualModelName = null,
    defaultValues = null,
    successCallback = null,
    deleteCallback = null,
    otherComponentProps = {},
    modalProps = {},
  }): ModalId {
    let modalId = null
    if (!successCallback) {
      successCallback = () => {
        this.closeModal(modalId)
      }
    }
    const modal: Modal = {
      componentType: ModalTypeMap.CreateView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        passedDefaultValues: defaultValues,
        columns: (globalThis.$core.$virtualModels[virtualModelName] || {}).colNames,
        successCallback,
        deleteCallback,
      },
      modalProps,
    }
    modalId = this.openModal(modal)
    return modalId
  }

  /**
   * モデル名 or Virtualモデル名 を元に、一覧モーダルを開く
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param fields optional: 表示するフィールド名の配列
   * @param filters optional: フィルター条件, 設定すると、検索絞り込みが適用された状態で開く
   * @param filter optional: フィルター条件, 設定すると、検索絞り込みが適用された状態で開く
   * @param otherComponentProps optional: その他 一覧コンポーネントに渡すprops
   * @param modalProps optional: その他 モーダルに渡すprops
   */
  openListViewModal({
    modelName,
    virtualModelName = null,
    fields = null,
    filters = null,
    filter = null,
    otherComponentProps = {},
    modalProps = {},
  }): ModalId {
    const modal: Modal = {
      componentType: ModalTypeMap.ListView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        fields,
        filters: filters || filter,
      },
      modalProps: modalProps,
    }
    return this.openModal(modal)
  }

  /**
   * モデル名 or Virtualモデル名 & id を元に、レコード詳細 (閲覧のみ) のモーダルを開く
   * @param id レコードのプライマリキーの値
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param referenceField optional: リレーションのフィールド名
   * @param otherComponentProps optional: その他 詳細コンポーネントに渡すprops
   * @param modalProps optional: その他 モーダルに渡すprops
   */
  async openModelViewModal({
    id,
    modelName,
    virtualModelName = null,
    referenceField = null,
    otherComponentProps = {},
    modalProps = {},
  }: {
    id: string | number
    modelName: string
    virtualModelName?: string | null
    referenceField?: string | null
    otherComponentProps?: any
    modalProps?: any
  }): Promise<ModalId> {
    if (referenceField && referenceField !== 'id') {
      const record = (
        await globalThis.$core.$models[modelName].findAllBy({
          [referenceField]: id,
        })
      )[0]
      id = record.id
    }
    if (!virtualModelName) {
      virtualModelName = detectVModelNameWithRecord(
        modelName,
        await globalThis.$core.$models[modelName].findById(id),
      )
    }
    const modal: Modal = {
      componentType: ModalTypeMap.ModelView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        passedId: id,
      },
      modalProps,
    }
    return this.openModal(modal)
  }

  // 現在開いているModalの数をカウント
  get openingModalCount(): number {
    return Object.keys(this.modalsByModalId || {}).length
  }

  /**
   * ModelForm コンポーネントを使って、自由なフォームを表示する Modal
   * 例: 何かのアクションに反応して、なにかを入力させたいときに利用する
   * @return data - 入力されたデータ or null
   *
   * @param columns フォームに表示するカラム定義オブジェクト
   * @param defaultValues フォームに表示するデフォルト値, レコードオブジェクト
   * @param onCloseFunction モーダルが閉じられたときに呼ばれるコールバック関数
   * @param submitButtonLabel フォームの送信ボタンのラベルテキスト
   * @param disableConfirmBeforeSubmit true に設定した場合、フォームの送信前に確認ダイアログを表示しない
   * @param modalProps Modal コンポーネントに渡す props
   * @param modelFormProps ModelForm コンポーネントに渡す props
   */
  async openModelFormModal({
    columns,
    defaultValues = null,
    onCloseFunction = null,
    submitButtonLabel = 'OK',
    disableConfirmBeforeSubmit = true,
    componentProps = {},
    modalProps = {},
    modelFormProps = {},
  }: {
    columns: ColumnDefByColName
    defaultValues?: any
    onCloseFunction?: Function
    componentProps?: any
    modalProps?: any
    submitButtonLabel?: string
    disableConfirmBeforeSubmit?: boolean
    modelFormProps?: any
  }): Promise<any | null> {
    // 後でModalを閉じられるように、modalIdは指定
    const modalId = $core.$utils.generateRandomString()
    return new Promise((resolve, reject) => {
      const modal: Modal = {
        modalId,
        // @ts-ignore
        component: $frameworkUtils.defineAsyncComponent(
          // @ts-ignore
          () => import('./ModelFormInputtableModal.vue'),
        ),
        componentProps: {
          ...componentProps,
          columns,
          onSubmitFunction: ({ data }) => {
            resolve(data)
          },
          modelFormProps: {
            submitButtonLabel,
            disableConfirmBeforeSubmit,
            ...modelFormProps,
            // ModelForm デフォルト値
            record: defaultValues || {},
            // Submit時 resolve
            onSubmitFunction: (data) => {
              resolve(data)
              setTimeout(() => {
                this.closeModal(modalId)
              }, 1)
            },
          },
        },
        modalProps: {
          ...modalProps,
          async onClose(onCloseEvent) {
            if (typeof onCloseFunction === 'function') {
              resolve(await onCloseFunction(onCloseEvent))
            } else {
              resolve(null)
            }
          },
        },
      }
      this.openModal(modal)
    })
  }
}

export const $modals = ModalService.instance
