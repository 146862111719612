import VueDatepicker from '@vuepic/vue-datepicker'
import { App } from 'vue'
import Helpicon from '../$libs/helpicon.vue'
import { registerFilterItemComponents } from '../../plugins/ComposableDataListComponents/front/registerFilterItemComponents'
import ModelColumnInput from '../../plugins/ComposableModelForm/front/components/ModelColumnInput.vue'
import { registerFormInputComponents } from '../../plugins/ComposableModelForm/ModelColumnInputs/front/registerFormInputComponents'
import HelpDoc from '../../plugins/HelpDoc/HelpDoc.vue'
import RemoteCOREHelpDoc from '../../plugins/HelpDoc/RemoteCOREHelpDoc.vue'
import ModelBulkControl from '../../plugins/ModelRecordBulkControlService/front/components/ModelBulkControl.vue'
import ModelPartialBulkEdit from '../../plugins/ModelRecordBulkControlService/front/components/ModelPartialBulkEdit.vue'
import { Multiselect } from '../ModelIndex/Multiselect'
import DeleteLink from './DeleteLink.vue'
import ModelCreatePage from './ModelCreatePage.vue'
import ModelEditPage from './ModelEditPage.vue'
import ModelForm from './ModelForm.vue'
import ModelFormGroup from './ModelFormGroup.vue'
import ModelFormGroupedEdit from './ModelFormGroupedEdit.vue'
import ModelFormSkelton from './ModelFormSkelton.vue'
import arrayOfObject from './ModelInput/arrayOfObject.vue'
import BooleanRadioInput from './ModelInput/BooleanRadioInput.vue'
import ModelInput from './ModelInput/ModelInput.vue'
import ModelValidationCondition from './ModelInput/ModelValidationCondition.vue'
import MultiselectCheckboxesInput from './ModelInput/MultiselectCheckboxesInput.vue'
import oneRowOfArrayOfObjectInput from './ModelInput/oneRowOfArrayOfObjectInput.vue'
import ReferenceMultiSelect from './ModelInput/ReferenceMultiSelect.vue'
import SortableRecordList from './ModelInput/SortableRecordList.vue'

export const registerModelFormComponents = (Vue: App) => {
  // eslint-disable-next-line
  Vue.component('Multiselect', Multiselect)
  Vue.component('ModelBulkControl', ModelBulkControl)
  Vue.component('ModelPartialBulkEdit', ModelPartialBulkEdit)
  Vue.component('ArrayOfObject', arrayOfObject)
  Vue.component('SortableRecordList', SortableRecordList)
  Vue.component('VueDatepicker', VueDatepicker)
  Vue.component('ModelInput', ModelInput)
  Vue.component('ModelColumnInput', ModelColumnInput)
  Vue.component('OneRowOfArrayOfObjectInput', oneRowOfArrayOfObjectInput)
  Vue.component('ReferenceMultiSelect', ReferenceMultiSelect)
  Vue.component('DeleteLink', DeleteLink)
  Vue.component('ModelCreatePage', ModelCreatePage)
  Vue.component('ModelEditPage', ModelEditPage)
  Vue.component('ModelForm', ModelForm)
  Vue.component('ModelFormGroup', ModelFormGroup)
  Vue.component('ModelFormGroupedEdit', ModelFormGroupedEdit)
  Vue.component('ModelFormSkelton', ModelFormSkelton)
  Vue.component(
    'ColumnGroupingControlForBeforeComponent',
    $frameworkUtils.defineAsyncComponent(
      () => import('./ColumnGroupingControlForBeforeComponent.vue'),
    ),
  )
  // eslint-disable-next-line
  Vue.component('Helpicon', Helpicon)
  Vue.component(
    'DisplayOtherModelRecordOnForm',
    $frameworkUtils.defineAsyncComponent(
      () => import('./ModelInput/DisplayOtherModelRecordOnForm.vue'),
    ),
  )
  Vue.component('BooleanRadioInput', BooleanRadioInput)
  Vue.component('MultiselectCheckboxesInput', MultiselectCheckboxesInput)
  Vue.component(
    'ColorPicker',
    $frameworkUtils.defineAsyncComponent(() => import('./ModelInput/ColorPicker.vue')),
  )
  Vue.component(
    'FieldPreviewEditor',
    $frameworkUtils.defineAsyncComponent(
      () => import('../../plugins/FieldPreviewEditor/front/FieldPreviewEditor.vue'),
    ),
  )
  Vue.component(
    'InputComponentForColumnType',
    $frameworkUtils.defineAsyncComponent(
      () =>
        import(
          '../../plugins/InputComponentForColumnType/InputComponentForColumnType.vue'
        ),
    ),
  )
  Vue.component(
    'ExcelEditor',
    $frameworkUtils.defineAsyncComponent(
      () => import('../../plugins/ExcelEditor/ExcelEditor.vue'),
    ),
  )
  Vue.component(
    'DBDirectQueryTester',
    $frameworkUtils.defineAsyncComponent(
      () => import('../../plugins/DBDirectQueryExecutor/DBDirectQueryExecutor.vue'),
    ),
  )
  Vue.component(
    'RecordEditableConditionForUserRole',
    $frameworkUtils.defineAsyncComponent(
      () => import('../UserRoles/RecordEditableConditionForUserRole.vue'),
    ),
  )
  Vue.component(
    'RecordDeletableConditionForUserRole',
    $frameworkUtils.defineAsyncComponent(
      () => import('../UserRoles/RecordDeletableConditionForUserRole.vue'),
    ),
  )

  // New version for Composable Component
  registerFormInputComponents(Vue)
  // New version for filter item component
  registerFilterItemComponents(Vue)

  Vue.component('HelpDoc', HelpDoc)
  Vue.component('RemoteCOREHelpDoc', RemoteCOREHelpDoc)
  Vue.component('ModelValidationCondition', ModelValidationCondition)
  Vue.component(
    'JsonViewer',
    $frameworkUtils.defineAsyncComponent(
      () => import('../../plugins/JsonViewer/front/JsonViewer.vue'),
    ),
  )

  Vue.component(
    'ResizableColumns',
    $frameworkUtils.defineAsyncComponent(
      () =>
        import(
          '../../plugins/AppAndPageLayouts/front/components/ResizeableColumns/ResizableColumns.vue'
        ),
    ),
  )

  Vue.component(
    'ModelDatasourcesFilterAndSelects',
    $frameworkUtils.defineAsyncComponent(
      () =>
        import(
          '../../plugins/ModelDatasourcesFilterAndSelects/front/ModelDatasourcesFilterAndSelects.vue'
        ),
    ),
  )
}
