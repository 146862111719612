// @ts-nocheck
// import Vue from 'vue'
/* Load global components */
import {
  BButton,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BFormSpinButton,
  BFormTag,
  BFormTags,
  BFormText,
  BFormTextarea,
  BFormValidFeedback,
  BInputGroup,
  BInputGroupAddon,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroupText,
  BPopover,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  vBPopover,
  vBToggle,
  vBTooltip,
} from 'bootstrap-vue-next'

import Skeleton from '../../plugins/Skeleton/front/Skeleton.vue'
import BModal from '../Modal/BModal.vue'

/**
 * TODO: 減らしたい, 増えていっているが...
 * @param Vue
 */
export const loadBootstrapVueComponents = (Vue) => {
  Vue.directive('b-popover', vBPopover)
  Vue.directive('b-tooltip', vBTooltip)
  Vue.directive('b-toggle', vBToggle)
  Vue.component('BModal', BModal)
  Vue.component('BTabs', BTabs)
  Vue.component('BTab', BTab)
  Vue.component('BInputGroup', BInputGroup)
  Vue.component('BInputGroupAddon', BInputGroupAddon)
  Vue.component('BInputGroupAppend', BInputGroupAppend)
  Vue.component('BInputGroupPrepend', BInputGroupPrepend)
  Vue.component('BInputGroupText', BInputGroupText)

  Vue.component('BButton', BButton)
  Vue.component('BPopover', BPopover)
  Vue.component('BCollapse', BCollapse)
  Vue.component('BTable', BTable)

  Vue.component('BFormGroup', BFormGroup)
  BFormInput.props.lazy.default = true
  Vue.component('BFormInput', BFormInput)
  Vue.component('BFormRadio', BFormRadio)
  Vue.component('BFormRadioGroup', BFormRadioGroup)
  Vue.component('BFormSelect', BFormSelect)
  Vue.component('BFormSelectOption', BFormSelectOption)
  Vue.component('BFormSelectOptionGroup', BFormSelectOptionGroup)
  Vue.component('BFormText', BFormText)
  Vue.component('BFormTextarea', BFormTextarea)
  Vue.component('BFormTag', BFormTag)
  Vue.component('BFormTags', BFormTags)
  Vue.component('BFormSpinButton', BFormSpinButton)
  Vue.component('BFormValidFeedback', BFormValidFeedback)
  Vue.component('BFormCheckbox', BFormCheckbox)
  Vue.component('BFormCheckboxGroup', BFormCheckboxGroup)
  Vue.component('BSpinner', BSpinner)
  Vue.component('Skeleton', Skeleton)
  Vue.component('BSkeleton', Skeleton)
}
