// フォームの編集状態を司るサービス
// [WIP] Migrating from ModelForm.vue
// ModelForm.vue から provide() => 各Component で inject
import { ModelFactory } from '../../common/$models'
import {
  ColumnDef,
  ErrorMessagesObject,
  ModelDef,
} from '../../common/$models/ModelDef'
import { VirtualModelFactory } from '../../common/$virtualModels'
import { FilterControlsService } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'
import {
  ColumnGroupFormattedDef,
  convertColumnsForModelFormGroups,
  formatColumnGroupDefs,
} from './index'

/**
 * フォームの編集状態を司るサービス
 *
 * - TODO: $models および this.model の 直接参照を解除する
 */
export class ModelFormService {
  modelName: string
  model: ModelFactory | null
  virtualModelName: string
  virtualModel: VirtualModelFactory | null
  isNewRecord: boolean
  record: any
  columns
  onSubmitFunction
  onDeleteFunction
  forceTreatAsNewRecord
  disableSubmitAction
  modalId
  additionalEditCallbackFunction
  submitButtonLabel
  disableDelete
  disableConfirmBeforeSubmit
  ignoreColNames
  wrapTag
  wrapTagClass
  readOnlyMode
  visibleStateByColumnGroupName: { [colGroupName: string]: string } = {}
  $modelFormVueInstance
  columnGroupFormattedDef: ColumnGroupFormattedDef = {}
  columnGroupParentKeyByChildGroupKey: { [childGroupKey: string]: string } = {}
  shouldEnableFormGUIEditMode = false
  formStyleTypeSpecified: string
  modelFormValidationMessages: ErrorMessagesObject
  validationErrorMessages: ErrorMessagesObject
  formColumnGroupsGiven: ModelDef['formColumnGroups']
  /**
   * 部分的に ModelDef を上書きするためのオブジェクト
   */
  modelDefOverrideObject: Partial<ModelDef> = {}
  private _calcCache: { [key: string]: any } = {}
  public FilterControlsServiceInstance: FilterControlsService = new FilterControlsService(
    {
      ComposableDataListServiceInstance: null,
      ModelFormServiceInstance: this,
    },
  )
  // selectionのFocus（ドロップダウンがされているのかのフラグ）
  public selectionFocus = false

  constructor({
    modelName,
    virtualModelName,
    columns,
    record,
    onSubmitFunction,
    onDeleteFunction,
    forceTreatAsNewRecord,
    disableSubmitAction,
    modalId,
    additionalEditCallbackFunction,
    submitButtonLabel,
    disableDelete,
    disableConfirmBeforeSubmit,
    ignoreColNames,
    wrapTag,
    wrapTagClass,
    readOnlyMode,
    $modelFormVueInstance,
    shouldEnableFormGUIEditMode,
    formStyleType,
    formColumnGroups,
    modelDefOverrideObject,
  }) {
    this.virtualModelName = virtualModelName
    // @ts-ignore
    this.virtualModel =
      $core.$virtualModels && this.virtualModelName
        ? $core.$virtualModels[this.virtualModelName] || null
        : null
    this.modelName = modelName || this.virtualModel?.baseModel || null
    // @ts-ignore
    this.model =
      $core.$models && this.modelName ? $core.$models[this.modelName] || null : null
    this.columns = columns
    this.record = record
    this.onSubmitFunction = onSubmitFunction
    this.onDeleteFunction = onDeleteFunction
    this.forceTreatAsNewRecord = forceTreatAsNewRecord
    this.disableSubmitAction = disableSubmitAction
    this.modalId = modalId
    this.additionalEditCallbackFunction = additionalEditCallbackFunction
    this.submitButtonLabel = submitButtonLabel
    this.disableDelete = disableDelete
    this.disableConfirmBeforeSubmit = disableConfirmBeforeSubmit
    this.ignoreColNames = ignoreColNames
    this.wrapTag = wrapTag
    this.wrapTagClass = wrapTagClass
    this.readOnlyMode = readOnlyMode
    this.$modelFormVueInstance = $modelFormVueInstance
    this.shouldEnableFormGUIEditMode = shouldEnableFormGUIEditMode
    this.formStyleTypeSpecified = formStyleType
    this.selectionFocus = false
    this.validationErrorMessages = {}
    this.modelFormValidationMessages = {}
    this.formColumnGroupsGiven = formColumnGroups
    this.modelDefOverrideObject = modelDefOverrideObject || {}
    this._init()
  }

  _init() {
    this._prepareColumnGroups()
  }

  get columnsForRecord(): { [colName: string]: ColumnDef } {
    const columns = this.record.columns
    if (columns) {
      return columns.reduce((res, c) => {
        res[c.key] = c
        return res
      }, {})
    } else {
      return {}
    }
  }

  getModelAttr(attrName: string, fallbackValue: any = null) {
    return (
      this.modelDefOverrideObject[attrName] ||
      this.virtualModel?.[attrName] ||
      this.model?.[attrName] ||
      this[attrName] ||
      fallbackValue
    )
  }

  get formColumnGroupsCalced(): ModelDef['formColumnGroups'] {
    return this.formColumnGroupsGiven || this.getModelAttr('formColumnGroups', {})
  }

  // カラムグループの準備
  private _prepareColumnGroups() {
    this.columnGroupFormattedDef = formatColumnGroupDefs(
      this.formColumnGroupsCalced,
      this.filteredColumns,
    )
    this.columnGroupParentKeyByChildGroupKey = Object.keys(
      this.columnGroupFormattedDef,
    ).reduce((res, parentGroupName) => {
      const colGroup = this.columnGroupFormattedDef[parentGroupName]
      if (colGroup.items) {
        Object.keys(colGroup.items).map((childColGroupName) => {
          res[childColGroupName] = parentGroupName
        })
      }
      return res
    }, {})
  }

  updateModelValidationErrorMessages(errorMessasges: ErrorMessagesObject) {
    this.modelFormValidationMessages = errorMessasges
  }

  udpateColumnValidationErrorMessages(
    colName: string,
    errorMessasges: ErrorMessagesObject,
  ) {
    if (errorMessasges && errorMessasges[colName]) {
      this.validationErrorMessages[colName] = errorMessasges[colName]
    }

    if (!errorMessasges || !errorMessasges[colName]) {
      delete this.validationErrorMessages[colName]
    }

    for (const colName in this.modelFormValidationMessages) {
      if (this.validationErrorMessages[colName]) {
        this.validationErrorMessages[colName] = this.validationErrorMessages[
          colName
        ].concat(this.modelFormValidationMessages[colName])
      } else {
        this.validationErrorMessages[colName] = this.modelFormValidationMessages[colName]
      }
    }
  }

  get filteredColumns() {
    if (this._calcCache.filteredColumns) {
      return this._calcCache.filteredColumns
    }
    let columns = {}
    // カラム定義指定の場合
    if (this.model && Array.isArray(this.columns)) {
      columns = this.columns.reduce((res, c) => {
        // TODO: Dirty
        const isVColOfVModel = !!this.virtualModel?.columns[c]?.virtualColumnOf
        if (this.model.columns[c] || isVColOfVModel) {
          if (this.virtualModel) {
            res[c] =
              this.virtualModel.columnsMergedWithBaseModel[c] || this.model.columns[c]
          } else {
            res[c] = this.model.columns[c]
          }
        }
        return res
      }, {})
    } else {
      // 通常, virtualModel の場合を含む
      columns =
        this.columns ||
        this.virtualModel?.columnsMergedWithBaseModel ||
        this.model.columns
    }
    if (this.ignoreColNames) {
      columns = Object.keys(columns).reduce((res, c) => {
        if (!this.ignoreColNames?.includes(c)) {
          res[c] = columns[c]
        }
        return res
      }, {})
    }
    this._calcCache.filteredColumns = columns
    return this._calcCache.filteredColumns
  }

  get targetColumns() {
    if (this._calcCache.targetColumns) {
      return this._calcCache.targetColumns
    }
    this._calcCache.targetColumns = this._convertColumnsForModelFormGroups(
      this.filteredColumns,
    )
    return this._calcCache.targetColumns
  }

  private _convertColumnsForModelFormGroups(columns) {
    return convertColumnsForModelFormGroups(columns, this.columnGroupFormattedDef)
  }

  errorMessagesCallbackFunction({
    row,
    key,
    newValue,
    oldValue,
    isNewRecord,
    callerVueInstance,
    modelInputVm,
  }) {
    if (
      this.model?.errorMessagesCallback &&
      typeof this.model?.errorMessagesCallback === 'function'
    ) {
      return this.model.errorMessagesCallback({
        value: newValue,
        record: row,
        modelName: this.modelName,
        initialValue: oldValue,
        colDef: this.model.columns[key],
        recordRoot: this.record,
        modelInputVm: modelInputVm,
      })
    }

    if (
      this.virtualModel?.errorMessagesCallback &&
      typeof this.virtualModel?.errorMessagesCallback === 'function'
    ) {
      return this.virtualModel.errorMessagesCallback({
        value: newValue,
        record: row,
        modelName: this.modelName,
        initialValue: oldValue,
        colDef: this.model.columns[key],
        recordRoot: this.record,
        modelInputVm: modelInputVm,
      })
    }

    return null
  }

  get editCallbackFunctions() {
    if (this._calcCache.editCallbackFunctions) {
      return this._calcCache.editCallbackFunctions
    }
    const functions = []
    if (this.model?.editCallback && typeof this.model?.editCallback === 'function') {
      functions.push(this.model.editCallback)
    }
    if (
      this.virtualModel?.editCallback &&
      typeof this.virtualModel.editCallback === 'function'
    ) {
      functions.push(this.virtualModel.editCallback)
    }
    // Model定義指定が無く, columnsで Array 指定しているとき
    if (
      !this.model &&
      this.columns &&
      !Array.isArray(this.columns) &&
      Object.keys(this.columns || {}).length > 0
    ) {
      const functionsByColName = {}
      Object.keys(this.columns).map((colName: string) => {
        const colD = this.columns[colName]
        if (colD.editCallback && typeof colD.editCallback === 'function') {
          functionsByColName[colName] = colD.editCallback
        }
      })
      functions.push(
        async ({ row, key, newValue, oldValue, isNewRecord, callerVueInstance }) => {
          if (functionsByColName[key]) {
            row = await functionsByColName[key]({
              row,
              key,
              newValue,
              oldValue,
              isNewRecord,
              callerVueInstance,
            })
          }
          return row
        },
      )
    }

    if (
      this.additionalEditCallbackFunction &&
      typeof this.additionalEditCallbackFunction === 'function'
    ) {
      functions.push(this.additionalEditCallbackFunction)
    }
    this._calcCache.editCallbackFunctions = functions.length ? functions : null
    return this._calcCache.editCallbackFunctions
  }

  get formStyleType() {
    return (
      this.formStyleTypeSpecified ||
      this.getModelAttr('formStyle')?.type ||
      $core.$configVars.get('modelForm.defaultStyle', 'normal')
    )
  }
}
