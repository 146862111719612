<template>
  <span
    class="d-lg-none align-self-center nav-menu-toggler-button"
    @click="
      () =>
        ($core.$uiState.isSidebarActiveOnMobile = !$core.$uiState.isSidebarActiveOnMobile)
    "
  >
    <ficon :type="$core.$uiState.isSidebarActiveOnMobile ? 'x' : 'bars'" />
  </span>
</template>
<script lang="ts">
export default {
  name: 'NavbarToggler',
}
</script>

<style lang="scss">
.nav-menu-toggler-button {
  cursor: pointer;
  font-size: 14px;
}
</style>
