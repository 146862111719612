<template>
  <component :is="cStructure">{{ t__content }}</component>
</template>

<script lang="ts">
import { ColumnDefByColName } from '../../../common/$models/ModelDef'
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'

/**
 * テキストコンテンツの設定カラム
 */
export const composableComponentTextContentConfigColumns: ColumnDefByColName = {
  t__tag: {
    label: '要素のタグ',
    type: 'STRING',
  },
  t__isHtml: {
    label: 'HTML (Vue template) を使用する',
    type: 'BOOLEAN',
  },
  t__content: {
    label: 'テキスト',
    type: 'STRING',
    enableIf: (record) => !record.t__isHtml,
  },
  t__htmlContent: {
    label: 'HTML',
    type: 'TEXT',
    inputComponent: 'CodeEditor',
    enableIf: (record) => record.t__isHtml,
    inputAttrs: {
      rows: 8,
      language: 'html',
    },
    afterComponent: `<div class="small" v-pre>Vue template としてパースされるので、関数的表現で動的な値を表示可能です。<br/>例: 親コンポーネント <code>ComposableDataList</code> の <code>modelName</code> プロパティを参照する場合: <br/><code>{{ $core.$models[$core.$utils.findNearestParentVueComponentByName(this, 'ComposableDataList')?.modelName]?.tableLabel }} 一覧</code></div>`,
  },
}
const name = 'TextContent'
registerComposableComponentSettings(name, {
  label: 'コンテンツ',
  category: 'Basic',
  configColumns: composableComponentTextContentConfigColumns,
  defaultProps: {
    t__content: 'サンプルテキスト',
  },
})

/**
 * シンプルなテキストコンテンツ, TextNode と同じようなイメージで、各種 ComposableComponent が継承して使用することも可能
 */
export default {
  name: name,
  props: {
    t__content: {},
    t__isHtml: {},
    t__htmlContent: {},
    t__tag: {},
  },
  computed: {
    cStructure() {
      const tag = this.t__tag || 'span'
      if (this.t__isHtml) {
        return {
          template: `<${tag} v-bind="$attrs">${this.t__htmlContent || 'サンプル'}</${tag}>`,
        }
      } else {
        return {
          template: `<${tag}><slot/></${tag}>`,
        }
      }
    },
  },
}
</script>
