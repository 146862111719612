<template>
  <input
    class="ignoreItemClick data-list-display-item-checkbox"
    type="checkbox"
    :name="item.id + '' + _.uid"
    v-model="isChecked"
    @click="handleCheckboxClick($event, item.id)"
  />
</template>
<script lang="ts">
import { DataListDisplayService } from '../../../ComposableDataListComponents/front/components/DataListDisplay/DataListDisplayService'
import { inject } from 'vue'

export default {
  props: {
    item: { required: true },
    index: { required: true },
  },
  data() {
    return {
      checked: true,
    }
  },
  setup() {
    return {
      DataListDisplayServiceInstance: inject<DataListDisplayService>(
        'DataListDisplayServiceInstance',
      ),
      items: inject<any[]>('items'),
    }
  },
  computed: {
    isChecked: {
      get() {
        return !!this.DataListDisplayServiceInstance.checkedItemId[this.item.id]
      },
      set(value) {
        this.DataListDisplayServiceInstance.checkItemId(this.item.id, !!value)
      },
    },
  },
  methods: {
    handleCheckboxClick(event, id) {
      let isShiftClick = event.shiftKey
      if (isShiftClick && this.DataListDisplayServiceInstance.lastChecked !== null) {
        let start = Math.min(this.index, this.DataListDisplayServiceInstance.lastChecked)
        let end = Math.max(this.index, this.DataListDisplayServiceInstance.lastChecked)
        for (let i = start; i <= end; i++) {
          this.DataListDisplayServiceInstance.checkItemId(this.items[i].id, event.target.checked)
        }
      } else {
        this.DataListDisplayServiceInstance.lastChecked = this.index
      }
      // this.items[this.index].checked = event.target.checked
      this.DataListDisplayServiceInstance.checkItemId(id, event.target.checked)
    },
  },
}
</script>
