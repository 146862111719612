<template>
  <span
    class="app-hookable-component"
    :style="shouldEnableAppHookControlDisplay ? 'position:relative;' : ''"
  >
    <div
      v-if="shouldEnableAppHookControlDisplay"
      class="devInfo"
      title="$appHookable"
    >
      <AppHookableComponentExampleCodeDisplay />
    </div>
    <template v-if="initialized && componentArray">
      <!-- Important: v-bind="$attrs" componentResolved されたコンポーネントから、上位にEmitするため -->
      <component
        v-for="(c, index) in componentArray"
        :key="index"
        :is="c"
        :ref="calcRefName(c, index)"
        v-bind="$attrs"
      />
    </template>
    <slot v-if="initialized && !componentArray && hasSlot" />
  </span>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../plugins/ComposableComponents'
import AppHookableComponentExampleCodeDisplay from './AppHookableComponentExampleCodeDisplay.vue'
import { $appHook } from './index'

const name = 'AppHookableComponent'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  label: name,
  configColumns: {
    resolveHookName: {
      label: 'resolveHookName',
      type: 'STRING',
      defaultValue: '',
    },
  },
  description: ``,
})
/**
 * Component(s) を valid なものだけ配列として返す
 * @param components
 */
const makeComponentsArrayIfValid = (
  components: object[] | any | undefined | null,
): any[] | null => {
  if (!components) {
    return null
  }
  if (Array.isArray(components)) {
    const filtered = components?.map((c) => c.default || c)?.filter((c) => !!c)
    return filtered.length > 0 ? filtered : null
  }
  return [components?.default || components]
}

/**
 * <component :is="xxx"/> rendering 用に、 componentArray の Promise を一旦全てresolve する
 * Promise.allSettled => エラーになるcomponentは無視している
 * 下記のような例のために:
 * $core.$appHook.on('$CORE.admin.resolveComponent.header.right', async (resolvedComponents) => {
 *   resolvedComponents.push(import('./pages/contact.vue'))
 *   return resolvedComponents
 * }, 'c')
 */
const getAwaitResolvedComponentArray = async (componentArray: any[] | any) => {
  const array = makeComponentsArrayIfValid(componentArray)
  if (!array) {
    return null
  }
  const res = (await Promise.allSettled(array)).reduce((acc, cur) => {
    if (cur.status === 'fulfilled') {
      acc.push(cur.value.default || cur.value)
    } else {
      console.warn('getAwaitResolvedComponentArray', cur.reason)
    }
    return acc
  }, [])
  if (res.length > 0) {
    return res
  }
}

export default {
  name,
  components: { AppHookableComponentExampleCodeDisplay },
  props: {
    resolveHookName: { required: true },
    defaultComponentResolver: { required: false, type: Function },
    description: { required: false, type: String },
  },
  data() {
    return {
      initialized: false,
      componentArray: null, // Array
    }
  },
  computed: {
    hasSlot() {
      return !!this.$slots?.default
    },
    shouldEnableAppHookControlDisplay() {
      return $core.$configVars.get(
        'enableAppHookControlDisplay',
        !!$core?.$embAuth?.user?.isAdmin,
      )
    },
  },
  async created() {
    this.componentArray = $core.$frameworkUtils.markRaw(await this.resolveComponent())
    this.$nextTick(() => {
      this.initialized = true
    })
  },
  methods: {
    async resolveComponent() {
      // TODO: Beautify...
      // 1. $appHook での resolve try
      if ($appHook.hasHook(this.resolveHookName)) {
        const componentsResolved = getAwaitResolvedComponentArray(
          await $appHook.emit(this.resolveHookName, []),
        )
        if (componentsResolved) {
          return componentsResolved
        }
      }
      // 2. defaultComponentResolver での resolve try
      if (typeof this.defaultComponentResolver === 'function') {
        const componentsResolved = getAwaitResolvedComponentArray(
          await this.defaultComponentResolver(),
        )
        if (componentsResolved) {
          return componentsResolved
        }
      }
    },
    calcRefName(c, index) {
      if (typeof c === 'string') {
        return c
      }
      if (c.name) {
        return c.name
      }
      return `child-${index}`
    },
  },
}
</script>
<style lang="scss">
.app-hookable-component {
  /*display: inline-block;*/
  display: inline;
  .devInfo {
    display: none;
    //margin: auto -1em;
    z-index: 10;
    border-radius: 0;
  }
}

/**
 * 開発用の表示
 */
body.showAppHookableInfo {
  .app-hookable-component {
    display: block;
    outline: 2px dashed #ea6573;
    background-color: rgba(220, 53, 69, 0.1);
    .devInfo {
      display: block;
    }
    padding: 4px;
    margin: 8px;
    &:hover {
      outline: 2px dashed #ea6573;
      background-color: rgba(220, 53, 69, 0.1);
      .app-hookable-component:hover {
        outline: 4px dashed blue;
        background-color: rgba(220, 53, 69, 0.3);
      }
    }
  }
}
</style>
