import { ComponentInstance, ComponentOptions, Ref, ref } from 'vue'
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'

export type VueComponentDefinition = ComponentOptions | any
export type VueComponentInstance<T> = ComponentInstance<T>
export type InjectPlaceKey = 'default-main-before' | 'default-main-after'

/**
 * # $core.$componentStore
 *
 * - コンポーネントの動的な追加・管理を行うサービス
 * - コンポーネントの定義を追加し、指定した場所にインスタンスを生成
 * - コンポーネントインスタンスの参照を管理
 *
 * ## front test framework での利用ケース
 * - 背景: vue コンポーネントのユニットテスト時に、 CORE Framework vue app 内にコンポーネントを注入してテストを行う
 * ```ts
 * // some/path/to/SomeInput.vue.test.ts
 *
 * describe('SomeInput.vue', () => {
 *   it('renders correctly', () => {
 *     $componentStore.injectComponentTo('some-ref-name', SomeComponent, 'default-main-before')
 *   })
 * })
 * ```
 */
export class ComponentStoreService {
  public componentRefs: { [key: string]: VueComponentInstance<any> } = {}
  public injectedComponentsByKey: {
    [key in InjectPlaceKey]: {
      lastInjectedAt: Ref<number>
      components: {
        componentDefinition: VueComponentDefinition
        componentRefName: string
      }[]
    }
  } = {
    'default-main-before': { lastInjectedAt: ref(0), components: [] },
    'default-main-after': { lastInjectedAt: ref(0), components: [] },
  }

  /**
   * コンポーネント定義を追加し、指定した場所にインスタンスを生成するよう設定
   * コンポーネントが mounted されると promise が resolve される
   */
  async injectComponentTo<_Def extends VueComponentDefinition = any>(
    componentRefName: string,
    componentDefinition: VueComponentDefinition,
    injectPlaceKey: InjectPlaceKey = 'default-main-before',
  ): Promise<VueComponentInstance<_Def>> {
    // 追加する際に mixin を追加: mounted 時に $core.$componentStore.registerVueComponentInstance を呼び出す
    // その際に componentRefName を渡す
    let resolverFunction: (componentInstance: VueComponentInstance<_Def>) => void
    const promise = new Promise<VueComponentInstance<_Def>>((resolve) => {
      resolverFunction = resolve
    })
    componentDefinition.mixins = [
      ...(componentDefinition.mixins || []),
      {
        mounted() {
          $core.$componentStore.registerVueComponentInstance(componentRefName, this)
          this.$nextTick(() => {
            resolverFunction(this as VueComponentInstance<_Def>)
          })
        },
        onBeforeUnmount() {
          $core.$componentStore.destroyVueComponentInstance(componentRefName)
        },
      },
    ]
    this.injectedComponentsByKey[injectPlaceKey].components.push({
      componentDefinition,
      componentRefName,
    })
    this.injectedComponentsByKey[injectPlaceKey].lastInjectedAt.value = Date.now()
    return promise
  }

  /**
   * コンポーネントインスタンスを登録
   * Vue コンポーネント内で mounted フック内で this を登録することを想定
   * - ※ 注意点: 何度もレンダリング & 破棄される コンポーネントを登録すると メモリリークにつながるため注意, vue コンポーネント側の beforeUnmount() にて 正しく `$core.$componentStore.destroyVueComponentInstance(refName)` を呼び出すこと必須
   */
  registerVueComponentInstance(
    componentRefName: string,
    componentInstance: VueComponentInstance<any>,
  ) {
    if (this.componentRefs[componentRefName]) {
      console.warn(
        `[$componentStore] reference name "${componentRefName}" はすでに登録済みです。上書きします。`,
      )
    }
    this.componentRefs[componentRefName] = componentInstance
  }

  /**
   * 登録されたコンポーネントインスタンスを取得
   */
  getComponent<T extends VueComponentDefinition = any>(
    componentRefName: string,
  ): VueComponentInstance<T> | undefined {
    return this.componentRefs[componentRefName]
  }

  /**
   * 登録されたコンポーネントインスタンスへの参照を提供
   */
  get $refs(): { [key: string]: VueComponentInstance<any> } {
    return this.componentRefs
  }

  /**
   * 指定された場所に注入するコンポーネント定義の配列を取得
   */
  getInjectComponents(injectPlaceKey: InjectPlaceKey): VueComponentDefinition[] {
    return this.injectedComponentsByKey[injectPlaceKey].components
  }

  static get instance() {
    return singletonInstanceSummoner('ComponentStoreService', ComponentStoreService)
  }

  /**
   * コンポーネントインスタンスを破棄
   */
  destroyVueComponentInstance(componentRefName: string) {
    delete this.componentRefs[componentRefName]
  }
}

export const $componentStore = ComponentStoreService.instance
