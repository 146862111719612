import { DBDefinedModelDefinitionColumns } from '../modelDefinitions/DBDefinedModelDefinitionColumns'
import { createNewRecordWithColumnDefs } from './createNewRecordWithColumnDefs'
import { ColumnDefGroupDef, ModelDef } from './ModelDef'

const deletePropAndReturnValue = (obj, prop) => {
  const value = obj[prop]
  delete obj[prop]
  return value
}

/**
 * modelDef の key が 間違っている場合でも 対応できるように 変換する関数を定義
 */
const dbDefinedModelDefKeysConvertFunctions = {
  tableName: (modelDefLike) => {
    if (modelDefLike.modelName) {
      return deletePropAndReturnValue(modelDefLike, 'modelName')
    }
    return modelDefLike.tableName
  },
  formColumnGroupsAsArray: (modelDefLike): ColumnDefGroupDef[] => {
    if (typeof modelDefLike.formColumnGroups === 'object') {
      const formColumnGroups = deletePropAndReturnValue(modelDefLike, 'formColumnGroups')
      return Object.keys(formColumnGroups).map((key) => {
        return {
          key,
          ...formColumnGroups[key],
          groupKey:
            formColumnGroups[key].groupKey ||
            (formColumnGroups[key].type === 'tab' ? 'tab' : undefined),
        }
      })
    }
    return modelDefLike.formColumnGroupsAsArray || []
  },
}

/**
 * colDef の key が 間違っている場合でも 対応できるように 変換する関数を定義
 */
const dbDefinedColDefKeysConvertFunctions = {
  key: (colDefLike, modelDefLike) => {
    const key = colDefLike.key || colDefLike.name
    if (key === 'id') {
      // ModelDef 側の primaryKeyType が STRING でないのであれば visible false に強制
      if (modelDefLike.primaryKeyColType !== 'STRING') {
        colDefLike.visible = false
      }
      // id には 特に primaryKey を 設定する必要はないので、削除する
      if (colDefLike.primaryKey) {
        delete colDefLike.primaryKey
      }
    }
    if (colDefLike.name) {
      // name で設定されていても key に変換する
      // console.log(`colDefLike.name: ${colDefLike.name}`, colDefLike)
      return deletePropAndReturnValue(colDefLike, 'name')
    }
    return colDefLike.key
  },
}

const baseDbDefinedColDefConvertFunction = (colDefLike, colData) => {
  // validate は禁止, validationConfigs へ変換する
  if (colDefLike.validate) {
    // const validate = deletePropAndReturnValue(colDefLike, 'validate')
    // colDefLike.validationConfigs = validate
    if (!colData.validationConfigs) {
      colData.validationConfigs = []
    }
    Object.keys(colDefLike.validate).forEach((validateKey) => {
      if ($core.$modelsLoader.validationConfigTypes[validateKey]) {
        const args = colDefLike.validate[validateKey]
        if (args === true) {
          colData.validationConfigs.push({
            validationType: validateKey,
          })
        } else if (Array.isArray(args) && args.length > 1) {
          colData.validationConfigs.push({
            validationType: validateKey,
            validatorArg1: args[0],
            validatorArg2: args[1],
          })
        } else {
          colData.validationConfigs.push({
            validationType: validateKey,
            validatorArg1: args,
          })
        }
      }
    })
    delete colDefLike.validate
  }
}

/**
 * Model定義 的な データを `DBDefinedModelDefinitionColumns` に変換する
 *
 * ## Steps
 * - 1. DBDefinedModelDefinitionColumns の 'columns' 以外のデータについて整形する
 *   - 1-1. `$core.$models.modelDefinitions.columns` の (columns 以外の) keys について `modelDefLikeData` に存在するか確認する
 *   - 1-2. 存在する場合は、その値を `DBDefinedModelDefinitionColumns` にセットしつつ、 `modelDefLikeData` から削除する
 *   - 1-3. 存在しない場合は、 `otherModelAttributes` に値をセットする
 * - 2. 'columns' のデータについて整形する
 *   - 2-1. modelDefLike.columns が object なら => key を column item の `key` とする
 *   - 2-2. modelDefLike.columns が array なら => key を column item の `key` とする
 */
export const convertModelDefLikeDataToSaveableDBDefinedModelDefinitionColumns = async (
  modelDefLikeDataArray: ((ModelDef & DBDefinedModelDefinitionColumns) | any)[],
): Promise<Partial<DBDefinedModelDefinitionColumns>[]> => {
  const dbDefinedModelDefsResults: Partial<DBDefinedModelDefinitionColumns>[] = []
  const modelDefaultValues = await $core.$models.modelDefinitions.createNew()
  const colDefaultValues = await createNewRecordWithColumnDefs(
    $core.$models.modelDefinitions.columns.columns.columns,
  )
  const dbDefinedModelDefKeys = Object.keys($core.$models.modelDefinitions.columns)
  const dbDefinedColDefKeys = Object.keys(
    $core.$models.modelDefinitions.columns.columns.columns,
  )
  for (const modelDefLike of modelDefLikeDataArray) {
    const _modelData: Partial<DBDefinedModelDefinitionColumns> = JSON.parse(
      JSON.stringify(modelDefaultValues),
    )
    // 1. DBDefinedModelDefinitionColumns の 'columns' 以外のデータについて整形する
    const modelDefKeysToIgnore = ['columns']
    dbDefinedModelDefKeys.forEach((key) => {
      if (modelDefKeysToIgnore.includes(key)) {
        return
      }
      if (_modelData[key] === modelDefLike[key]) {
        return // do nothing
      }

      // 変換関数があるなら そちらを優先して実行する
      if (dbDefinedModelDefKeysConvertFunctions[key]) {
        _modelData[key] = dbDefinedModelDefKeysConvertFunctions[key](modelDefLike)
        delete modelDefLike[key]
        return
      }

      // key が `_` を 含んでいる場合は nested object 対象を check, 存在しているなら 値として取得しつつ、object から削除する
      if (key.includes('_') && modelDefLike[key] === undefined) {
        const value = getValueFromObjectWithKey({
          key,
          obj: modelDefLike,
          nestedKeySplitter: '_',
          deleteKey: true,
        })
        if (value !== undefined) {
          _modelData[key] = value
        }
        return
      }
      const value = modelDefLike[key]
      delete modelDefLike[key]
      if (value !== undefined) {
        _modelData[key] = value
      }
    })
    // `dbDefinedModelDefKeys` に存在しない key は `otherModelAttributes` にセットする
    const otherModelAttributes = Object.keys(modelDefLike).reduce((res, key) => {
      if (!dbDefinedModelDefKeys.includes(key)) {
        res[key] = modelDefLike[key]
      }
      return res
    }, {})
    if (Object.keys(otherModelAttributes).length > 0) {
      // TODO: マージ必須.... orz, しかも 文字列 Control で やり切る必要あり
      if (_modelData.otherModelAttributes) {
        // うまいことマージする
        console.log({
          'modelData.otherModelAttributes': _modelData.otherModelAttributes,
          otherModelAttributes,
        })
        _modelData.otherModelAttributes = mergeStringDefinedObject(
          otherModelAttributes,
          _modelData.otherModelAttributes,
        )
        console.log(
          `After merged, modelData.otherModelAttributes:`,
          _modelData.otherModelAttributes,
        )
        debugger
      } else {
        // @ts-ignore
        _modelData.otherModelAttributes = JSON.stringify(otherModelAttributes, null, 2)
      }
    }
    // 2. 'columns' のデータについて整形する
    _modelData.columns = convertColumnDefLike(
      modelDefLike.columns,
      colDefaultValues,
      _modelData,
    )
    dbDefinedModelDefsResults.push(_modelData)
  }
  return dbDefinedModelDefsResults
}

const convertColumnDefLike = (maybeColumns, colDefaultValues, modelDef) => {
  const dbDefinedColDefKeys = Object.keys(
    $core.$models.modelDefinitions.columns.columns.columns,
  )
  const columnDefLikeArray = Array.isArray(maybeColumns)
    ? maybeColumns
    : Object.keys(maybeColumns).map((key) => {
        return { key, ...maybeColumns[key] }
      })
  return columnDefLikeArray.map((colDefLike) => {
    const colData = JSON.parse(JSON.stringify(colDefaultValues))
    baseDbDefinedColDefConvertFunction(colDefLike, colData)
    dbDefinedColDefKeys.map((key) => {
      // 変換関数があるなら そちらを優先して実行する
      if (dbDefinedColDefKeysConvertFunctions[key]) {
        colData[key] = dbDefinedColDefKeysConvertFunctions[key](colDefLike, modelDef)
        return
      }

      // key が `_` を 含んでいる場合は nested object 対象を check, 存在しているなら 値として取得しつつ、object から削除する
      if (key.includes('_') && colDefLike[key] === undefined) {
        const value = getValueFromObjectWithKey({
          key,
          obj: colDefLike,
          nestedKeySplitter: '_',
          deleteKey: true,
        })
        if (value !== undefined) {
          colData[key] = value
        }
        return
      }
      const value = colDefLike[key]
      delete colDefLike[key]
      if (value !== undefined) {
        colData[key] = value
      }
    })
    // otherColAttributes へのセット: `colDefLike` に残っている key は `otherColAttributes` にセットする
    const otherColAttributes: Record<string, any> = Object.keys(colDefLike).reduce(
      (res, key) => {
        if (
          dbDefinedColDefKeysConvertFunctions[key] ||
          dbDefinedColDefKeys.includes(key)
        ) {
          return res
        }
        // 空 Array or Object は無視する
        if (Array.isArray(colDefLike[key]) && colDefLike[key].length === 0) {
          return res
        }
        if (
          typeof colDefLike[key] === 'object' &&
          Object.keys(colDefLike[key]).length === 0
        ) {
          return res
        }
        res[key] = colDefLike[key]
        return res
      },
      {},
    )
    // nest している columns を 処理
    if (
      colDefLike.columns &&
      Array.isArray(colDefLike.columns) &&
      colDefLike.columns?.length > 0
    ) {
      otherColAttributes.columns = colDefLike.columns.reduce((res, colDef) => {
        res[colDef.key] = {
          ...colDef,
          key: undefined,
        }
        return res
      }, {})
      delete colDefLike.columns
    }
    if (Object.keys(otherColAttributes).length > 0) {
      if (colData.otherColAttributes) {
        // うまいことマージする
        console.log({
          'colData.otherColAttributes': colData.otherColAttributes,
          otherColAttributes,
        })
        debugger
        // @ts-ignore
        colData.otherColAttributes = mergeStringDefinedObject(
          otherColAttributes,
          colData.otherColAttributes,
        )
        console.log(
          `After merged, colData.otherColAttributes:`,
          colData.otherColAttributes,
        )
        debugger
      } else {
        // @ts-ignore
        colData.otherColAttributes = JSON.stringify(otherColAttributes, null, 2)
      }
    }
    return colData
  })
}

const getValueFromObjectWithKey = ({
  key,
  obj,
  nestedKeySplitter = '_',
  deleteKey = true,
}: {
  key: string
  obj: any
  nestedKeySplitter: string
  deleteKey: boolean
}) => {
  const keys = key.split(nestedKeySplitter)
  const foundValue = keys.reduce((res, key) => {
    return res?.[key]
  }, obj)
  if (foundValue !== undefined && deleteKey) {
    keys.reduce((res, key, index) => {
      if (index === keys.length - 1) {
        delete res[key]
      }
      return res[key]
    }, obj)
  }
  return foundValue
}

/**
 * Object 同士を結合する (javascript object の結合であるため JSON.parse できない場合である前提)
 * @param obj1
 * @param obj2
 */
const mergeStringDefinedObject = (obj1: string | object, obj2: string | object) => {
  const obj1String =
    (typeof obj1 === 'string' ? obj1 : JSON.stringify(obj1, null, 2))?.trim() || ''
  const obj2String =
    (typeof obj2 === 'string' ? obj2 : JSON.stringify(obj2, null, 2))?.trim() || ''
  // 末尾同士の `}` と `{` を 正規表現で削除して、結合する
  return (
    obj1String.replace(/\}$/, '').trim() + ',\n  ' + obj2String.replace(/^\{/, '').trim()
  )
}
