import { ColumnDefByColName } from './ModelDef'

export const getModelLabelByModelName = (modelName): string =>
  globalThis.$core.$models[modelName] && globalThis.$core.$models[modelName].tableLabel
    ? globalThis.$core.$models[modelName].tableLabel
    : modelName

// @20210318 Daisuke: excel import で利用しているが、beforeSave でも使ったほうが良いかな?
const trueableValues = [true, 'true', 1, '1', 'はい']
const castFuncByColType = {
  BOOLEAN: (val) =>
    typeof val === 'string'
      ? trueableValues.indexOf(val.toLowerCase()) >= 0
      : val == 1 || !!val,
  NUMBER: (val) => (val ? Number(val) : val),
}

export const castValuesWithColumnType = (
  row: { [colName: string]: any },
  columnDefs: ColumnDefByColName,
): any => {
  // Cast values based on columns[colName].type
  return Object.keys(row).reduce((res: any, colName: string) => {
    const colDef = columnDefs[colName]
    const colType = colDef && colDef.type ? colDef.type : null
    let val = row[colName]
    if (
      colType === 'ARRAY_OF_OBJECT' &&
      colDef.columns &&
      val &&
      Array.isArray(val) &&
      val.length
    ) {
      res[colName] = val.map((childRow) =>
        // @ts-ignore
        castValuesWithColumnType(childRow, colDef.columns),
      )
      return res
    }
    val = colType && castFuncByColType[colType] ? castFuncByColType[colType](val) : val
    res[colName] = val
    return res
  }, {})
}

/**
 * 条件式の表現
 */
export type conditionalExpressions = [
  {
    blocks: [
      {
        propName: string
        relationalOperator: '===' | '!==' | '>' | '>=' | '<' | '<='
        threshold: string
        logicalOperator: 'ならば' | 'and' | 'or'
        returnValue?: string
      },
    ]
  },
]

const LogicalOperator = {
  ならば: 'return',
  and: '&&',
  or: '||',
}

/**
 * 条件式ビルダの設定から条件式を評価する関数を生成する
 * @param expressions 条件式表現
 * @param defaultValue デフォルト値
 */
export const generateConditionalExpressionFunc = (
  expressions: conditionalExpressions,
  defaultValue: string,
): Function => {
  let funcStr = ''
  for (const expression of expressions) {
    let ifBlock = 'if( '
    let isFirst = true
    for (const expElem of expression.blocks) {
      const expLater =
        expElem.relationalOperator !== '===' && expElem.relationalOperator !== '!=='
          ? Number(expElem.threshold)
          : `'${expElem.threshold}'`
      let conditionStr = ''
      // 空文字でないことを条件に追加する
      if (isFirst) {
        conditionStr += `properties['${expElem.propName}'] !== '' && `
        isFirst = false
      }
      conditionStr += `properties['${expElem.propName}'] ${expElem.relationalOperator} ${expLater}`
      if (expElem.logicalOperator !== 'ならば') {
        conditionStr += ` ${LogicalOperator[expElem.logicalOperator]} `
        ifBlock += `${conditionStr} `
      } else {
        ifBlock += `${conditionStr}) { return '${expElem.returnValue}'; } `
      }
    }
    funcStr += ifBlock
  }
  funcStr += ` return '${defaultValue}';`
  return new Function('properties', funcStr)
}
