/**
 * 個別的なfilterItemの設定を行う
 */
import { reactive } from 'vue'
import { getColumnDefsWithNestedColumnNames } from '../../../common/$models/getColumnDefsWithNestedColumnNames'
import {
  ColumnDef,
  ColumnType,
  ColumnTypes,
  ComponentResolver,
} from '../../../common/$models/ModelDef'
import { IconTypes } from '../../../front/Icons/allIcons'
import { fetchSelectionsWithColDef } from '../../../front/ModelForm'
import { FilterControlsService } from './FilterControlsService'
import { FilterGroupService } from './FilterGroupService'
import {
  FILTER_OPERATOR_FOR_BOOLEAN,
  FILTER_OPERATOR_FOR_DATE,
  FILTER_OPERATOR_FOR_NUMBER,
  FILTER_OPERATOR_FOR_SELECT,
  FILTER_OPERATOR_FOR_STRING,
  FilterType,
  TYPICAL_NUMBER_OPERATOR,
} from './FilterRuleService'

/**
 * normal: 通常の FilterItem であるという意味
 * custom: "高度なフィルタ" 内の FilterItem であるという意味
 */
export type FilterItemType = 'normal' | 'custom'

export type CascaderOptionItem = {
  label: string
  value: string
  children?: CascaderOptionItem[]
}
export type CascaderOptions = CascaderOptionItem[]

// ColumnのtypeによってFiconの種類を変える
// PropType<IconTypes> は、IconTypesの型を持つPropTypeを作る
export const getFilterIcon = (colDef: ColumnDef): IconTypes => {
  const type = colDef?.type || ''
  const isSelect = !!(
    colDef.selections ||
    colDef.selectionsWithSelectOptionsMasterGroupName ||
    colDef.selectionsWithColNameFacet ||
    type === ColumnTypes.MultiSelect
  )
  if (isSelect) {
    return 'list-ul'
  }
  if (
    type === ColumnTypes.String ||
    type === ColumnTypes.Text ||
    type === ColumnTypes.RichText
  ) {
    return 'pen'
  }
  if (
    type === ColumnTypes.Number ||
    type === ColumnTypes.Float ||
    type === ColumnTypes.BigInteger ||
    type === ColumnTypes.Decimal ||
    type === ColumnTypes.Double
  ) {
    return 'digital-tachograph'
  }
  if (type === ColumnTypes.DateOnly || type === ColumnTypes.Datetime) {
    return 'calendar-day'
  }
  if (type === ColumnTypes.Boolean) {
    return 'check'
  }
  return 'filter'
}

const filterCTypes = Object.freeze({
  string: {
    normal: 'FilterItemString',
    custom: 'FilterItemStringForCustom',
  },
  number: {
    normal: 'FilterItemNumber',
    custom: 'FilterItemNumberForCustom',
  },
  select: {
    normal: 'FilterItemSelect',
    custom: 'FilterItemSelectForCustom',
  },
  date: {
    normal: 'FilterItemDate',
    custom: 'FilterItemDateForCustom',
  },
  boolean: {
    normal: 'FilterItemBoolean',
    custom: 'FilterItemBooleanForCustom',
  },
  // colType === ColumnTypes.Select || colType === ColumnTypes.MultiSelect
})
export const filterComponentByColumnType: {
  [colType in ColumnType]: { normal: string; custom: string }
} = {
  NUMBER: filterCTypes.number,
  FLOAT: filterCTypes.number,
  BIGINTEGER: filterCTypes.number,
  DECIMAL: filterCTypes.number,
  DOUBLE: filterCTypes.number,
  STRING: filterCTypes.string,
  FILE: filterCTypes.string,
  FILEUPLOAD: filterCTypes.string,
  TEXT: filterCTypes.string,
  UUID: filterCTypes.string,
  SELECT: filterCTypes.select,
  MULTISELECT: filterCTypes.select,
  REFERENCE: filterCTypes.string,
  RICHTEXT: filterCTypes.string,
  DATEONLY: filterCTypes.date,
  BOOLEAN: filterCTypes.boolean,
  ARRAY_OF_OBJECT: null,
  JSON: null,
  CONDITIONAL_EXPRESSION: null,
  DATETIME: filterCTypes.date,
  TIME: filterCTypes.number,
  RELATIONSHIP_MANY_TO_ANY: null,
  RELATIONSHIP_MANY_TO_ONE: null,
  RELATIONSHIP_ONE_TO_MANY: null,
}

/**
 * カラム名の配列から、カラムのラベルの配列を取得する
 * options が ネストされている場合にも対応している
 */
export const getColLabels = (colNames: string[], options: CascaderOptions): string[] => {
  const labels = []
  colNames.reduce((_options: CascaderOptions, colName) => {
    const option = _options.find((option) => option.value === colName)
    if (option) {
      labels.push(option.label || option.value)
    }
    return option?.children || []
  }, options)
  return labels
}

export type FilterItemServiceConstructorOptions = {
  filterItemType: FilterItemType
  filterControlsService: FilterControlsService
  filterGroupService: FilterGroupService
  isFirstObject: boolean
  isSecondObject: boolean
  isFocus: boolean
  columnNamePathsFromRootColumnDef: string[]
}

type ColumnDefWithKey = ColumnDef & { key: string }

/**
 * # FilterItemService
 * - 特定カラムに対する 検索条件状態の保持と関連する算出, 操作を行う
 *
 * ## 責任範囲
 * - value にて 検索条件の状態を保持する
 * - columnNamePathsFromRootColumnDef を受け取り, ColumnDef を ($core.$models から) 算出する
 *
 */
export class FilterItemService<T extends FilterType | null> {
  public initialized = false
  public filterItemType: FilterItemType = 'normal'
  public value: { colName: string; operator: string; value: any } = reactive(null)
  FilterControlsService: FilterControlsService
  FilterGroupService: FilterGroupService
  public isFirstObject = false
  public isSecondObject = false
  public objectType = 'rule'
  public columnNamePathsFromRootColumnDef: string[] = []

  get columnNamePathDotConnected() {
    return this.columnNamePathsFromRootColumnDef.join('.')
  }

  // public columnLabelPathsFromRootColumnDef: string[] = []
  public isFocus = false

  constructor({
    columnNamePathsFromRootColumnDef,
    filterItemType,
    filterControlsService,
    filterGroupService,
    isFirstObject,
    isSecondObject,
    isFocus,
  }: FilterItemServiceConstructorOptions) {
    // this.colDef = colDef
    this.columnNamePathsFromRootColumnDef = columnNamePathsFromRootColumnDef
    if (!this.columnNamePathsFromRootColumnDef?.length) {
      throw new Error('[FilterItemService] columnNamePathsFromRootColumnDef is required')
    }
    this.filterItemType = filterItemType
    this.FilterControlsService = filterControlsService
    this.FilterGroupService = filterGroupService
    this.isFirstObject = isFirstObject
    this.isSecondObject = isSecondObject
    this.isFocus = isFocus
  }

  /**
   * カラム定義は this.columnNamePathsFromRootColumnDef によって 辿って算出される
   */
  get colDefsBasedOnColumnNamePaths(): ColumnDefWithKey[] {
    return getColumnDefsWithNestedColumnNames(
      this.columnNamePathsFromRootColumnDef,
      this.FilterControlsService.columns,
    ) as ColumnDefWithKey[]
  }

  get columnLabelPathsFromRootColumnDef(): string[] {
    return this.colDefsBasedOnColumnNamePaths.map((colDef) => colDef.label || colDef.name)
  }

  /**
   * ここでいう colDef は nest した columnNamePathsFromRootColumnDef の最後の colDef となる
   */
  get colDef(): ColumnDefWithKey {
    const columns = this.colDefsBasedOnColumnNamePaths
    return columns?.[columns.length - 1]
  }

  updateFocus(isFocus: boolean) {
    this.isFocus = isFocus
  }

  get icon(): IconTypes {
    return getFilterIcon(this.colDef)
  }

  get hasValue(): boolean {
    return !!this.value
  }

  /**
   * TODO. FilterItemDateの場合には、operatorがrelationの場合には特殊なので、別途Operatorのラベルを作成する
   */
  get formatOperatorForDate(): string {
    // operatorがrelationの場合
    if (this.value.operator === 'relative') {
      // 値が配列の場合
      if (Array.isArray(this.value.value)) {
        return 'の期間内'
      } else {
        return 'と一致'
      }
    }
    return FILTER_OPERATOR_FOR_DATE[this.value.operator]
  }

  get formatOperator(): string {
    if (
      this.hasValue &&
      (this.value.value ||
        this.value.operator === 'isnull' ||
        this.value.operator === 'isnotnull')
    ) {
      if (this.component === 'FilterItemDate') {
        return this.formatOperatorForDate
      } else if (this.component === 'FilterItemSelect') {
        if (this.colDef.type === 'MULTISELECT' && this.value.operator === 'or') {
          // multiSelectの場合
          const colName = this.value.colName
          if (this.value.value.length > 0) {
            const realOperator =
              Object.keys(this.value.value[0][colName])[0] === '_contains' ? 'in' : 'nin'
            return FILTER_OPERATOR_FOR_SELECT[realOperator]
          } else {
            return ''
          }
        } else {
          return FILTER_OPERATOR_FOR_SELECT[this.value.operator]
        }
      } else if (this.component === 'FilterItemBoolean') {
        return FILTER_OPERATOR_FOR_BOOLEAN[this.value.operator]
      } else if (this.component === 'FilterItemNumber') {
        return FILTER_OPERATOR_FOR_NUMBER[this.value.operator]
      } else if (this.component === 'FilterItemString') {
        return FILTER_OPERATOR_FOR_STRING[this.value.operator]
      }
    } else {
      return ''
    }
  }

  /**
   * MultiSelectの場合には、valueを整形する
   * @param value
   */
  formatValueForMultiSelect(value) {
    const colName = this.value.colName
    const formattedValue = value.map((item) => {
      return Object.values(item[colName])[0]
    })
    if (formattedValue.length > 2) {
      return `"${formattedValue.join('、')}"`
    } else {
      if (formattedValue.length === 0) {
        return ''
      } else {
        return `"${formattedValue.join('、')}"`
      }
    }
  }

  get formatValue() {
    if (this.hasValue) {
      const value = this.value.value
      if (value) {
        if (this.value.operator === 'or') {
          // 特集なケースでmultiselectの検索条件をorでつなげる場合があるので
          return this.formatValueForMultiSelect(value)
        } else {
          if (Array.isArray(value)) {
            if (value.length > 2) {
              return `"${value.join('、')}"`
            } else {
              if (
                this.value.operator === 'between' ||
                this.value.operator === 'relative'
              ) {
                return `"${value.join('〜')}"`
              } else {
                return `"${value.join('、')}"`
              }
            }
          } else {
            return `"${value}"`
          }
        }
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  get component(): string | ComponentResolver {
    if (this.colDef.searchInputComponent) {
      return this.colDef.searchInputComponent
    }
    const colType = this.colDef.type
    const normalOrCustom = this.filterItemType === 'normal' ? 'normal' : 'custom'
    if (
      colType === ColumnTypes.String &&
      (this.colDef.selections ||
        this.colDef.selectionsWithSelectOptionsMasterGroupName ||
        this.colDef.selectionsWithColNameFacet)
    ) {
      return filterCTypes.select[normalOrCustom]
    }
    return filterComponentByColumnType[colType]?.[normalOrCustom] || null
  }

  get displayLabel() {
    if (this.columnLabelPathsFromRootColumnDef?.length) {
      return this.columnLabelPathsFromRootColumnDef.join(' > ')
    }
    return this.colDef?.label || this.colDef?.name
  }

  removeFilter() {
    if (this.filterItemType === 'normal') {
      this.FilterControlsService.removeFilter(this.columnNamePathDotConnected)
    } else if (this.filterItemType === 'custom') {
      this.FilterGroupService.filterObjects.splice(
        this.FilterGroupService.filterObjects.indexOf(this),
        1,
      )
      this.refreshOrder()
    }
    this.FilterControlsService.refreshFilter()
  }

  refreshOrder() {
    this.FilterGroupService.filterObjects.forEach((filterObject, index) => {
      if (index === 0) {
        filterObject.isFirstObject = true
        filterObject.isSecondObject = false
      } else if (index === 1) {
        filterObject.isFirstObject = false
        filterObject.isSecondObject = true
      } else {
        filterObject.isFirstObject = false
        filterObject.isSecondObject = false
      }
    })
  }

  get isNumberTypicalOperator(): boolean {
    return (
      this.colDef.type === 'NUMBER' &&
      TYPICAL_NUMBER_OPERATOR.includes(this.value.operator)
    )
  }

  duplicateFilter() {
    if (this.filterItemType === 'custom') {
      const isFirst = this.FilterGroupService.filterObjects.length == 0
      const isSecond = this.FilterGroupService.filterObjects.length == 1
      const filterItemService = new FilterItemService({
        columnNamePathsFromRootColumnDef: this.columnNamePathsFromRootColumnDef,
        filterItemType: 'custom',
        filterControlsService: this.FilterControlsService,
        filterGroupService: this.FilterGroupService,
        isFirstObject: isFirst,
        isSecondObject: isSecond,
        isFocus: false,
      })
      this.FilterGroupService.filterObjects.splice(
        this.FilterGroupService.filterObjects.indexOf(this) + 1,
        0,
        filterItemService,
      )
    }
    this.FilterControlsService.refreshFilter()
  }

  updateValue(value: T) {
    // this.valueを更新する
    if (
      !value.value ||
      value.value == '' ||
      (Array.isArray(value.value) && value.value.length == 0)
    ) {
      this.value = {
        ...this.value,
        ...value,
        colName: this.colDef.name || this.colDef.key,
      }
      this.FilterControlsService.refreshFilter()
      return
    }
    // value.valueが単一の値の場合にはfocusを外す かつ value.valueが変更がある場合にはfocusを外す
    if (!Array.isArray(value.value) && this.value?.value !== value.value) {
      // operatorがrelativeの場合には特殊なケースとして閉じらないようにする
      if (value?.operator !== 'relative') {
        this.isFocus = false
      }
    }
    this.value = {
      ...this.value,
      ...value,
      colName: this.colDef.name || this.colDef.key,
    }
    this.FilterControlsService.refreshFilter()
  }

  updateItemOperator(operator) {
    // this.valueのoperatorを更新する
    // もしoperatorがisnullとかisnotnullの場合は、valueのvalueをnullにする
    if (operator === 'isnull' || operator === 'isnotnull') {
      this.value = {
        ...this.value,
        operator: operator,
        value: null,
        colName: this.colDef.name,
      }
    } else {
      // typeがMultiSelectの場合には別途処理する
      if (this.colDef.type === 'MULTISELECT') {
        const value = this.value.value.map((item) => {
          const temp = item[this.colDef.name]
          if (operator === 'in') {
            return {
              [this.colDef.name]: {
                _contains: Object.values(temp)[0],
              },
            }
          } else {
            return {
              [this.colDef.name]: {
                _ncontains: Object.values(temp)[0],
              },
            }
          }
        })
        this.value = {
          ...this.value,
          value: value,
          operator: 'or',
          colName: this.colDef.name,
        }
      } else {
        this.value = {
          ...this.value,
          operator: operator,
          colName: this.colDef.name,
        }
      }
    }

    this.FilterControlsService.refreshFilter()
  }

  async getSelections() {
    let list = []
    try {
      const selec = await fetchSelectionsWithColDef({
        modelName: this.FilterControlsService.modelName,
        colDef: this.colDef,
      })
      return selec
    } catch (e) {
      if (typeof this.colDef.selections === 'function') {
        list = await this.colDef.selections({})
      } else if (this.colDef.selectionsWithSelectOptionsMasterGroupName) {
        try {
          list = (
            await $core.$models.selectOptionsMaster.find({
              filter: {
                group: {
                  _eq: this.colDef.selectionsWithSelectOptionsMasterGroupName,
                },
              },
            })
          ).map((d) => d.value)
        } catch (e) {
          console.error(e)
        }
      }
      return list
    }
  }

  formatDate(date: string) {
    if (this.colDef.type === 'DATEONLY') {
      return $core.$dayjs(date).format('YYYY-MM-DD')
    } else {
      return $core.$dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }

  calcOneDateFromType(type: string) {
    // $core.$dayjsを利用しつつFormatもするYYYY-MM-DDで
    const dayjs = $core.$dayjs
    const today = dayjs().format('YYYY-MM-DD')
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
    const oneWeekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
    const oneWeekFromNow = dayjs().add(1, 'week').format('YYYY-MM-DD')
    const oneMonthAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    const oneMonthFromNow = dayjs().add(1, 'month').format('YYYY-MM-DD')
    switch (type) {
      case 'today':
        return today
      case 'yesterday':
        return yesterday
      case 'tomorrow':
        return tomorrow
      case 'one_week_ago':
        return oneWeekAgo
      case 'one_week_from_now':
        return oneWeekFromNow
      case 'one_month_ago':
        return oneMonthAgo
      case 'one_month_from_now':
        return oneMonthFromNow
    }
  }

  formatDateFromRelation(dateRelationType, dateRelationPeriodType, relationPeriod) {
    const dayjs = $core.$dayjs
    const today = dayjs().format('YYYY-MM-DD')
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
    // 今週期間
    const thisWeekStart = dayjs().startOf('week').format('YYYY-MM-DD')
    const thisWeekEnd = dayjs().endOf('week').format('YYYY-MM-DD')
    // 前の週間の開始日と終了日の配列
    const pastWeekStart = dayjs()
      .subtract(relationPeriod, 'week')
      .startOf('week')
      .format('YYYY-MM-DD')
    const pastWeekEnd = dayjs()
      .subtract(relationPeriod, 'week')
      .endOf('week')
      .format('YYYY-MM-DD')
    // 次の週間
    const nextWeekStart = dayjs()
      .add(relationPeriod, 'week')
      .startOf('week')
      .format('YYYY-MM-DD')
    const nextWeekEnd = dayjs()
      .add(relationPeriod, 'week')
      .endOf('week')
      .format('YYYY-MM-DD')
    // 今月
    const thisMonthStart = dayjs().startOf('month').format('YYYY-MM-DD')
    const thisMonthEnd = dayjs().endOf('month').format('YYYY-MM-DD')
    // 前月間
    const pastMonthStart = dayjs()
      .subtract(relationPeriod, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')
    const pastMonthEnd = dayjs()
      .subtract(relationPeriod, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
    // 次月間
    const nextMonthStart = dayjs()
      .add(relationPeriod, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')
    const nextMonthEnd = dayjs()
      .add(relationPeriod, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
    const thisYearStart = dayjs().startOf('year').format('YYYY-MM-DD')
    const thisYearEnd = dayjs().endOf('year').format('YYYY-MM-DD')
    // 前年間
    const pastYearStart = dayjs()
      .subtract(relationPeriod, 'year')
      .startOf('year')
      .format('YYYY-MM-DD')
    const pastYearEnd = dayjs()
      .subtract(relationPeriod, 'year')
      .endOf('year')
      .format('YYYY-MM-DD')
    // 次年間
    const nextYearStart = dayjs()
      .add(relationPeriod, 'year')
      .startOf('year')
      .format('YYYY-MM-DD')
    const nextYearEnd = dayjs()
      .add(relationPeriod, 'year')
      .endOf('year')
      .format('YYYY-MM-DD')
    switch (dateRelationType) {
      case 'this':
        switch (dateRelationPeriodType) {
          case 'day':
            return today
          case 'week':
            return [thisWeekStart, thisWeekEnd]
          case 'month':
            return [thisMonthStart, thisMonthEnd]
          case 'year':
            return [thisYearStart, thisYearEnd]
        }
      // eslint-disable-next-line no-fallthrough
      case 'past':
        switch (dateRelationPeriodType) {
          case 'day':
            return yesterday
          case 'week':
            return [pastWeekStart, pastWeekEnd]
          case 'month':
            return [pastMonthStart, pastMonthEnd]
          case 'year':
            return [pastYearStart, pastYearEnd]
        }
      // eslint-disable-next-line no-fallthrough
      case 'next':
        switch (dateRelationPeriodType) {
          case 'day':
            return tomorrow
          case 'week':
            return [nextWeekStart, nextWeekEnd]
          case 'month':
            return [nextMonthStart, nextMonthEnd]
          case 'year':
            return [nextYearStart, nextYearEnd]
        }
    }
  }

  updateOperator(operator) {
    this.FilterGroupService.filterLogic = operator
    this.FilterControlsService.refreshFilter()
  }

  selectColumn(colNamePath: string[] = []) {
    this.columnNamePathsFromRootColumnDef = colNamePath
    // this.valueのcolNameを更新する
    this.value = {
      ...this.value,
      colName: this.colDef.name,
      value: undefined,
    }
    this.FilterControlsService.refreshFilter()
  }
}
