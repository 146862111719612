import { $virtualModelsLoader } from '../$virtualModels'
import {
  registerFunctionLoggedInExecuteOnce,
  tryParseAsObject,
  unFlattenObject,
} from '../../common/utils'
import {
  columnDefOnLoadOtherColAttributesDeepMerge,
  modelDefConvertFunctionDefinitionPropsToFunction,
} from '../modelDefinitions/modelDefinitionsLoaderService'
import { parseValidationConfigIntoColumnDefValidateProp } from '../modelDefinitions/validationConfigs'
import { formatVirtualModelDefinitionDataWithOverrideAttrs } from './virtualModelDefinitions'

export class VirtualModelDefinitionsLoaderService {
  constructor() {
    // 初期化された時点で、ログイン後に実行される関数を登録
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'VirtualModelDefinitionsLoaderService',
      onceLoadFunction: (userData) => {
        if (!userData) {
          return
        }
        return this.loadVirtualModelDefinitions()
      }, // 初回ロード時に実行する関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse)
      hookPriority: 1600,
    })
  }

  async loadVirtualModelDefinitions(modelNames = []) {
    // modelDefinitionsからmodel情報を取得
    const findQuery = {
      limit: -1,
      sort: ['baseModel'],
      filter: modelNames.length === 0 ? {} : { baseModel: { _in: modelNames } },
    }
    try {
      const virtualModelDefinitions =
        await $core.$models.virtualModelDefinitions.find(findQuery)
      // ModelDefの型に整形
      for (const unformattedModelDefinition of virtualModelDefinitions) {
        // overrideAttrs を 適用, overrideable な Model Props の場合に override 対象になっていなければ props を delete する
        const unformattedModelDefinitionTrimed =
          formatVirtualModelDefinitionDataWithOverrideAttrs(unformattedModelDefinition)
        unformattedModelDefinitionTrimed.isDefinedOnDb = true
        // $core.$modelsLoaderを利用してmodelをロード
        const formattedModelDefinition = formatImportableModel(
          unformattedModelDefinitionTrimed,
        )
        await $virtualModelsLoader.loadModel(formattedModelDefinition)
      }
    } catch (e) {
      console.error(
        `[virtualModelDefinitionsLoaderService.ts] Failed to load virtualModelDefinitions. There might not exists the table, if so you should run "yarn syncModels --models=virtualModelDefinitions" e.message: ${e.message}`,
      )
    }
  }
}

const parseAsJavascriptObjectFieldNamesInEachColumn = ['inputAttrs', 'dataFilters']

/**
 * 編集用のmodelを正しいmodelの形に修正
 */
export const formatImportableModel = (model) => {
  // _で繋がれたkeyをObjectに
  model = unFlattenObject(model)
  const tableLogDisplayName = model.baseModel
    ? `VirtualModel: "${model.name}" (baseModel: "${model.baseModel}")`
    : model.tableName
  const baseModel = $core.$models[model.baseModel]
  // formColumnGroupsAsArray を formColumnGroups に変換
  if (model.formColumnGroupsAsArray?.length > 0) {
    model.formColumnGroups = model.formColumnGroupsAsArray.reduce(
      (r, { key, ...group }) => {
        r[key] = group
        return r
      },
      {},
    )
  }

  // map column
  const columns = model.columns.reduce((r, { key, ...column }) => {
    // if (!baseModel || baseModel.columns![key]) {
    //
    // }
    // selectionsWithColNameFacet
    if (column.selectionsWithColNameFacet) {
      column.selectionsWithColNameFacet = key
    }

    // _で繋がれたkeyをObjectに
    column = unFlattenObject(column)
    // inputAttrsのparse
    for (let i = 0; parseAsJavascriptObjectFieldNamesInEachColumn.length > i; i++) {
      const fieldName = parseAsJavascriptObjectFieldNamesInEachColumn[i]
      if (column[fieldName]) {
        try {
          column[fieldName] = tryParseAsObject(column[fieldName])
        } catch (e) {
          console.warn(
            `[VirtualModelDefinitionsLoaderService] Failed to parse "${fieldName}" in importableModel[${tableLogDisplayName} - ${key}]`,
            e,
          )
        }
      }
    }
    // Validation定義のparse
    if (column.validationConfigs && column.validationConfigs.length) {
      const parsedValidate = parseValidationConfigIntoColumnDefValidateProp(
        column.validationConfigs,
      )
      column.validate = { ...(column.validate || {}), ...parsedValidate }
    }
    if (column.otherColAttributes) {
      try {
        const otherAttrs = tryParseAsObject(column.otherColAttributes)
        // merge
        column = columnDefOnLoadOtherColAttributesDeepMerge(column, otherAttrs)
      } catch (e) {
        const msg = `[VirtualModelDefinitionsLoaderService] Failed to parse "otherColAttributes" in importableModel[${tableLogDisplayName} - ${key}]`
        console.warn(msg, e)
        // 管理者の場合にのみwarn
        setTimeout(() => {
          if (globalThis.$core?.$embAuth?.user?.isAdmin) {
            globalThis.$core.$toast.warningToast(msg)
          }
        }, 1000)
      }
    }
    r[key] = column
    return r
  }, {})

  model.columns = columns
  model = _convertDefinitionToFunction(model)
  if (model.otherModelAttributes) {
    try {
      const parsedOtherModelAttrs = tryParseAsObject(model.otherModelAttributes)
      // merge
      model = {
        ...model,
        ...parsedOtherModelAttrs,
      }
    } catch (e) {
      console.error(
        `[VirtualModelDefinitionsLoaderService] failed to parse "otherModelAttributes" in importableModel[${tableLogDisplayName}]`,
        e,
      )
    }
  }
  return model
}

/**
 * Function定義をevalを利用して挙動へ変換
 * beforeSaveFunctionDef => async beforeSave(row, beforeRow)
 * @param model
 * @private
 */
const _convertDefinitionToFunction = (model) =>
  modelDefConvertFunctionDefinitionPropsToFunction(model)

export const $virtualModelDefinitionsLoaderService = () =>
  new VirtualModelDefinitionsLoaderService()
