import { ModelDef } from '../../types'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'

const modelName = 'selectOptionsMaster'
addModelDoc(
  modelName,
  '選択肢マスタは、各カラムの選択肢を簡易的に管理するためのマスタです。モデル定義 > カラム設定 内の "選択肢マスタ指定" でグループ名を指定することで、このマスタの値を選択肢として利用することが可能です。',
)

namespace Models {
  export interface SelectOptionsMaster {
    group: string
    value: string
    category: string
    sort: number
  }
}

export const selectOptionsMaster: ModelDef = {
  bulkEditable: true,
  columns: {
    group: {
      label: 'グループ',
      type: 'STRING',
      unique: false,
      validate: {
        notEmpty: true,
      },
      selectionsWithColNameFacet: 'group',
      strictSelections: false,
      dynamicSelections: true,
      facet: { showOnSearch: true },
    },
    value: {
      label: '値',
      type: 'STRING',
      unique: {
        conditions: (record: Models.SelectOptionsMaster) => ({
          group: {
            _eq: record.group,
          },
        }),
      },
      validate: {
        notEmpty: true,
      },
      facet: true,
    },
    category: {
      label: '中分類',
      type: 'STRING',
      facet: { showOnSearch: true },
    },
    sort: {
      defaultValue: 0,
      label: '表示順(昇順)',
      type: 'NUMBER',
      unique: false,
      validate: {
        isInt: true,
      },
    },
  },
  tableComment: 'XXX',
  tableLabel: '選択肢マスタ',
  tableName: modelName,
  defaultSort: ['group', 'sort'],
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  extensions: [
    {
      key: 'defaultCachedFind',
      extensionType: 'cachedFindable',
      enabled: true,
      cacheTTLSeconds: 60 * 5, // キャッシュの有効期限 (秒)
      purgeCacheOnUpdate: true, // 更新時にキャッシュをパージするかどうか
    }
  ]
}
