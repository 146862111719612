<template>
  <nav
    v-if="initialized"
    id="sidebar"
    class="sidebar sidebar-offcanvas position-fixed"
    :class="[
      $core.$uiState.isSidebarActiveOnMobile ? 'active' : '',
      sidebarDisplayStatus ? '' : 'shrink',
    ]"
  >
    <ul class="nav">
      <li>
        <div
          class="nav-link d-flex align-items-center"
          :style="{
            backgroundColor: 'rgba(0, 0, 0, 0.52)',
            position: 'relative',
            height: '53px',
            padding: 0,
          }"
        >
          <div
            class="core-app-header-logo"
            :style="{
              flex: '1 1 auto',
            }"
          >
            <CoreAdminAppLogo
              :style="{
                maxWidth: '100%',
                height: '30px',
                marginLeft: '10px',
                textAlign: 'center',
              }"
            />
          </div>
          <div
            v-single-click="() => toggleSidebar()"
            class="toggel_button"
            :style="{}"
          >
            <ficon
              type="angle-double-left"
              class="small"
            />
          </div>
        </div>
      </li>
    </ul>
    <hr class="mt-0" />
    <MenuItemKeywordSearch
      :appMenus="adminAppMenu"
      ref="menuItemKeywordSearch"
    />
    <ul
      class="nav"
      v-show="!searchString"
    >
      <AppMenuItemList
        v-for="(menuItem, index) in adminAppMenu"
        :key="`admin-menu-${index}`"
        :menuItem="menuItem"
      />
    </ul>
    <app-hookable-component
      v-show="!searchString"
      resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.after"
    />
    <ul
      class="nav"
      v-show="!searchString"
    >
      <li class="nav-item nav-category font-weight-bold mt-4">
        ログイン中: {{ name }} さん
      </li>
      <app-hookable-component
        resolve-hook-name="$CORE.admin.resolveComponent.sidebar.nav.afterUserName"
      />
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          @click.prevent="() => $core.$utils.fireOnce(logout)()"
          >ログアウト</a
        >
      </li>
    </ul>
  </nav>
</template>
<script lang="ts">
import { generateSidebarLinksToOtherApps } from '../../../front/AppMenu/generateSidebarLinksToOtherApps'
import AppMenuItemList from '../../AppMenu/AppMenuItemList.vue'
import { generateAdminAppMenu } from '../../AppMenu/generateAdminAppMenu'
import CoreAdminAppLogo from './CoreAdminAppLogo.vue'
import MenuItemKeywordSearch from './MenuItemKeywordSearch.vue'

export default {
  components: { AppMenuItemList, CoreAdminAppLogo, MenuItemKeywordSearch },
  props: {
    initialSidebarDisplayStatus: { default: true, type: Boolean },
  },
  data() {
    return {
      navLinks: [],
      initialized: false,
      appLinks: [],
      adminAppMenu: [],
      searchString: '',
    }
  },
  computed: {
    sidebarDisplayStatus: {
      get() {
        return $core.$uiState.isSidebarOpened
      },
      set(value: boolean) {
        $core.$uiState.isSidebarOpened = !!value
      },
    },
    name() {
      const user = $core.$embAuth.user
      return (user || {}).email || ''
    },
  },
  watch: {
    // 更新したらメニューをReload
    '$core.$uiState.latestModelDefinitionLoadedTime'(newVal, oldVal) {
      this.reload()
    },
  },
  async mounted() {
    // Wait until
    await $core.$appDefinitionLoader.loggedInExecuteOnceServiceBaseInstance.initPromise
    this.appLinks = generateSidebarLinksToOtherApps({ skipAdminAppLink: true })
    await this.generateAdminMenu()
    if (this.initialSidebarDisplayStatus !== $core.$uiState.isSidebarOpened) {
      $core.$uiState.isSidebarOpened = this.initialSidebarDisplayStatus
    }
    this.initialized = true
  },
  methods: {
    async logout() {
      if (
        (await $core.$toast.confirmDialog(`ログアウトします、よろしいですか？`, {
          okVariant: 'success',
        })) === false
      ) {
        return
      }
      await $core.$embAuth.signOut()
    },
    async generateAdminMenu() {
      this.adminAppMenu = await generateAdminAppMenu()
    },
    async reload() {
      this.initialized = false
      await this.generateAdminMenu()
      this.$nextTick(() => {
        this.initialized = true
      })
    },
    toggleSidebar() {
      $core.$lsCache.set('sidebarDisplayStatus', !this.sidebarDisplayStatus)
      this.sidebarDisplayStatus = $core.$lsCache.get('sidebarDisplayStatus')
      this.$parent.sidebarDisplayStatus = $core.$lsCache.get('sidebarDisplayStatus')
      this.$emit('onToggleSidebar', this.sidebarDisplayStatus)
    },
  },
}
</script>
