import { Directus, DirectusOptions, IDirectus } from '@directus/sdk'
import { singletonInstanceSummoner } from '../singletonInstanceSummoner'

export type DirectusSdkType = IDirectus<Record<string, any>>

const options: DirectusOptions = {
  auth: {
    // CORE管理画面から、iFrameでDirectus側の画面, Node-RED 画面を表示するために必須
    mode:
      globalThis.DIRECTUS_SDK_AUTH_MODE || process.env.DIRECTUS_SDK_AUTH_MODE || 'json',
    staticToken:
      globalThis.DIRECTUS_SDK_STATIC_TOKEN ||
      process.env.DIRECTUS_SDK_STATIC_TOKEN ||
      undefined,
  },
}

/**
 * どこから呼び出しても同じ状態のSDKが返ってくる。
 * UserAuthの状態を保持することもあり、JSのメモリ内ではSingletonでOK
 */
const ins: DirectusSdkType = singletonInstanceSummoner('DirectusSdk', Directus, [
  globalThis.CORE_API_DOMAIN ||
    process.env.DIRECTUS_SERVER_BASE_URL ||
    process.env.PUBLIC_URL ||
    'http://localhost:8055/',
  options,
]) as DirectusSdkType
// TODO: 正しい設定方法がわからない...
// @ts-ignore
export const $directusSdk = ins
