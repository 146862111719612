<template>
  <PageHeader v-if="initialized">
    <h6 class="bold mb-0">
      {{ $core.$dict.get('importDataFromExternal.Title', 'データインポート実行') }}{{
        queriedImportTypeName
          ? $core.$dict.get(
            `importDataFromExternal.Title.${queriedImportTypeName}`,
            `: ${queriedImportTypeName}`,
          )
          : ''
      }}
    </h6>
    <router-link
      v-if="!queriedImportTypeName"
      class="small ml-auto btn btn-outline-secondary import-data-from-external-import-settings-button"
      :to="{
        name: 'fallback_model',
        params: { fallback_model: 'dataImportSettings' },
      }"
      >インポート設定を管理
    </router-link>
  </PageHeader>
  <div class="card import-data-from-external border-top-0">
    <div class="card-body" v-if="initialized">
      <div class="alert alert-danger" v-if="!$core.$models.dataImportSettings">
        $core.$models.dataImportSettings
        が見つかりませんでした。dataImportSettingsを正しくロードしてください。
      </div>
      <span v-if="dataImportSettingNames.length && !queriedImportTypeName">
        {{
          $core.$dict.get('importDataFromExternal.importTargetSelect', 'インポートタイプを選択:')
        }}
        <span class="d-inline-block">
          <b-form-select
            class="form-control-sm"
            v-model="selectedImportType"
            :options="['', ...dataImportSettingNames]"
          />
        </span>
      </span>
      <span v-if="errorMessage">{{ errorMessage }}</span>
      <div v-show="selectedImportType" class="mt-3">
        <div v-if="selectedImportType && selectedDataImportSettingRecord">
          <p
            v-if="
              selectedDataImportSettingRecord.excludeFields &&
                selectedDataImportSettingRecord.excludeFields.length
            "
          >
            取込対象外カラム名:
            <span
              class="badge badge-secondary mr-1"
              v-for="ex in selectedDataImportSettingRecord.excludeFields"
              >{{ ex }}</span
            >
          </p>
          <!-- <pre>selectedDataImportSettingRecord: {{ selectedDataImportSettingRecord }}</pre> -->
        </div>
        <UploadExcel
          v-show="!loading"
          :onSuccess="
            ({ results }) => {
              return handleSuccess({ results })
            }
          "
          :letSelectSheetName="true"
          :xlsxReadOptions="xlsxReadOptions"
          :xlsxSheetToJsonOptions="xlsxSheetToJsonOptions"
        />
        <p v-show="loading" class="text-center py-5">
          <Loading>アップロード中...</Loading>
        </p>
        <div v-if="!loading">
          <div
            class="alert alert-danger mt-3 mx-auto"
            style="max-width: 980px;"
            v-if="displayErrorMessages.length"
          >
            <div class="bold">取込エラー:</div>
            <div
              class="pt-1 small bold pl-2"
              v-for="(e, index) in displayErrorMessages"
              :key="index"
            >
              {{ e }}
            </div>
          </div>
          <div
            class="alert alert-warning mt-3 mx-auto"
            style="max-width: 980px;"
            v-if="displayWarningMessages.length"
          >
            <div class="bold">取込ワーニング:</div>
            <div
              class="pt-1 small bold pl-2"
              v-for="(e, index) in displayWarningMessages"
              :key="index"
            >
              {{ e }}
            </div>
          </div>
        </div>
        <div v-if="formattedData && formattedData.length" class="text-center py-3">
          <span
            class="btn btn-success my-3"
            :disabled="!isImportExecutableState"
            @click.prevent="() => executeImport()"
          >
            下記{{ formattedData.length }}行を
            {{ $core.$dict.get('importDataFromExternal.executeImport', 'インポート実行') }}</span
          >
          <a
            href="#"
            v-if="enabledDownloadAsJson"
            class="small my-2 import-data-from-external--downloadAsJson mx-2"
            @click.prevent="() => downloadAsJson()"
            >JSONとしてダウンロード</a
          >
          <b-table
            :items="formattedData"
            :fields="formattedDataFields"
            responsive
            table-class="overflow-ellipsis-wider table-sm table-bordered"
            class="text-nowrap text-left"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ImportServiceWithDataImportSetting } from './importServiceWithDataImportSetting'
import UploadExcel from '../ExcelUpload/UploadExcel.vue'
import { $errorReporter } from '../$libs/$errorReporter/$errorReporter'

export default {
  components: {
    UploadExcel,
  },
  data: () => ({
    dataImportSettings: {}, // {[settingName: string]: any}
    additionalDataImportSettings: {}, // {[settingName: string]: any}
    selectedImportType: '',
    importData: [],
    formattedData: [],
    loading: false,
    initialized: false,
    importServiceIns: null as ImportServiceWithDataImportSetting,
    errorMessage: '',
    enabledDownloadAsJson: $core.$configVars.get(
      'importDataFromExternal.enabledDownloadAsJson',
      false,
    ),
  }),
  computed: {
    formattedDataFields() {
      return this.importServiceIns.formattedDataFields
    },
    displayErrorMessages() {
      return this.importServiceIns?.displayErrorMessages || []
    },
    displayWarningMessages() {
      return this.importServiceIns?.displayWarningMessages || []
    },
    xlsxReadOptions() {
      let option = {}
      if (!this.importServiceIns || !this.importServiceIns.dataImportSettingRecord) {
        return option
      }
      // TODO: do something...
      return option
    },
    xlsxSheetToJsonOptions() {
      let option = {}
      if (!this.importServiceIns || !this.importServiceIns.dataImportSettingRecord) {
        return option
      }
      if (
        this.importServiceIns.dataImportSettingRecord.useDateConversion &&
        this.importServiceIns.dataImportSettingRecord.dateConversionFormat
      ) {
        // @ts-ignore
        option.dateNF = this.importServiceIns.dataImportSettingRecord.dateConversionFormat
      }
      return option
    },
    queriedImportTypeName() {
      return this.$route.query?.importType || this.$route.params?.importType
    },
    dataImportSettingNames() {
      // with remove duplicate
      return Object.keys(this.dataImportSettings)
        .concat(Object.keys(this.additionalDataImportSettings))
        .filter((v, i, a) => a.indexOf(v) === i)
    },
    selectedDataImportSettingRecord() {
      return (
        this.additionalDataImportSettings[this.selectedImportType] ||
        this.dataImportSettings[this.selectedImportType]
      )
    },
    disableImportIfHasValidationErrors() {
      return this.importServiceIns.dataImportSettingRecord.disableImportIfHasValidationErrors
    },
    isImportExecutableState() {
      if (!this.formattedData.length) {
        return false
      }
      if (this.disableImportIfHasValidationErrors && this.displayErrorMessages.length) {
        return false
      }
      return true
    }
  },
  async created() {
    const dataImportSettings = await $core.$models.dataImportSettings.find()
    if ($core.$appHook.hasHook('importDataFromExternal.additionalDataImportSettings')) {
      this.additionalDataImportSettings = await $core.$appHook.emit(
        'importDataFromExternal.additionalDataImportSettings',
        {},
      )
    }
    this.dataImportSettings = dataImportSettings.reduce((res, r) => ({ ...res, [r.name]: r }), {})
    this.init()
  },
  watch: {
    queriedImportTypeName() {
      this.init()
    },
    selectedImportType() {
      // 選択が 切り替わった場合は importServiceIns を初期化
      this.initialized = false
      this.importServiceIns = null
      this.errorMessage = ''
      this.importData = []
      this.formattedData = []
      this.$nextTick(() => {
        this.initialized = true
      })
    }
  },
  methods: {
    init() {
      this.initialized = false
      this.selectedImportType = ''
      this.importData = []
      this.formattedData = []
      if (this.dataImportSettingNames.length === 0) {
        this.errorMessage = 'インポート設定を作成してください'
      } else {
        this.errorMessage = ''
      }
      this.$nextTick(() => {
        this.initialized = true
        if (this.queriedImportTypeName) {
          if (this.dataImportSettingNames.indexOf(this.queriedImportTypeName) === -1) {
            this.errorMessage = `インポート設定名 "${this.queriedImportTypeName}" が見つかりませんでした`
            return
          }
          this.$nextTick(() => {
            this.selectedImportType = this.queriedImportTypeName
          })
        }
      })
    },
    // 1. Import直後
    async handleSuccess({ results }) {
      this.importServiceIns = new ImportServiceWithDataImportSetting(
        this.selectedImportType,
        { useUnflatten: true },
        this.selectedDataImportSettingRecord,
      )
      await this.importServiceIns.reformatDataWithImportSettings(results)
      this.formattedData = this.importServiceIns.formattedDataRows
      this.displayErrors = this.importServiceIns.errors
    },
    // 2. Save 実行
    async executeImport() {
      if (this.loading || !this.selectedImportType || this.formattedData.length === 0) {
        return
      }
      $core.$loading.start('インポート中...', 'overlay')
      this.loading = true
      await this.$nextTick()
      try {
        await this.importServiceIns.saveCurrentTargets()
        $core.$toast.successToast(
          `"${this.selectedImportType}" ${this.formattedData.length}件を保存しました。`,
        )
        // clear state
        this.formattedData = []
        this.importServiceIns = null
      } catch (e) {
        $errorReporter.r(e, this)
        console.error(e)
        $core.$toast.errorToast(`保存できませんでした。 ${e.message}`)
      } finally {
        this.loading = false
        $core.$loading.finish()
      }
    },
    downloadAsJson() {
      $core.$utils.downloadJsValue(this.formattedData, `${this.selectedImportType}.json`)
    },
  },
}
</script>
