<template>
  <app-hookable-component
    v-if="$core.$configVars.get('layout.enableHeader', true)"
    resolve-hook-name="$CORE.admin.resolveComponent.header"
    class="d-block"
  >
    <nav
      style="width: auto"
      class="app-header navbar-header navbar-fixed navbar default-layout col-12 p-0 d-flex flex-row border-bottom"
    >
      <div
        class="navbar-menu-wrapper clearfix w-100"
        style="min-height: 37px"
      >
        <div class="float-left p-2 d-flex">
          <HeaderNavMenu v-if="shouldHeaderNavEnabled" />
          <app-hookable-component
            resolve-hook-name="$CORE.admin.resolveComponent.header.left"
          />
        </div>
        <div class="float-right p-2 mr-2">
          <UserNotificationPopIcon v-if="$core.$userNotificationsService?.enabled" />
          <NavMenuTogglerButton />
          <app-hookable-component
            resolve-hook-name="$CORE.admin.resolveComponent.header.right"
          />
        </div>
      </div>
      <MemoryUsageDisplayPanel />
    </nav>
  </app-hookable-component>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import HeaderNavMenu from './HeaderNavMenu.vue'
import MemoryUsageDisplayPanel from './MemoryUsageDisplayPanel.vue'
import NavMenuTogglerButton from './NavMenuTogglerButton.vue'

export default {
  name: 'AppHeader',
  props: {
    sidebarEnabled: { default: true },
  },
  components: {
    HeaderNavMenu,
    UserNotificationPopIcon: defineAsyncComponent(
      () =>
        import('../../../plugins/UserNotifications/front/UserNotificationPopIcon.vue'),
    ),
    MemoryUsageDisplayPanel,
    NavMenuTogglerButton,
  },
  watch: {
    '$route.fullPath'() {
      $core.$uiState.isSidebarActiveOnMobile = false
    },
  },
  computed: {
    shouldHeaderNavEnabled() {
      if ($core.$appDefinitionLoader.isAdminMode) {
        // Force layout
        return false
      }
      return $core.$configVars.get('layout.enableHeaderNavMenu', !this.sidebarEnabled)
    },
  },
  methods: {
    async logout() {
      if (
        (await $core.$toast.confirmDialog(`ログアウトします、よろしいですか？`, {
          okVariant: 'success',
        })) === false
      ) {
        return
      }
      await $core.$embAuth.signOut()
    },
  },
}
</script>
