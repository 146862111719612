import * as Sentry from '@sentry/vue'
import * as Vue from 'vue'
import { App, createApp } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { $core } from '../$core'
import { $frameworkUtils } from '../$frameworkUtils/$frameworkUtils'
import { $router } from '../$router/$router'
import { $appHook } from '../../common/$appHook'
import { $modelsLoader } from '../../common/$models'
import { ModelDef } from '../../common/$models/ModelDef'
import { $virtualModelsLoader } from '../../common/$virtualModels'
import { VirtualModel } from '../../common/$virtualModels/VirtualModel'
import { useAppTemplatesFeature } from '../../common/appTemplates/useAppTemplatesFeature'
import { fetchAndRegisterFrontSideAppHooks } from '../../common/FrontSideAppHooks/FrontSideAppHooksRegistrator'
import { useModelDefinitionsFeature } from '../../common/modelDefinitions/useModelDefinitionsFeature'
import { loadExternalScript } from '../../common/utils'
import { useVirtualModelDefinitionsFeature } from '../../common/virtualModelDefinitions/useModelDefinitionsFeature'
import { $appDefinitionLoader } from '../../plugins/AppDefinitions/front/AppDefinitionLoaderService'
import { initComposableComponentBuilderFront } from '../../plugins/ComposableComponentBuilder/front/initComposableComponentBuilderFront'
import { $composableComponentSettingsManager } from '../../plugins/ComposableComponents/$composableComponentSettingsManager'
import { initComposableComponents } from '../../plugins/ComposableComponents/initComposableComponents'
import { initComposableDataListComponents } from '../../plugins/ComposableDataListComponents/front/initComposableDataListComponents'
import { initComposableModelFormComponents } from '../../plugins/ComposableModelForm/front/initComposableModelFormComponents'
import { initModelDefinitionsEditor } from '../../plugins/ModelDefinitionsEditor/front/initModelDefinitionsEditor'
import { installFrontRecordCommentsFeature } from '../../plugins/RecordComments/front/installFrontRecordCommentsFeature'
import { installUserDisplays } from '../../plugins/UserDisplays/front/installUserDisplays'
import { $userNotificationsService } from '../../plugins/UserNotifications/front/$userNotificationsService'
import { useUserNotificationsFeature } from '../../plugins/UserNotifications/front/useUserNotificationsFeature'
import { installVueDirectives } from '../../plugins/VueDirectives/installVueDirectives'
import { apiAdminInitializer } from '../ApiAdmin/ApiAdminInitializer'
import { useChartDefinitionsFeature } from '../Chart/useChartDefinitions'
import { useCustomComponentDefinitionsFeature } from '../CustomComponentDefinitions/useCustomComponentsFeature'
import { $dataExportService } from '../DataExportSettings/$dataExportService'
import { useDataExportSettings } from '../DataExportSettings/useDataExportSettings'
import { useDataImportSettingPage } from '../DataImportSettings/useDataImportSettingPage'
import { $exportAsExcel } from '../ExcelExport/$exportAsExcel'
import { enableFileManageFeature } from '../FileManager/enableFileManageFeature'
import { defaultPageRoutes } from '../pages/defaultPageRoutes'
import { useRecordRevisionsFeature } from '../RecordRevisions/directusRevisionsModelsLoader'
import { $userMetaData } from '../UserMetaDataService/UserMetaDataService'
import { useUserRolesFeature } from '../UserRoles'
import { loadCoreAppUsers } from '../Users/loadCoreAppUsers'
import CoreApp from './CoreAppMain.vue'
import { addCoreGlobalHooksOnError } from './errorHandlers'
import './install$routerInto$core'
import { CORELoadCommonComponent } from './loadCommonComponent'
import './loadSpectrumWebComponents'

export interface initCoreFrontAppArgs {
  modelDefinitions?: { [modelName: string]: ModelDef } | null
  virtualModelDefinitions?: { [modelName: string]: VirtualModel } | null
  useUserRoles?: boolean
  useDataImportSetting?: boolean
  useDataExportSetting?: boolean
  useFileManager?: boolean
  useModelDefinitions?: boolean
  useVirtualModelDefinitions?: boolean
  useFrontSideAppHooks?: boolean
  additionalRoutes?: RouteRecordRaw[] | null
  useAppDefinition?: boolean
  appDefinitionKey?: string
  useRecordRevisions?: boolean
  useCustomComponentDefinitions?: boolean
  /**
   * 通常の管理画面を利用しない場合にtrueにする, default false
   * 独自画面を構築したい場合に、いくつかのデフォルト挙動をoffにする (signInへのリダイレクト挙動など)
   */
  isSdkMode?: boolean
  useAppTemplates?: boolean
  useChartDefinitions?: boolean
  useLineAwesomeIcons?: boolean
  useUserNotifications?: boolean
  /**
   * CSSを読み込むかどうか
   */
  loadDefaultCss?: boolean
  /**
   * アプリケーションメインのコンポーネントを差し替える場合にのみ利用 (特殊なカスタマイズを実施するケースのみ)
   */
  AppMainVueComponent?: any
  disableSetGlobalVue?: boolean
}

/**
 * Core Front app を初期化するMainFunction
 * 各PJの main.ts で callする。
 * RootのVueInstanceを返す
 */
export async function initCoreFrontApp({
  isSdkMode = false,
  modelDefinitions = null,
  virtualModelDefinitions = null,
  useDataImportSetting = !isSdkMode,
  useDataExportSetting = !isSdkMode,
  useUserRoles = !isSdkMode,
  useFileManager = !isSdkMode,
  useModelDefinitions = true,
  useVirtualModelDefinitions = true,
  useFrontSideAppHooks = !isSdkMode,
  additionalRoutes = null,
  useAppDefinition = !isSdkMode,
  appDefinitionKey = null,
  useRecordRevisions = !isSdkMode,
  useCustomComponentDefinitions = !isSdkMode,
  useAppTemplates = false,
  useChartDefinitions = !isSdkMode,
  useLineAwesomeIcons = !isSdkMode,
  useUserNotifications = false,
  loadDefaultCss = !isSdkMode,
  AppMainVueComponent = CoreApp,
  disableSetGlobalVue = false,
}: initCoreFrontAppArgs): Promise<() => App> {
  // Basic setups
  if (useAppDefinition) {
    $core.$appDefinitionLoader = $appDefinitionLoader
  }
  $core.$exportAsExcel = $exportAsExcel
  $core.$userMetaData = $userMetaData
  $core.$composableComponentSettingsManager = $composableComponentSettingsManager
  const loadPromises = []
  if (loadDefaultCss) {
    loadPromises.push(import('./loadCssStyles'))
  }
  $core.$frameworkUtils = $frameworkUtils
  if (!disableSetGlobalVue) {
    // 外部プラグイン化 した スクリプトを読み込んで 統合するために、global 名前空間経由で 渡す必要がある
    // 利用方法Ref: https://github.com/teamstove/CORE_FW_GPT_Integrator/blob/main/AgentChatUICoreFrameworkEmbedIntegration/vite.config.js#L45
    globalThis.CoreFwVue = Vue
  }

  // @ts-ignore
  const $vueApp = createApp(AppMainVueComponent)
  $vueApp.mixin({
    mounted() {
      // @ts-ignore
      if (window.Cypress) {
        this.setupMutationObserver()
      }
    },
    beforeUnmount() {
      // コンポーネントが破棄される前に、observerを切断します
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    methods: {
      setupMutationObserver() {
        // MutationObserverをセットアップ
        this.observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              this.applyDataAttributesToAddedNodes(mutation.addedNodes)
            }
          })
        })

        // コンポーネントの要素を監視対象にする
        this.observer.observe(this.$el, {
          childList: true,
          subtree: true,
        })
      },
      applyDataAttributesToAddedNodes(addedNodes) {
        // 追加されたノードのリストを受け取り、適切な属性を適用する
        addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            this.applyDataAttributes(node)
          }
        })
      },
      applyDataAttributes(element = this.$el) {
        // @ts-ignore
        if (window.Cypress && element && typeof element.setAttribute === 'function') {
          if (this.$options.name) {
            element.setAttribute('data-test', this.$options.name)
          }

          this.$nextTick(() => {
            setTimeout(() => {
              const listItems = element.querySelectorAll('li')
              listItems.forEach((li) => {
                const text = li.innerText.trim()
                if (text) {
                  li.setAttribute('data-test-text', text)
                }
              })

              // 全てのaタグとbuttonタグにdata-test-text属性を付与する
              const links = element.querySelectorAll('a, button')
              links.forEach((link) => {
                const text = link.innerText.trim()
                if (text) {
                  link.setAttribute('data-test-link-text', text)
                }
              })

              // classに.btnがある要素にdata-test-link-textを付与する
              const btns = element.querySelectorAll('.btn .btn-content')
              btns.forEach((btn) => {
                const text = btn.innerText.trim()
                if (text) {
                  btn.setAttribute('data-test-link-text', text)
                }
              })

              // 全てのtdに対してdata-test-td-text属性を付与する
              const tds = element.querySelectorAll('td')
              tds.forEach((td) => {
                const text = td.innerText.trim()
                if (text) {
                  td.setAttribute('data-test-td-text', text)
                }
              })

              // 全てのthに対してdata-test-th-text属性を付与する
              const ths = element.querySelectorAll('th')
              ths.forEach((th) => {
                const text = th.innerText.trim()
                if (text) {
                  th.setAttribute('data-test-th-text', text)
                }
              })
            }, 1000)
          })
        }
      },
    },
  })
  $vueApp.config.globalProperties.$core = $core
  $vueApp.config.globalProperties.$frameworkUtils = $frameworkUtils
  $core.$router = $router

  /**
   * 外部から $core を利用する場合などのための変数参照のsetup
   */
  $core.VueClass = $vueApp
  // SDKモードの設定
  $core.isSdkMode = !!(isSdkMode || globalThis.CORE_SDK_MODE)

  /**
   * レコードコメント機能 および関連するサービス・コンポーネントのLoad
   */
  installFrontRecordCommentsFeature()

  /**
   * ファイル定義のmodel定義をloadする (あれば)
   */
  if (modelDefinitions) {
    await $modelsLoader.loadModels(Object.values(modelDefinitions))
  }
  /**
   * ファイル定義のVirtualModel定義をloadする (あれば)
   */
  if (virtualModelDefinitions) {
    await $virtualModelsLoader.loadModels(Object.values(virtualModelDefinitions))
  }

  // 一括インポートの利用
  if (useDataImportSetting) {
    useDataImportSettingPage()
  }
  // エクスポートの利用
  if (useDataExportSetting) {
    $core.$dataExportService = $dataExportService
    useDataExportSettings()
  }
  // modelDefinitionsの利用
  if (useModelDefinitions) {
    useModelDefinitionsFeature()
  }
  // useVirtualModelDefinitionsの利用
  if (useVirtualModelDefinitions) {
    useVirtualModelDefinitionsFeature()
  }
  // UserRolesの利用
  if (useUserRoles) {
    useUserRolesFeature()
  }
  // FileManagerの利用
  if (useFileManager) {
    enableFileManageFeature()
  }
  // appDefinitionをLoad
  if (useAppDefinition) {
    await $core.$appDefinitionLoader.loadAppDefinitionOnSignedIn(appDefinitionKey)
  }

  /**
   * ユーザ情報の表示に関する サービス・コンポーネントのLoad
   */
  // installUserDisplays()

  /**
   * Composable Components を Load
   */
  initComposableComponents()
  initComposableDataListComponents()
  initComposableModelFormComponents()

  /**
   * Composable component builder の初期化
   */
  initComposableComponentBuilderFront()

  // Load API manager
  // TODO: only admin mode
  if (!isSdkMode) {
    apiAdminInitializer()
    loadCoreAppUsers() // vitrualmodel
  }

  /**
   * 共通ComponentをLoad
   */
  CORELoadCommonComponent($core.VueClass)

  if (useFrontSideAppHooks) {
    fetchAndRegisterFrontSideAppHooks()
  }

  if (useCustomComponentDefinitions) {
    useCustomComponentDefinitionsFeature()
  }

  // useChartDefinitions
  if (useChartDefinitions) {
    useChartDefinitionsFeature()
  }

  // plugins/ModelDefinitionsEditor
  initModelDefinitionsEditor()

  // useLineAwesomeIcons
  if (useLineAwesomeIcons) {
    // <link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css">
    loadExternalScript(
      'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css',
      'css',
    )
    // loadExternalScript('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css', 'css')
  }

  if (useUserNotifications) {
    $core.$userNotificationsService = $userNotificationsService
    await useUserNotificationsFeature()
  }

  /**
   * default Routing のset
   */
  if (!isSdkMode) {
    ;(await $appHook.emit('core-front-app--default-routes', defaultPageRoutes)).map(
      (route) => {
        $core.$router.addRoute(route)
      },
    )
  }

  /**
   * 追加のRoutingをセット
   */
  if (additionalRoutes) {
    additionalRoutes.map((route) => {
      $core.$router.addRoute(route)
    })
  }

  /**
   * 変更履歴の参照を有効化
   */
  if (useRecordRevisions) {
    useRecordRevisionsFeature()
  }

  if (useAppTemplates) {
    useAppTemplatesFeature()
  }
  // Resolve all load promises
  await Promise.all(loadPromises)

  // Error capture を有効化 with appHook
  addCoreGlobalHooksOnError()

  // Install vue directives
  installVueDirectives($core.VueClass)

  /**
   * Sentry エラーレポートを有効化 (development では無効)
   */
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      app: $core.VueClass,
      dsn: process.env.SENTRY_DSN_FRONT || process.env.SENTRY_DSN,
      environment: process.env.NODE_ENV,
      logErrors: true,
      ignoreErrors: [$core.$frontErrors.NoNeedReportError.name],
    })
  }

  // return start action
  const startApp = () => {
    $vueApp.use($router)
    $core.$vueRoot = $vueApp.mount(globalThis.CORE_ROOT_VUE_ELEMENT_SELECTOR || '#app')
    $core.$root = $core.$vueRoot
    // Emit event into window
    setTimeout(() => {
      globalThis.dispatchEvent(new Event('$CORE.init'))
    }, 100)
    return $core.VueClass
  }
  return startApp
}
