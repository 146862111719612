import { singletonInstanceSummoner } from '../singletonInstanceSummoner'

/**
 * # $core.$configVars
 *
 * - 挙動を差し替えるための設定値を参照, 設定するサービス
 *
 * ```ts
 * // CORE側で設定値を参照する
 * const shouldDoSomething = $core.$configVars.get('configKeyName', true) // true => defalt value
 * const someOtherConfigObject = $core.$configVars.get('someOtherConfig', {some: true, other: {a: false, b: 'something'}})
 * ```
 *
 * ```ts
 * // サービス側で設定値を書き換えて挙動を変更する
 * $core.$configVars.set('someOtherConfig', {some: false, other: {a: true, b: 'something'}})
 * ```
 *
 * @category $core/40.customize/402.configVars
 *
 */
export class ApplicationConfigVariablesService {
  configs: { [key: string]: any } = {}

  // 設定値を取得
  get(key, defaultValue = null) {
    return this.configs.hasOwnProperty(key) ? this.configs[key] : defaultValue
  }

  // 設定値を設定 (上書き)
  set(key, value) {
    this.configs[key] = value
  }

  // 一括設定
  configure(configs: { [keyName: string]: string }) {
    this.configs = {
      ...this.configs,
      ...configs,
    }
  }

  /**
   * @hidden
   */
  static get instance() {
    return singletonInstanceSummoner(
      'ApplicationConfigVariablesService',
      ApplicationConfigVariablesService,
    )
  }
}

export const $configVars = ApplicationConfigVariablesService.instance
