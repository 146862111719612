<template>
  <li v-if="menuItem" class="nav-item position-relative" :class="menuItemClasses">
    <h5 class="nav-item-heading" v-if="menuItem.type === 'heading'">
      <AppMenuIcon :icon="menuItem.icon" />
      {{ menuItem.label }}
    </h5>
    <router-link
      exact-active-class="dummy"
      active-class="dummy"
      :class="{ 'router-link-exact-active': isLinkExactActiveIncludesQueryPart }"
      v-bind="menuItemStyle"
      v-else-if="menuItem.type === 'link'"
      :to="menuItem.to"
      class="nav-link core-app-menu-bg-colored-text"
    >
      <AppMenuIcon v-if="menuItem.icon" :icon="menuItem.icon" />
      {{ menuItem.label }}
      <AppMenuRecordCount v-if="menuItem.amountBadgeVisible && menuItem.amountBadgeTargetModel" :menuItem="menuItem" />
    </router-link>
    <component
      v-else-if="menuItem.type === 'component' && menuItem.componentDefinition"
      :is="typeof menuItem.componentDefinition === 'string' ? menuItem.componentDefinition : {...menuItem.componentDefinition}"
      class="nav-link"
    ></component>
    <div v-else-if="menuItem.type === 'nested'">
      <a
        v-b-toggle="sidebarCollapseId"
        class="nav-link app-menu-item--type-nested"
        style="cursor: pointer"
        @click.prevent="() => false"
      >
        <AppMenuIcon :icon="menuItem.icon" />
        {{ menuItem.label }}
        <ficon type="angle-right" class="menu-arrow core-app-menu-bg-colored-text" />
      </a>
      <b-collapse :id="sidebarCollapseId" :visible="isActive">
        <ul class="nav flex-column sub-menu">
          <ChildAppMenuItemList
            v-for="(childMenu, i) in validMenuChildren"
            :menuItem="childMenu"
            :key="i"
          />
        </ul>
      </b-collapse>
    </div>
  </li>
</template>

<script lang="ts">
import AppMenuIcon from './AppMenuIcon.vue'
import { AppMenuItem } from './appMenuDefinitionParser'
import { PropType } from 'vue'
import AppMenuRecordCount from './AppMenuRecordCount.vue'

export default {
  components: {
    AppMenuRecordCount,
    AppMenuIcon,
    ChildAppMenuItemList: $frameworkUtils.defineAsyncComponent(
      () => import('./AppMenuItemList.vue'),
    ),
  },
  props: {
    menuItem: {
      required: true,
      type: Object as PropType<AppMenuItem>,
    },
  },
  async mounted() {
    if (!this.menuItem) {
      console.error('menuItem is undefined. this.menuItem:', this.menuItem)
    }
  },
  computed: {
    isActive() {
      const pathDecoded = decodeURI(this.$route.fullPath)
      return this.menuItem.children?.some(
        (child) => child?.to === pathDecoded.replace(/(.*)\/new/, '$1'),
      )
    },
    menuItemStyle() {
      if (
        $core.$appDefinitionLoader.appDefinition &&
        $core.$appDefinitionLoader.appDefinition.appMenuColor
      ) {
        const appMenuColor = $core.$appDefinitionLoader.appDefinition.appMenuColor
        const tooLightColor = $core.$utils.getBrightnessOfColor(appMenuColor) > 155
        if (tooLightColor) {
          // メニュ背景が明るい場合は、文字色を暗くする
          return {
            style: {
              color: 'var(--bs-gray-700)',
            },
          }
        } else {
          // メニュ背景が暗い場合は、文字色を明るくする
          return {
            style: {
              color: 'var(--bs-white)',
            },
          }
        }
      }
    },
    validMenuChildren() {
      return this.menuItem.children?.filter((child) => child)
    },
    isLinkExactActiveIncludesQueryPart(): boolean {
      const fullPathDecoded = decodeURIComponent(this.$route.fullPath)
      if (this.menuItem.useActiveLinkRegex && this.menuItem.activeLinkRegex) {
        // this.menuItem.activeLinkRegex, this.menuItem.activeLinkRegexFlags を利用して isActive を判定する
        const regex = new RegExp(
          this.menuItem.activeLinkRegex,
          this.menuItem.activeLinkRegexFlags || 'i',
        )
        return regex.test(fullPathDecoded)
      }
      if (decodeURIComponent(fullPathDecoded.replace('/new', '')) === this.menuItem.to) {
        return true
      }
      return false
    },
    uid() {
      return this._.uid
    },
    sidebarCollapseId() {
      return `sidebar-menu-collapse-${this.uid}`
    },
    menuItemClasses() {
      return [
        this.menuItem.cssClass || '',
        `app-menu-item--type-${this.menuItem.type}`,
      ]
    },
  },
}
</script>
