import { codeInputCommonAttrs } from '../$models'
import { createNewRecordWithColumnDefs } from '../$models/createNewRecordWithColumnDefs'
import {
  colGroupTypeKeys,
  colGroupTypes,
  ColumnDef,
  ColumnType,
  ColumnTypes,
  columnTypesNumber,
  columnTypesWithDecimals,
  ModelDef,
  ModelDefFormColumnGroups,
} from '../$models/ModelDef'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
import { tryParseAsObject } from '../utils'
import {
  DBDefinedModelDefinitionColumns,
  DBDefinedModelDefinitionColumnsColumns,
} from './DBDefinedModelDefinitionColumns'
import { getAllNestedColumnsWithRelationsForSelections } from './getAllNestedColumnsWithRelationsForSelections'
import { validationConfigs, ValidationConfigValueType } from './validationConfigs'

const selectableColumnTypeLabelMap = {
  [ColumnTypes.String]: `文字列型 (${ColumnTypes.String})`,
  [ColumnTypes.Number]: `数値(整数) (${ColumnTypes.Number})`,
  [ColumnTypes.DateOnly]: `日付 (${ColumnTypes.DateOnly})`,
  [ColumnTypes.Datetime]: `日付＋時刻（${ColumnTypes.Datetime}）`,
  [ColumnTypes.Text]: `ロングテキスト (${ColumnTypes.Text})`,
  [ColumnTypes.Boolean]: `真/偽チェックボックス (${ColumnTypes.Boolean})`,
  [ColumnTypes.FileUpload]: `ファイルアップロード (${ColumnTypes.FileUpload})`,
  [ColumnTypes.File]: `ファイル選択 (${ColumnTypes.File})`,
  [ColumnTypes.RichText]: `リッチテキスト (${ColumnTypes.RichText})`,
  // [ColumnTypes.String]: `セレクト (${ColumnTypes.String})`,
  [ColumnTypes.MultiSelect]: `マルチセレクト (${ColumnTypes.MultiSelect})`,
  // [ColumnTypes.Reference]: `外部参照 (${ColumnTypes.Reference})`,
  [ColumnTypes.RelationshipManyToOne]: `M2O (多対1) リレーションシップ (${ColumnTypes.RelationshipManyToOne})`,
  [ColumnTypes.RelationshipOneToMany]: `O2M (1対多) リレーションシップ (${ColumnTypes.RelationshipOneToMany})`,
  // [ColumnTypes.RelationshipManyToAny]: `M2A (${ColumnTypes.RelationshipManyToAny})`, // TODO: not implemented yet 20221023
  // [ColumnTypes.ArrayOfObject]: `階層型 (${ColumnTypes.ArrayOfObject})`,
  // [ColumnTypes.Float]: `数値(小数点) (${ColumnTypes.Float})`,
  [ColumnTypes.Decimal]: `数値(小数点) (${ColumnTypes.Decimal})`,
  // [ColumnTypes.Double]: `数値(小数点) (${ColumnTypes.Double})`,
  [ColumnTypes.BigInteger]: `数値(整数) (${ColumnTypes.BigInteger})`,
  [ColumnTypes.JSON]: ColumnTypes.JSON,
}

/**
 * importableModelで作成可能なColumnType
 */
const selectableColumnTypes = Object.keys(selectableColumnTypeLabelMap)

/**
 * selectableOptionsを指定可能なColumnType
 */
const enableSelectOptionsColumnTypes: ColumnType[] = [
  ColumnTypes.String,
  ColumnTypes.MultiSelect,
  ColumnTypes.String,
]

const selectablePrimaryKeyColumnTypeLabelMap = {
  [ColumnTypes.Number]: `数値連番 (AUTO_INCREMENT)`,
  [ColumnTypes.UUID]: `${ColumnTypes.UUID}自動割当`,
  [ColumnTypes.String]: `手動入力文字列`,
}

const selectablePrimaryKeyColumnTypes = Object.keys(
  selectablePrimaryKeyColumnTypeLabelMap,
)

const formStyleTypes = {
  normal: '通常',
  table: '表形式',
  labeled: 'ラベル(横並び)',
}

const findPrimaryKeyColFromModelDefinitionData = (
  modelDefinitionData: any,
): DBDefinedModelDefinitionColumnsColumns => {
  return modelDefinitionData.columns.find((col) => {
    if (col.key === 'id' || col.primaryKey === true) {
      return true
    }
    let otherColAttributes: Partial<ColumnDef> = {}
    try {
      if (col.otherColAttributes && typeof col.otherColAttributes === 'string') {
        otherColAttributes = tryParseAsObject(col.otherColAttributes)
      }
      if (otherColAttributes.primaryKey === true) {
        return true
      }
    } catch (e) {
      console.error(e)
    }
    return false
  })
}

/**
 * tableName validator
 */
const validateAlphaAndUnderscore = (value) => {
  const isValid = value && /^[0-9|a-z|_]+$/i.test(value)
  if (!isValid) {
    return '半角英数およびアンダーバーで入力してください。'
  }
  return
}

const getColumnSpecificPropFromColumnRowData = (
  propName: string,
  row: Record<string, any>,
  recordRoot: Record<string, any>,
) => {
  if (row[propName]) {
    return row[propName]
  }
  if (!row.key) {
    return null
  }
  // VirtualModel 上で 呼び出しても 正しく 元モデルの columnType を取得できるようにする
  return $core.$models[recordRoot.baseModel]?.columns?.[row.key]?.[propName] || null
}

const getBaseColumnFromColumnRowDataWhenVirtualModel = (row, recordRoot): ColumnDef => {
  return $core.$models[recordRoot.baseModel]?.columns?.[row.key] || null
}

const getColumnTypeFromColumnRowData = (row, recordRoot): ColumnType => {
  return getColumnSpecificPropFromColumnRowData('type', row, recordRoot)
}

const enableIfForSelectOptionsColumnTypes = (row, recordRoot) => {
  return enableSelectOptionsColumnTypes.includes(
    getColumnTypeFromColumnRowData(row, recordRoot),
  )
}

const enableIfNumberLikeColumnTypes = (row, recordRoot) => {
  return columnTypesNumber.includes(getColumnTypeFromColumnRowData(row, recordRoot))
}

const enableIfDecimalNumberColumnTypes = (row, recordRoot) => {
  return columnTypesWithDecimals.includes(getColumnTypeFromColumnRowData(row, recordRoot))
}

const widthRightValues = [
  'auto',
  'none',
  'unset',
  'initial',
  'inherit',
  'max-content',
  'min-content',
  'fit-content',
]
const isNumberAndValidUnitForWidth = (value) => {
  if (!value) return
  // CSS の 値として 有効な値であること
  if (
    /^-?\d+(\.\d+)?(px|%|em|rem|vw|vh|vmin|vmax)$/.test(value) === false &&
    widthRightValues.includes(value) === false
  ) {
    return '有効な単位・値で入力して下さい'
  }
}

const addPxUnitIfNumberOnly = (value) => {
  // 数字のみの場合は、単位を付与する
  if (value && /^-?\d+(\.\d+)?$/.test(value)) {
    return value + 'px'
  }
  return value
}

function isValidColumnName(columnName) {
  // 正規表現で列名のパターンをチェック（英数字、アンダースコア、日本語文字で始まることを許可）
  const validNamePattern =
    /^[a-zA-Z\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\u3000-\u303F][a-zA-Z0-9_\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\u3000-\u303F]*$/

  // 列名が正規表現にマッチするかどうかをチェック
  if (!validNamePattern.test(columnName)) {
    return '半角英数字、アンダースコア および 日本語文字で入力してください。(先頭に数字・アンダースコアを利用することはできません)'
  }
}

const getAllNestedColumnNames = (columns): string[] => {
  if (!columns) {
    return []
  }
  const res: string[] = []
  columns?.forEach((column) => {
    res.push(column.name || column.key)
    if (column.type === ColumnTypes.ArrayOfObject) {
      res.concat(getAllNestedColumnNames(column.columns))
    }
  })
  return res
}

const getColNameOrKeyArr = (row, callerVueInstance) => {
  const columns = callerVueInstance?.record?.columns || []
  return getAllNestedColumnNames(columns)
}

const inputColWidthSelectionsUnselectedLabel = '未設定'
const inputColWidthSelections: Record<number | string | null, string> = {
  // 1...48 の 48 分割
  // @ts-ignore
  [null]: inputColWidthSelectionsUnselectedLabel,
  ...Array.from({ length: 48 }, (_, i) => i + 1).reduce((acc, cur) => {
    acc[`${cur}`] = `${cur}/48 (${((cur / 48) * 100).toFixed(2)}%)`
    return acc
  }, {}),
  // grow-1
  'grow-1': '横幅いっぱいに表示 (余白を埋める)',
}

/**
 * カラム定義入力のカラム
 */
const columnDefinitionColumns: Record<
  keyof DBDefinedModelDefinitionColumnsColumns,
  ColumnDef
> = {
  key: {
    type: ColumnTypes.String,
    validate: {
      notEmpty: true,
      isValidColumnName,
      validateUnique: (value, col, modelName, record, recordRoot) => {
        const isDuplicated =
          recordRoot.columns?.filter((col) => col.key === value)?.length > 1
        if (isDuplicated) {
          return 'キーが重複しています。'
        }
        return
      },
    },
    inputHelpText:
      'カラムの物理名を定義します。一度設定すると 変更することはできません。(削除は可能です)',
    label: 'キー',
    disabledComponent: {
      props: {
        modelValue: {},
      },
      template: `<code class="">{{ modelValue }}</code>`,
    },
    editable(row, callerVueInstance, recordRoot) {
      // モデル定義のキーは 保存済みであれば 編集不可
      if ($core.$models[recordRoot.tableName]?.columns?.[row.key]) {
        return false
      }
      return true
    },
    editCallback({ row, newValue, oldValue, callerVueInstance }) {
      if (!newValue || newValue === oldValue) return row
      const colNameOrKeyArr = getColNameOrKeyArr(row, callerVueInstance)
      const includeCount = colNameOrKeyArr.filter(
        (colNameOrKey) => colNameOrKey === newValue,
      ).length
      if (includeCount > 1) {
        row.key = ''
        $core.$toast.errorToast(`すでにカラム名 "${newValue}" は設定されています`)
      }
      return row
    },
    // afterComponent: {
    //   template: `
    //     <small v-if="isNotRecommendedColumnName"> ⚠️ <span class="text-muted">日本語を利用する場合は そのリスクを考慮して設定してください。</span></small>`,
    //   computed: {
    //     isNotRecommendedColumnName() {
    //       return this.$parent.record.key && !isRecommendedColumnName(this.$parent.record.key)
    //     },
    //   },
    // },
    // async editCallback({ row, newValue, oldValue, callerVueInstance }) {
    //   const tableName = callerVueInstance?.recordRoot?.tableName
    //   // 既存のカラム物理名が変更されるとき、警告を出して キャンセルなら戻す
    //   if (oldValue && oldValue !== newValue && $core.$models[tableName]?.columns?.[oldValue]) {
    //     if (
    //       (await $core.$toast.confirmDialog(
    //         'カラムのキー (データベース物理名) を変更すると、新しいカラムとして作成されます。それでもよろしいですか？',
    //       )) !== true
    //     ) {
    //       row.key = oldValue
    //     }
    //   }
    //   debugger
    //   return row
    // },
  },
  label: {
    label: '表示名',
    type: ColumnTypes.String,
    comment: 'カラムの表示名を入力します。',
  },
  type: {
    label: 'データタイプ',
    type: ColumnTypes.String,
    comment: 'データ型の選択によって、入力フォームが変わります。',
    validate: { notEmpty: true },
    inputAttrs: { wrapperClass: 'col-12 col-md-3' },
    defaultValue: ColumnTypes.String,
    selections(record: any, currentValue: any, initialValue: any, recordRoot: any) {
      const isNewColumn = !$core.$models[recordRoot.tableName]?.columns?.[record.key]
      if (isNewColumn) {
        // 新規カラムの場合は RELATIONSHIP_ONE_TO_MANY を選択肢に含める
        return selectableColumnTypes
      }
      // RELATIONSHIP_ONE_TO_MANY の場合は編集不可のため、現在の値のみを返す
      if (initialValue === ColumnTypes.RelationshipOneToMany) {
        return [ColumnTypes.RelationshipOneToMany]
      }

      // それ以外の場合は RELATIONSHIP_ONE_TO_MANY 以外の選択肢を返す
      return selectableColumnTypes.filter(
        (type) => type !== ColumnTypes.RelationshipOneToMany,
      )
    },
    customLabel(val) {
      return selectableColumnTypeLabelMap[val] || val
    },
    editable(row, callerVueInstance, recordRoot) {
      const isNewColumn = !$core.$models[recordRoot.tableName]?.columns?.[row.key]
      if (isNewColumn) {
        return true
      }
      // RELATIONSHIP_ONE_TO_MANY の場合は編集不可
      return row.type !== ColumnTypes.RelationshipOneToMany
    },
  },
  adminComment: {
    label: '管理用コメント (設計・仕様・カラムの用途に関する特記事項)',
    type: 'TEXT',
    displayAsDetail: true,
    width: { xs: 48 },
  },
  inputComponent: {
    label: '入力コンポーネント',
    type: ColumnTypes.String,
    comment: '入力フォームの種類を選択します。',
    width: { xs: 48 },
    inputComponent: 'InputComponentForColumnType',
    displayAsDetail: true,
    groupKey: 'colBase',
  },
  defaultValue: {
    groupKey: 'colBase',
    type: ColumnTypes.String,
    label: 'デフォルト値',
    enableIf: (row) => row.type !== ColumnTypes.ArrayOfObject,
    displayAsDetail: true,
  },
  setNullIfEmpty: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '空値をnullとして保存する',
    enableIf: (row) => row.type === ColumnTypes.String,
    displayAsDetail: true,
    defaultValue: false,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  // NOTICE: GUIから設定するカラムは、 orderOnForm は利用禁止 => GUIでソートさせるために
  // orderOnForm: {
  //   groupKey: 'colBase',
  //   label: '入力フォームの表示順指定',
  //   type: 'NUMBER',
  //   comment: '昇順 (ASC)',
  //   displayAsDetail: true,
  // },
  groupKey: {
    type: 'STRING',
    groupKey: 'colStyle',
    label: 'カラムグループ',
    displayAsDetail: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
    ): Promise<any[]> | any[] {
      return recordRoot?.formColumnGroupsAsArray?.map((group) => group.key) || []
    },
    customLabel: (val, callerVueInstance, recordRoot) => {
      return (
        recordRoot?.formColumnGroupsAsArray?.find((group) => group?.key === val)?.label ||
        ''
      )
    },
    enableIf: (childRow, row) =>
      (row?.formColumnGroupsAsArray?.map((group) => group.key) || []).length > 0,
  },
  unique: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '重複禁止',
    comment: '同じ値を登録できないようにします。',
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  visible: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '表示する',
    comment: '表側では非表示にして、裏側で値を保持するときに利用します。',
    defaultValue: true,
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  replicable: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '複製可能',
    comment: '複製時に値を引き継ぐかどうかを指定します。',
    defaultValue: true,
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  visibleOnIndex: {
    groupKey: 'colList',
    type: ColumnTypes.Boolean,
    label: '一覧画面で表示をする',
    defaultValue: true,
    displayAsDetail: true,
    // inputComponent: 'BooleanRadioInput',
    // inputAttrs: {
    //   wrapperStyle: 'width: auto;',
    //   trueLabel: '表示する',
    //   falseLabel: '非表示',
    // },
  },
  excludeFromSearch: {
    groupKey: 'colList',
    label: '検索対象から除外',
    inputHelpText:
      'チェックを入れた場合、一覧表示時の 検索フィルタ条件 および キーワード検索 から除外されます。',
    type: 'BOOLEAN',
    displayAsDetail: true,
    inputAttrs: {
      wrapperStyle: 'width: auto;',
    },
  },
  editable: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '編集可能',
    defaultValue: true,
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  bulkEditable: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    label: '一括編集可能',
    defaultValue: true,
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  editableOnCreate: {
    groupKey: 'colBase',
    type: ColumnTypes.Boolean,
    enableIf: (row, child) => !row.editable,
    label: '新規作成時のみ編集可能',
    inputAttrs: {
      wrapperStyle: 'width: auto;',
    },
    defaultValue: false,
    displayAsDetail: true,
  },
  relationshipManyToOne_collectionName: {
    type: ColumnTypes.String,
    label: '[M2O] 参照Model',
    validate: { notEmpty: true },
    // VirtualModel では 変更禁止
    enableIf: (row) => row.type === ColumnTypes.RelationshipManyToOne,
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      return Object.keys($core.$models) // .filter((model) => model !== recordRoot.tableName)
    },
    customLabel(val) {
      return (
        val +
        ($core.$models[val]?.tableLabel ? ` (${$core.$models[val]?.tableLabel})` : '')
      )
    },
  },
  relationshipManyToOne_virtualModelName: {
    type: ColumnTypes.String,
    label: '[M2O] 参照Virtualモデル',
    // validate: { notEmpty: true },
    // VirtualModel では 変更可能
    enableIf: (row, recordRoot) => {
      if (
        getColumnTypeFromColumnRowData(row, recordRoot) !==
        ColumnTypes.RelationshipManyToOne
      ) {
        return false
      }
      const baseModel =
        row.relationshipManyToOne_collectionName ||
        getBaseColumnFromColumnRowDataWhenVirtualModel(row, recordRoot)
          ?.relationshipManyToOne?.collectionName
      return (
        Object.keys($core.$virtualModels).filter(
          (vmName) => $core.$virtualModels[vmName].baseModel === baseModel,
        )?.length > 0
      )
    },
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      const baseModel =
        record.relationshipManyToOne_collectionName ||
        getBaseColumnFromColumnRowDataWhenVirtualModel(record, recordRoot)
          ?.relationshipManyToOne?.collectionName
      if (!baseModel) {
        return []
      }
      return Object.keys($core.$virtualModels).filter(
        (vmName) => $core.$virtualModels[vmName].baseModel === baseModel,
      )
    },
    customLabel(val) {
      return (
        val +
        ($core.$virtualModels[val]?.tableLabel
          ? ` (${$core.$virtualModels[val]?.tableLabel})`
          : '')
      )
    },
  },
  relationshipManyToOne_labelFormatter: {
    type: ColumnTypes.Text,
    // TODO: わかりやすく！！
    label: '[M2O] データのラベル表示 (Label formatter 関数)',
    inputAttrs: {
      ...codeInputCommonAttrs,
    },
    // VirtualModel で 変更可能
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
      ColumnTypes.RelationshipManyToOne,
    afterComponent:
      '<div class="small text-muted">例: <code>return row.email + "(" + row.id + ")"</code> リレーション先のデータから、どの項目を表示するか記述可能です。function (row) { ... } の文脈で実行されます。</div>',
    // inputComponent: 'FieldPreviewEditor',
    width: { xs: 48 },
  },
  relationshipManyToOne_filterByAttrs: {
    type: ColumnTypes.Text,
    label: '[M2O] リレーションデータのフィルター条件',
    // VirtualModel で 変更可能
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
      ColumnTypes.RelationshipManyToOne,
    width: { xs: 48 },
    inputComponent: {
      template:
        '<FilterResultDisplayContainer v-if="collectionName" :emitValueAsObject="true" v-bind="attrsMerged"/>',
      computed: {
        collectionName(): string {
          // Virtual model の場合
          if (this.$attrs.recordRoot.baseModel) {
            return $core.$models[this.$attrs.recordRoot?.baseModel].columns[
              this.$attrs.record?.key
            ]?.relationshipManyToOne?.collectionName
          }
          return this.$attrs.record?.relationshipManyToOne_collectionName
        },
        attrsMerged() {
          return {
            ...this.$attrs,
            collectionName: this.collectionName,
          }
        },
      },
    },
  },
  relationshipManyToOne_allowEmptySelection: {
    // beforeComponents: ['<h4>M2O設定</h4>'],
    type: ColumnTypes.Boolean,
    groupKey: 'colBase',
    displayAsDetail: true,
    label: '[M2O] 未選択を許可',
    // VirtualModel で 変更可能
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
      ColumnTypes.RelationshipManyToOne,
    inputAttrs: { wrapperStyle: 'width: auto;' },
    defaultValue: true,
  },
  relationshipManyToOne_emptyLabel: {
    type: ColumnTypes.String,
    groupKey: 'colBase',
    displayAsDetail: true,
    label: '[M2O] 未選択時の表示ラベル',
    // VirtualModel で 変更可能
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
        ColumnTypes.RelationshipManyToOne &&
      row.relationshipManyToOne_allowEmptySelection === true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
    defaultValue: '=== 未選択 ===',
  },
  inputAttrs_enableAddNewLink: {
    type: ColumnTypes.Boolean,
    label: '[M2O] フォーム上に新規追加リンクを表示',
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
      ColumnTypes.RelationshipManyToOne,
    groupKey: 'colBase',
    displayAsDetail: true,
  },
  hideOpenDataLink: {
    type: ColumnTypes.Boolean,
    label: '[M2O] データ参照リンクを非表示',
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
      ColumnTypes.RelationshipManyToOne,
    groupKey: 'colBase',
    displayAsDetail: true,
  },
  relationshipOneToMany_collectionName: {
    type: ColumnTypes.String,
    label: '[O2M] 参照Model',
    validate: { notEmpty: true },
    // VirtualModel では 変更禁止
    enableIf: (row) => row.type === ColumnTypes.RelationshipOneToMany,
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      const allModels = Object.keys($core.$models).filter(
        (model) => model !== recordRoot.tableName,
      )
      // 自分のモデルをM2Oで指定したカラム持っているモデルのみに絞る
      const ret = []
      for (const model of allModels) {
        const columns = $core.$models[model].columns
        for (const [colKey, colValue] of Object.entries(columns)) {
          if (
            colValue.type === ColumnTypes.RelationshipManyToOne &&
            colValue.relationshipManyToOne?.collectionName === recordRoot.tableName
          ) {
            ret.push(model)
            break
          }
        }
      }
      return ret
    },
    customLabel(val) {
      return (
        val +
        ($core.$models[val]?.tableLabel ? ` (${$core.$models[val]?.tableLabel})` : '')
      )
    },
  },
  relationshipOneToMany_fieldName: {
    type: ColumnTypes.Select,
    label: '[O2M] 参照フィールド',
    validate: { notEmpty: true },
    // VirtualModel では 変更禁止
    enableIf: (row) => row.type === ColumnTypes.RelationshipOneToMany,
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      // {value, label}[]で返せる
      if (!$core.$models[record.relationshipOneToMany_collectionName]) return []
      const ret: { value: string; label: string }[] = []
      for (const [colKey, colValue] of Object.entries(
        $core.$models[record.relationshipOneToMany_collectionName].columns,
      )) {
        // Many側で自分とリレーション張っているカラムに絞る
        if (
          colValue.type === ColumnTypes.RelationshipManyToOne &&
          colValue.relationshipManyToOne?.collectionName === recordRoot.tableName
        )
          ret.push({
            value: colKey,
            label: colValue.label ? colKey + `(${colValue.label})` : colKey,
          })
      }
      return ret
    },
    dynamicSelections: true,
  },
  relationshipOneToMany_virtualModelName: {
    type: ColumnTypes.String,
    label: '[O2M] 参照Virtualモデル',
    // validate: { notEmpty: true },
    // VirtualModel では 変更可能
    enableIf: (row, recordRoot) => {
      if (
        getColumnTypeFromColumnRowData(row, recordRoot) !==
        ColumnTypes.RelationshipOneToMany
      ) {
        return false
      }
      const baseModel =
        row.relationshipOneToMany_collectionName ||
        getBaseColumnFromColumnRowDataWhenVirtualModel(row, recordRoot)
          ?.relationshipOneToMany?.collectionName
      return (
        Object.keys($core.$virtualModels).filter(
          (vmName) => $core.$virtualModels[vmName].baseModel === baseModel,
        )?.length > 0
      )
    },
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      const baseModel =
        record.relationshipOneToMany_collectionName ||
        getBaseColumnFromColumnRowDataWhenVirtualModel(record, recordRoot)
          ?.relationshipOneToMany?.collectionName
      if (!baseModel) {
        return []
      }
      return Object.keys($core.$virtualModels).filter(
        (vmName) => $core.$virtualModels[vmName].baseModel === baseModel,
      )
    },
    customLabel(val) {
      return (
        val +
        ($core.$virtualModels[val]?.tableLabel
          ? ` (${$core.$virtualModels[val]?.tableLabel})`
          : '')
      )
    },
  },
  relationshipOneToMany_labelFormatter: {
    type: ColumnTypes.Text,
    inputAttrs: {
      ...codeInputCommonAttrs,
    },
    // TODO: わかりやすく！！
    label: '[O2M] リレーションデータのラベル表示 (Label formatter)',
    // VirtualModel で 変更可能
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
        ColumnTypes.RelationshipOneToMany && row.relationshipOneToMany_collectionName,
    // inputComponent: 'FieldPreviewEditor',
    width: { xs: 48 },
  },
  relationshipOneToMany_sortFieldName: {
    type: ColumnTypes.String,
    dynamicSelections: true,
    label: '[O2M] リレーションデータの並び替えキーの指定',
    enableIf: (row, recordRoot) =>
      getColumnTypeFromColumnRowData(row, recordRoot) ===
        ColumnTypes.RelationshipOneToMany && row.relationshipOneToMany_collectionName,
    async selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ): Promise<any[]> {
      const relationshipModelName = record.relationshipOneToMany_collectionName
      if (!$core.$models[relationshipModelName]) {
        return []
      }
      const columns = $core.$models[relationshipModelName].columns
      return Object.values(columns).map((column) => {
        return {
          label: column.label || column.name,
          value: column.name,
        }
      })
    },
    inputHelpText:
      'フォーム表示内でリレーション先のデータを並び替えるためのキーを指定します。',
  },
  selectionsWithSelectOptionsMasterGroupName: {
    groupKey: 'colSelection',
    type: ColumnTypes.String,
    async selections($store, record, currentValue, initialValue) {
      return $core.$models.selectOptionsMaster.fetchFacetNamesByColName('group')
    },
    label: '選択肢マスタ指定',
    enableIf: enableIfForSelectOptionsColumnTypes,
    displayAsDetail: true,
    afterComponent: {
      template: `
        <a v-if="selectOptionGroupName" class="small" href="#" @click.prevent="() => openListModal()">選択肢マスタ "{{ selectOptionGroupName }}" 一覧
          <ficon type="external-link-alt"/>
        </a>`,
      computed: {
        selectOptionGroupName() {
          return this.$parent?.record?.selectionsWithSelectOptionsMasterGroupName
        },
      },
      methods: {
        openListModal() {
          $core.$modals.openListViewModal({
            modelName: 'selectOptionsMaster',
            filters: {
              group: { _eq: this.selectOptionGroupName },
            },
          })
        },
      },
    },
  },
  createSelectOptionsMasterOnAddNewSelection: {
    groupKey: 'colSelection',
    type: ColumnTypes.Boolean,
    label: '新規選択肢を自動登録する',
    enableIf: (row, recordRoot) =>
      enableIfForSelectOptionsColumnTypes(row, recordRoot) &&
      row.selectionsWithSelectOptionsMasterGroupName,
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  strictSelections: {
    type: 'BOOLEAN',
    label: '選択肢以外の入力を禁止する',
    enableIf: enableIfForSelectOptionsColumnTypes,
    groupKey: 'colSelection',
    displayAsDetail: true,
  },
  additionalSelectOptions_prepend: {
    groupKey: 'colSelection',
    type: ColumnTypes.MultiSelect,
    label: '追加の選択肢',
    enableIf: enableIfForSelectOptionsColumnTypes,
    displayAsDetail: true,
  },
  additionalSelectOptions_append: {
    groupKey: 'colSelection',
    type: ColumnTypes.MultiSelect,
    label: '追加の選択肢 (後方)',
    enableIf: enableIfForSelectOptionsColumnTypes,
    displayAsDetail: true,
  },
  colorLabelSelectionConditon: {
    groupKey: 'colSelection',
    type: ColumnTypes.ArrayOfObject,
    label: '選択肢色の設定',
    enableIf: async (row, recordRoot) => {
      return (
        enableIfForSelectOptionsColumnTypes(row, recordRoot) &&
        row.inputComponent === 'ColorfulSelect'
      )
    },
    columns: {
      valueCondition: {
        type: ColumnTypes.Text,
        label: '値条件',
        inputAttrs: {
          ...codeInputCommonAttrs,
        },
      },
      useRegExp: {
        type: ColumnTypes.Boolean,
        label: '正規表現を利用する',
      },
      color: {
        type: ColumnTypes.String,
        label: '色',
        inputComponent: 'ColorPicker',
        width: { xs: 6 },
      },
      borderColor: {
        type: ColumnTypes.String,
        label: '枠線色',
        inputComponent: 'ColorPicker',
        width: { xs: 6 },
      },
      textColor: {
        type: ColumnTypes.String,
        label: '文字色',
        inputComponent: 'ColorPicker',
        width: { xs: 6 },
      },
    },
    displayAsDetail: true,
    width: { xs: 48 },
  },
  inputAttrs_isFormatWithComma: {
    label: '数値入力欄でカンマ区切りを適用',
    type: ColumnTypes.Boolean,
    groupKey: 'colStyle',
    displayAsDetail: true,
    defaultValue: false,
    enableIf: (row, recordRoot) => enableIfNumberLikeColumnTypes(row, recordRoot),
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  hideLabel: {
    groupKey: 'colStyle',
    type: ColumnTypes.Boolean,
    label: 'ラベルを非表示',
    displayAsDetail: true,
    inputAttrs: { wrapperStyle: { maxWidth: 'max-content' } },
  },
  width_xs: {
    groupKey: 'colStyle',
    type: ColumnTypes.String,
    label: 'スマホサイズ時の幅',
    inputHelpText: '幅の割合を、48分の1 の単位で設定可能です。 (48を設定すると幅100%)',
    displayAsDetail: true,
    selections: () => Object.keys(inputColWidthSelections),
    customLabel(val) {
      return inputColWidthSelections[val]
    },
  },
  width_sm: {
    groupKey: 'colStyle',
    type: ColumnTypes.String,
    label: 'タブレットサイズ時の幅',
    displayAsDetail: true,
    selections: () => Object.keys(inputColWidthSelections),
    customLabel(val) {
      return inputColWidthSelections[val]
    },
  },
  width_md: {
    groupKey: 'colStyle',
    type: ColumnTypes.Number,
    label: 'PCサイズ時の幅',
    displayAsDetail: true,
    selections: () => Object.keys(inputColWidthSelections),
    customLabel(val) {
      return inputColWidthSelections[val]
    },
  },
  validationConfigs,
  numericPrecision: {
    label: '小数点を含めた桁数 (precision)',
    groupKey: 'colBase',
    type: ColumnTypes.Number,
    enableIf: enableIfDecimalNumberColumnTypes,
    defaultValue: 10,
    displayAsDetail: true,
  },
  numericScale: {
    label: '小数点以下桁数 (scale)',
    groupKey: 'colBase',
    type: ColumnTypes.Number,
    enableIf: enableIfDecimalNumberColumnTypes,
    defaultValue: 5,
    displayAsDetail: true,
  },
  // groupedEdit: {
  //   label: 'グループ編集',
  //   type: ColumnTypes.Boolean,
  //   displayAsDetail: true,
  // },
  inputAttrs_prefix: {
    groupKey: 'colBase',
    label: '接頭語 (prefix)',
    type: 'STRING',
    comment: 'フィールドに接頭語を付与します。',
    displayAsDetail: true,
    inputAttrs: {
      placeholder: '例: 単位',
      wrapperStyle: 'min-width: 100px; max-width: 160px;',
    },
  },
  inputAttrs_suffix: {
    groupKey: 'colBase',
    label: '接尾語 (suffix)',
    type: 'STRING',
    comment: 'フィールドに接尾語を付与します。単位などで利用します。 (例: KG)',
    displayAsDetail: true,
    inputAttrs: {
      placeholder: '例: KG',
      wrapperStyle: 'min-width: 100px; max-width: 160px;',
    },
  },
  inputAttrs_placeholder: {
    groupKey: 'colBase',
    label: '入力プレースホルダー',
    type: 'STRING',
    inputHelpText: '入力欄内に 薄い文字で表示されるテキストを設定します。',
    displayAsDetail: true,
    inputAttrs: {
      placeholder: '例: T001234',
      wrapperStyle: 'min-width: 210px; max-width: 260px;',
    },
  },
  inputAttrs_wrapperStyle_minWidth: {
    groupKey: 'colStyle',
    type: ColumnTypes.String,
    label: '最小幅',
    displayAsDetail: true,
    validate: {
      isNumberAndValidUnitForWidth,
    },
    editCallback({ row, newValue, oldValue }) {
      // 数字のみの場合は、単位 px を自動付与する
      row.inputAttrs_wrapperStyle_minWidth = addPxUnitIfNumberOnly(newValue)
      return row
    },
  },
  inputAttrs_wrapperStyle_maxWidth: {
    groupKey: 'colStyle',
    type: ColumnTypes.String,
    label: '最大幅',
    displayAsDetail: true,
    validate: {
      isNumberAndValidUnitForWidth,
    },
    editCallback({ row, newValue, oldValue }) {
      // 数字のみの場合は、単位 px を自動付与する
      row.inputAttrs_wrapperStyle_maxWidth = addPxUnitIfNumberOnly(newValue)
      return row
    },
    afterComponent: {
      template: `<div class="small text-muted">💡 <code style="text-decoration: underline" class="cursor-pointer hover-fade" @click.prevent="() => $parent.change({value: 'max-content', error: ''})">max-content</code> を指定して、自動可変幅を設定できます。</div>`,
    },
  },
  inputAttrs_rows: {
    groupKey: 'colStyle',
    type: ColumnTypes.Number,
    label: 'テキストエリアの行数(高さ)',
    displayAsDetail: true,
    defaultValue: 3,
    enableIf: (row) => row.type === ColumnTypes.Text,
  },
  // inputAttrs_wrapperClass: {
  //   type: ColumnTypes.String,
  //   label: '追加 CSS Class',
  //   groupKey: 'colStyle',
  //   displayAsDetail: true,
  // },
  inputHelpText: {
    groupKey: 'colBase',
    label: '入力欄説明文',
    type: ColumnTypes.Text,
    inputAttrs: {
      wrapperClass: 'col-12',
    },
    inputHelpText: '※ このテキストのことです。 <code>HTMLタグ</code> も利用可能です。',
    displayAsDetail: true,
  },
  // inputAttrs: {
  //   groupKey: 'colAdvanced',
  //   label: '入力要素の追加属性',
  //   type: ColumnTypes.Text,
  //   comment: 'JSONで記述(load時にparseされます)',
  //   inputAttrs: {
  //     ...codeInputCommonAttrs,
  //     placeholder: ``,
  //   },
  //   displayAsDetail: true,
  //   validate: {
  //     isValidJsObject: true,
  //   },
  // },
  otherColAttributes: {
    groupKey: 'colAdvanced',
    type: ColumnTypes.Text,
    label: 'JS形式の追加カラム定義',
    inputHelpText:
      'オブジェクトを返却してください。Javascriptの記法を利用可能で、他属性を上書きすることが可能です。(editCallback や、enableIf など の関数設定で利用)',
    inputAttrs: {
      ...codeInputCommonAttrs,
      placeholder: ``,
      initCode: `/**
 * @type {ColumnDefPartial}
 */
const columnDef = {
  $$cursor
}
return columnDef`,
    },
    displayAsDetail: true,
    validate: {
      isValidJsObject: true,
    },
  },
  enableIfByFilterQuery: {
    groupKey: 'colBase',
    type: ColumnTypes.Text,
    label: '編集フォーム 動的表示切替設定 (enableIf)',
    inputHelpText:
      '編集中のデータの状態が、上記条件に合致したときにのみ、このカラムが表示されます。 ※ 未保存の新規追加カラムは、一度保存すると条件設定に表示されます。',
    displayAsDetail: true,
    inputAttrs: {
      wrapperClass: 'col-12',
    },
    // inputComponent: 'FilterResultDisplayContainer',
    inputComponent: {
      template:
        '<FilterResultDisplayContainer :emitValueAsObject="true" v-bind="attrsMerged"/>',
      computed: {
        attrsMerged() {
          return {
            ...this.$attrs,
            collectionName:
              this.$attrs.recordRoot.tableName || this.$attrs.recordRoot.baseModel,
          }
        },
      },
    },
  },
  'listItemAttrs_class_no-ellipsis': {
    groupKey: 'colList',
    type: ColumnTypes.Boolean,
    label: '一覧表示時に省略しない',
    displayAsDetail: true,
    editCallback({ row, newValue, oldValue }) {
      // チェック時に minWidth が 設定されていなければ 自動で 280px を設定する
      if (newValue && !row.listItemAttrs_style_minWidth) {
        row.listItemAttrs_style_minWidth = '280px'
      }
      return row
    },
  },
  'listItemAttrs_class_whitespace-wrap': {
    groupKey: 'colList',
    type: ColumnTypes.Boolean,
    label: '一覧表示時に改行を許可',
    displayAsDetail: true,
  },
  listItemAttrs_style_minWidth: {
    groupKey: 'colList',
    type: ColumnTypes.String,
    label: '一覧表示時の幅(最小値)',
    displayAsDetail: true,
    validate: {
      isNumberAndValidUnitForWidth,
    },
    editCallback({ row, newValue, oldValue }) {
      // 数字のみの場合は、単位 px を自動付与する
      row.listItemAttrs_style_minWidth = addPxUnitIfNumberOnly(newValue)
      return row
    },
  },
  listItemAttrs_style_maxWidth: {
    groupKey: 'colList',
    type: ColumnTypes.String,
    label: '一覧表示時の幅(最大値)',
    displayAsDetail: true,
    validate: {
      isNumberAndValidUnitForWidth,
    },
    editCallback({ row, newValue, oldValue }) {
      // 数字のみの場合は、単位 px を自動付与する
      row.listItemAttrs_style_maxWidth = addPxUnitIfNumberOnly(newValue)
      return row
    },
  },
  // listItemAttrs_style_width: {
  //   groupKey: 'colList',
  //   type: ColumnTypes.String,
  //   label: '一覧表示時の幅',
  //   displayAsDetail: true,
  //   validate: {
  //     isNumberAndValidUnitForWidth,
  //   },
  // },
}

const getColumnNamesFromValidationItem = (callerVueInstance: any) => {
  const errorMessageByColumnNameComponent = callerVueInstance.$parent?.$parent?.$parent
  if (!errorMessageByColumnNameComponent) return []
  return errorMessageByColumnNameComponent.record.errorMessageByColumnName.map(
    (item) => item.colName,
  )
}

const openModelPreviewModalButtonComponent = {
  template: `
    <a href="#"
      class="btn btn-outline-secondary cursor-pointer"
      @click.prevent="() => openModelPreviewModal()"
    >
      <ficon type="eye"/>
      フォームをプレビュー
    </a>
  `,
  computed: {
    modelFormComponent() {
      return $core.$utils.findNearestParentVueComponentByName(this, 'ModelForm')
    },
    editingRecord() {
      return this.modelFormComponent?.data
    },
  },
  methods: {
    openModelPreviewModal() {
      try {
        // ココで 一度 ModelDef 形式 に 変換する
        const formattedModelDef =
          $core.$modelDefinitionsLoaderService.formatDBDefinedModelIntoImportableModelDef(
            this.editingRecord,
          )
        this.$core.$modals.openModal({
          modalProps: {
            title: `モデル定義 ${this.editingRecord.tableName} をプレビュー中`,
            noCloseOnEsc: false,
          },
          component: {
            template: `<ModelForm v-bind="modelFormBinds"/>`,
            computed: {
              modelFormBinds() {
                return {
                  columns: formattedModelDef.columns,
                  onSubmitFunction: () =>
                    $core.$toast.infoToast(`プレビューのため 保存はできません`),
                  formColumnGroups: formattedModelDef.formColumnGroups,
                  modelDefOverrideObject: formattedModelDef,
                }
              },
            },
          },
        })
      } catch (error) {
        $core.$toast.errorToast(
          `プレビューを表示できませんでした (データ変換に失敗しました)`,
        )
      }
    },
  },
}
// @ts-ignore
if (globalThis?.$core) {
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.modelDefinitions.create.modelForm.beforeActionButton',
    async (components) => {
      return (components || []).concat([openModelPreviewModalButtonComponent])
    },
    'registerPreviewButton',
  )
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.modelDefinitions.edit.modelForm.beforeActionButton',
    async (components) => {
      return (components || []).concat([openModelPreviewModalButtonComponent])
    },
    'registerPreviewButton',
  )
}

const modelDefinitionColumns: Record<
  keyof Omit<DBDefinedModelDefinitionColumns, 'id'>,
  ColumnDef
> = {
  tableName: {
    label: 'DBテーブル名(物理名)',
    type: ColumnTypes.String,
    validate: {
      notEmpty: true,
      validateAlphaAndUnderscore,
      validateFirstLetterShouldBeAlphabet: (value: any) => {
        if (!/^[a-zA-Z]/.test(value)) {
          return '先頭文字はアルファベットである必要があります'
        }
      },
      doNotUseDeplicatedTableName: (
        value: any,
        col: ColumnDef,
        modelName: string,
        record: any,
        recordRoot: any,
      ) => {
        if (!record?.id && $core.$models[value]) {
          return '既に存在するモデル名です'
        }
      },
    },
    editable: false,
    editableOnCreate: true,
    unique: true,
    listItemAttrs: { class: { 'no-ellipsis': true } },
  },
  tableLabel: {
    label: 'モデル名称(表示名)',
    type: ColumnTypes.String,
    validate: { notEmpty: true },
    width: {
      sm: 24,
    },
    listItemAttrs: { class: { 'no-ellipsis': true } },
  },
  tableComment: {
    groupKey: 'basicTab',
    orderOnForm: 99,
    label: 'テーブル説明',
    type: ColumnTypes.Text,
    inputAttrs: {
      wrapperClass: 'col-12',
    },
  },
  primaryKeyColType: {
    groupKey: 'basicTab',
    label: 'プライマリキータイプ',
    type: ColumnTypes.String,
    defaultValue: ColumnTypes.Number,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ): Promise<any[]> | any[] {
      return selectablePrimaryKeyColumnTypes
    },
    customLabel(val) {
      return selectablePrimaryKeyColumnTypeLabelMap[val] || val
    },
    comment: 'id フィールドのタイプを指定可能です',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    validate: {
      notEmpty: true,
      async customValidate(
        value: any,
        col: ColumnDef,
        modelName: string,
        record: any,
        recordRoot: any,
      ) {
        if (record.id) {
          const model = $core.$models[record.tableName]
          if (value && value !== model.primaryKeyColType) {
            const records = await model.countBy({})
            if (records > 0) {
              return 'データが既に存在するため、変更できません'
            }
          }
        }
      },
    },
  },
  creatable: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    label: '新規作成可能',
    type: ColumnTypes.Boolean,
    defaultValue: true,
    visibleOnIndex: false,
  },
  updatable: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    label: '更新可能',
    type: ColumnTypes.Boolean,
    defaultValue: true,
    visibleOnIndex: false,
  },
  deletable: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    label: '削除可能',
    type: ColumnTypes.Boolean,
    defaultValue: true,
    visibleOnIndex: false,
  },
  enableCheckDuplicateUpdateOnBeforeSave: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    label: 'データ更新時に排他チェックを実行する',
    type: ColumnTypes.Boolean,
    defaultValue: false,
    visibleOnIndex: false,
  },
  replicable: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    label: '複製可能',
    type: ColumnTypes.Boolean,
    defaultValue: false,
    visibleOnIndex: false,
  },
  timestamps: {
    groupKey: 'basicTab',
    virtualColumnOf: 'metaData',
    label: '作成/変更日時の自動追加',
    type: ColumnTypes.Boolean,
    defaultValue: true,
    visibleOnIndex: false,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  defaultSort_key: {
    beforeComponent: '<h4>一覧表示設定</h4>',
    groupKey: 'listConfigTab',
    type: ColumnTypes.String,
    label: 'デフォルト表示順キー',
    comment: 'カラム定義のkeyを指定',
    defaultValue: 'createdAt',
    visibleOnIndex: false,
    dynamicSelections: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ): Promise<any[]> | any[] {
      const defaultKeys = ['createdAt', 'updatedAt', 'id']
      return defaultKeys.concat(record?.columns?.map((col) => col.key))
    },
  },
  defaultSort_order: {
    groupKey: 'listConfigTab',
    type: ColumnTypes.Select,
    label: 'デフォルト表示順ソート',
    selections: () => ['asc', 'desc'],
    visibleOnIndex: false,
    defaultValue: 'desc',
    customLabel(val) {
      const labels = {
        asc: '昇順 (ASC)',
        desc: '降順 (DESC)',
      }
      return labels[val] || ''
    },
  },
  keywordSearchTargetColumns: {
    groupKey: 'listConfigTab',
    type: ColumnTypes.MultiSelect,
    label: 'キーワード検索対象カラム',
    visibleOnIndex: false,
    inputAttrs: { wrapperStyle: 'width: auto;' },
    inputHelpText:
      'リレーション先モデルのカラムを含め、キーワード検索対象とするカラムを指定可能です。指定しない場合は、本モデルの 文字列タイプ もしくは 数値型 のカラムが対象になります。',
    width: { xs: 24 },
    orderOnForm: 20,
    dynamicSelections: true,
    selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
      if (!currentValue) {
        currentValue = []
      }
      // Virtual Model での 編集時にも対応
      const modelName = recordRoot.baseModel || recordRoot.tableName
      const baseModel = $core.$models[modelName]
      if (baseModel) {
        const columnsArray = Object.values(baseModel.columns)
        return getAllNestedColumnsWithRelationsForSelections(columnsArray, currentValue)
      }
      return []
    },
  },
  // validates: {
  //   groupKey: 'validationTab',
  //   label: '複数カラム バリデーション設定',
  //   type: ColumnTypes.ArrayOfObject,
  //   inputAttrs: { wrapperClass: 'col-12' },
  //   minValueLength: 0,
  //   columns: {
  //     name: {
  //       label: 'バリデーション名',
  //       type: ColumnTypes.String,
  //       validate: { notEmpty: true },
  //     },
  //     description: {
  //       label: 'バリデーションルール説明文',
  //       type: ColumnTypes.Text,
  //     },
  //     isDefinedAsFunction: {
  //       label: '関数/高度FIlterの切り替え',
  //       type: ColumnTypes.Boolean,
  //       defaultValue: true,
  //     },
  //     isInvalidCheckFunction: {
  //       label: 'validation関数',
  //       type: ColumnTypes.Text,
  //       enableIf: (row) => row.isDefinedAsFunction === true,
  //       inputAttrs: {
  //         ...codeInputCommonAttrs,
  //       },
  //       width: { xs: 48 },
  //     },
  //     isInvalidCheckConditionObject: {
  //       label: '高度のFilterで設定',
  //       type: ColumnTypes.Text,
  //       inputComponent: 'ModelValidationCondition',
  //       width: { xs: 48 },
  //       enableIf: (row) => row.isDefinedAsFunction !== true,
  //     },
  //     errorMessageByColumnName: {
  //       type: ColumnTypes.ArrayOfObject,
  //       label: 'エラーメッセージセット',
  //       inputAttrs: { wrapperClass: 'col-12' },
  //       columns: {
  //         colName: {
  //           type: ColumnTypes.String,
  //           label: 'エラーメッセージ用カラム名',
  //           dynamicSelections: true,
  //           selections: (
  //             record?: any,
  //             currentValue?: any,
  //             initialValue?: any,
  //             recordRoot?: any,
  //             callerVueInstance?: any,
  //           ) => {
  //             if (recordRoot?.columns) {
  //               const colNames = getColumnNamesFromValidationItem(callerVueInstance)
  //               return recordRoot.columns
  //                 .filter((col) => {
  //                   return !colNames.includes(col.key)
  //                 })
  //                 .map((col) => {
  //                   return {
  //                     value: col.key,
  //                     label: col.label,
  //                   }
  //                 })
  //             }
  //             return []
  //           },
  //         },
  //         errorMessages: {
  //           type: ColumnTypes.ArrayOfObject,
  //           inputAttrs: { wrapperClass: 'col-12' },
  //           label: 'エラーメッセージ一覧',
  //           columns: {
  //             message: {
  //               type: ColumnTypes.String,
  //               label: 'エラーメッセージ',
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // },
  bulkControllable: {
    groupKey: 'basicTab',
    inputAttrs: { wrapperStyle: 'width: auto;' },
    type: ColumnTypes.Boolean,
    label: '一括編集可能',
    defaultValue: true,
    visibleOnIndex: false,
  },
  useBeforeSaveFunction: {
    groupKey: 'advancedTab',
    orderOnForm: 11,
    type: ColumnTypes.Boolean,
    label: 'beforeSave() 処理を利用',
    visibleOnIndex: false,
  },
  beforeSaveFunctionDef: {
    groupKey: 'advancedTab',
    orderOnForm: 11,
    label: 'beforeSave() 処理関数',
    inputHelpText: `async beforeSave(row, index, allSaving) {..., return row} の文脈で実行されます。rowのプロパティを書き換えて下さい。1件保存でも、複数一括保存の場合でも allSaving は 保存しようとするデータすべての配列であり、 row はその中の1件です。`,
    type: ColumnTypes.Text,
    inputAttrs: {
      ...codeInputCommonAttrs,
      placeholder: "if (!row.id) {\n  row.id = md5(row.some + '--' + row.another)\n}",
    },
    enableIf: (row) => !!row.useBeforeSaveFunction,
    visibleOnIndex: false,
  },
  otherModelAttributes: {
    groupKey: 'advancedTab',
    orderOnForm: 10,
    type: ColumnTypes.Text,
    label: 'JS形式のモデルの追加属性定義',
    inputHelpText:
      'オブジェクト形式で記述。Javascriptの記法を利用可能で、他属性を上書きすることが可能です。(editCallbackなどで利用する)',
    inputAttrs: {
      ...codeInputCommonAttrs,
      initCode: `/**
 * @type {ModelDefPartial}
 */
const modelDef = {
  $$cursor
}
return modelDef`,
    },
    visibleOnIndex: false,
    validate: {
      isValidJsObject: true,
    },
  },
  enableSearchConditionSave: {
    groupKey: 'listConfigTab',
    orderOnForm: 15,
    type: 'BOOLEAN',
    label: '検索条件保存機能を有効化',
    virtualColumnOf: 'metaData',
    visibleOnIndex: false,
    comment: '一覧画面にて、検索条件保存機能を有効化します。',
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  enableKeywordSearch: {
    beforeComponent: '<h4>キーワード検索設定</h4>',
    groupKey: 'listConfigTab',
    defaultValue: true,
    orderOnForm: 10,
    type: 'BOOLEAN',
    label: 'キーワード検索機能を有効化',
    virtualColumnOf: 'metaData',
    visibleOnIndex: false,
    inputAttrs: { wrapperStyle: 'width: auto;' },
  },
  doNotSyncModel: {
    groupKey: 'advancedTab',
    orderOnForm: 80,
    width: { xs: 48 },
    type: ColumnTypes.Boolean,
    label: '物理テーブルのカラム定義変更を実施しない',
    inputHelpText:
      'チェックを入れると、カラム定義設定に基づいた物理テーブルの作成 および カラム変更を実施しません。既存テーブルの変更を避けたい場合などに利用します。',
    virtualColumnOf: 'metaData',
    visibleOnIndex: false,
  },
  formStyle_type: {
    label: 'フォームのスタイル',
    type: 'STRING',
    groupKey: 'formStyleDef',
    defaultValue: 'normal',
    selections: () => Object.keys(formStyleTypes),
    customLabel: (val) => formStyleTypes[val] || '',
    virtualColumnOf: 'metaData',
    visibleOnIndex: false,
  },
  metaData: {
    type: 'JSON',
    visible: false,
  },
  columns: {
    groupKey: 'colAccordion',
    label: 'カラム定義',
    beforeComponents: [
      `<HelpDoc  doc-key="model:modelDefinitions.columns.columns">データベースのカラム定義 および カラムごとの一覧表示・入力フォームの設定を実施します。</HelpDoc>`,
    ],
    // inputHelpText: 'データベースのカラム定義 および 入力フォームの設定が可能です',
    hideLabel: true,
    type: ColumnTypes.ArrayOfObject,
    inputAttrs: {
      wrapperClass: 'col-12',
    },
    visibleOnIndex: false,
    columns: columnDefinitionColumns,
  },
  formColumnGroupsAsArray: {
    label: 'カラムグループの定義',
    groupKey: 'formStyleDef',
    inputHelpText:
      'カラムをタブや、アコーディオンでグルーピングして表示することが可能です。',
    visibleOnIndex: false,
    virtualColumnOf: 'metaData',
    width: { xs: 48 },
    type: ColumnTypes.ArrayOfObject,
    minValueLength: 0,
    columns: {
      type: {
        label: 'グルーピングタイプ',
        type: 'STRING',
        selections: () => colGroupTypeKeys,
        defaultValue: 'tab',
        customLabel: (val) => colGroupTypes[val]?.label || '',
        validate: { notEmpty: true },
        inputAttrs: { wrapperStyle: 'width: auto;' },
      },
      key: {
        label: 'キー',
        type: 'STRING',
        validate: { notEmpty: true },
        comment: 'グループ制御のkeyを別にしたい場合に設定',
        inputAttrs: { wrapperStyle: 'width: auto;' },
      },
      label: {
        label: '表示名',
        type: 'STRING',
        validate: { notEmpty: true },
        inputAttrs: { wrapperStyle: 'width: auto;' },
      },
      order: {
        label: '表示順',
        type: 'NUMBER',
        comment: '並び順を制御 (昇順)',
        inputAttrs: { wrapperClass: 'col-12 col-md-1' },
      },
      groupKey: {
        label: 'グループキー',
        type: 'STRING',
        enableIf: (childRow) => childRow.type === 'tab',
        validate: {
          notEmptyIfTabType: (value, col, modelName, record) => {
            if (record.type === 'tab' && !value) {
              return 'タブの場合は指定必須です'
            }
            return null
          },
        },
        comment:
          'タブの場合はタブグループ指定 (必須)。 アコーディオンの場合、開閉状態が同期される。',
        inputAttrs: { wrapperStyle: 'width: auto;' },
      },
      icon: {
        // アイコンタイプ 文字列で選択
        type: 'STRING',
        label: 'アイコン',
        inputAttrs: { wrapperStyle: 'width: auto;' },
        inputComponent: 'IconSelectInput',
      },
    },
  },
  // appHookFunctions: {
  //   groupKey: 'advancedTab',
  //   label: '関連 $appHook 一覧',
  //   // hideLabel: true,
  //   orderOnForm: 333,
  //   doNotSyncModel: true,
  //   type: ColumnTypes.String,
  //   inputComponent: {
  //     template: `<div v-if="model" class="d-flex gap-2 flex-wrap small">
  //       <div class="_mt-1 _small _text-muted w-100">appHookName: <code>{{appHookName}}</code> で登録すると、Model定義を Load した直後に Hook を利用して Javascript を実行することが可能です。</div>
  //       <a href="#" class="btn btn-outline-primary btn-sm" @click.prevent="() => openRelatedFrontSideAppHooks()"><ficon type="external-link-alt"/> 一覧表示</a>
  //       <a href="#" class="btn btn-outline-primary btn-sm" @click.prevent="() => createRelatedFrontSideAppHooks()"><ficon type="plus"/> 新規作成</a>
  // </div>`,
  //     props: { record: {} },
  //     computed: {
  //       modelName() {
  //         return this.record.tableName
  //       },
  //       model() {
  //         return $core.$models[this.modelName]
  //       },
  //       appHookName() {
  //         return `$CORE.ModelFactory.${this.modelName}.init.after`
  //       }
  //     },
  //     methods: {
  //       openRelatedFrontSideAppHooks() {
  //         $core.$modals.openListViewModal({
  //           modelName: 'frontSideAppHooks',
  //           filters: {
  //             appHookName: this.appHookName,
  //           },
  //           otherComponentProps: {
  //             enableAddNewRecordButton: false,
  //           }
  //         })
  //       },
  //       createRelatedFrontSideAppHooks() {
  //         $core.$modals.openCreateViewModal({
  //           modelName: 'frontSideAppHooks',
  //           defaultValues: {
  //             appHookName: this.appHookName,
  //           },
  //         })
  //       }
  //     }
  //   },
  //   width: { xs: 48 },
  //   visibleOnIndex: false,
  // },
}

export const modelDefColumnGroupTabs: ModelDefFormColumnGroups = {
  colBase: {
    type: 'tab',
    label: '入力設定',
    icon: 'edit-2',
    groupKey: 'colTabGroup1',
    order: 6,
  },
  colStyle: {
    type: 'tab',
    label: '入力フォームのスタイル',
    groupKey: 'colTabGroup1',
    icon: 'pen',
    order: 6,
  },
  colValidations: {
    type: 'tab',
    label: '入力値チェック設定',
    icon: 'check-double',
    groupKey: 'colTabGroup1',
    order: 6,
  },
  colList: {
    type: 'tab',
    label: '一覧・検索設定',
    icon: 'filter',
    groupKey: 'colTabGroup1',
    order: 10,
  },
  colSelection: {
    type: 'tab',
    label: '選択肢の設定',
    groupKey: 'colTabGroup1',
    icon: 'list',
    order: 7,
  },
  colAdvanced: {
    type: 'tab',
    label: '高度な設定',
    groupKey: 'colTabGroup1',
    icon: 'cog',
    order: 80,
  },
}

const isValidColumnDef = (col: DBDefinedModelDefinitionColumns['columns'][0]) => {
  if (!col.key || !col.type) {
    return false
  }
  if (isValidColumnName(col.key)) {
    return false
  }
  if (['created_at', 'updated_at'].indexOf(col.key) >= 0) {
    return false
  }
  return true
}

export const modelDefinitions: ModelDef = {
  tableName: 'modelDefinitions',
  tableLabel: 'モデル定義',
  tableComment: '',
  primaryKeyColType: 'UUID',
  timestamps: true,
  defaultSort: { key: 'tableName', order: 'asc' },
  formColumnGroups: {
    colAccordion: {
      type: 'tab',
      label: 'カラム定義',
      groupKey: 'tabGroup1',
      icon: 'columns',
      order: 20,
    },
    formStyleDef: {
      type: 'tab',
      label: 'フォームのスタイル設定',
      groupKey: 'tabGroup1',
      icon: 'pen',
      order: 23,
    },
    basicTab: {
      type: 'tab',
      label: '基本設定',
      groupKey: 'tabGroup1',
      icon: 'list',
      order: 10,
    },
    listConfigTab: {
      type: 'tab',
      label: '一覧表示・検索設定',
      groupKey: 'tabGroup1',
      order: 60,
      icon: 'filter',
    },
    validationTab: {
      type: 'tab',
      label: '入力値チェック設定',
      groupKey: 'tabGroup1',
      order: 80,
      icon: 'check',
    },
    advancedTab: {
      type: 'tab',
      label: '高度な設定',
      groupKey: 'tabGroup1',
      order: 100,
      icon: 'cog',
    },
    ...modelDefColumnGroupTabs,
  },
  columns: modelDefinitionColumns,
  async beforeSave(row: DBDefinedModelDefinition): Promise<any> {
    row.tableName = row.tableName.trim()
    // columns のうち Invalid なものを排除
    const colDefaultValues = await createNewRecordWithColumnDefs(
      $core.$models.modelDefinitions.columns.columns.columns,
    )
    const defaultValues = await $core.$models.modelDefinitions.createNew()
    const colNames: string[] = []
    const formatColumn = (col) => {
      col = mergeColProps({
        ...colDefaultValues,
        ...col,
      })
      return col
    }
    row = {
      ...defaultValues,
      ...row,
      columns: row.columns.reduce((cols, col) => {
        col.key = col.key?.trim()
        if (isValidColumnDef(col)) {
          cols.push(formatColumn(col))
          colNames.push(col.key)
        }
        return cols
      }, []),
    }

    const primaryKeyCol = findPrimaryKeyColFromModelDefinitionData(row)
    const primaryKeyColName = primaryKeyCol?.key || 'id'
    /**
     * primaryKeyColType が String の場合 かつ primaryKey が 設定されていない場合には id カラムを追加する
     */
    if (row.primaryKeyColType === ColumnTypes.String) {
      if (!primaryKeyCol) {
        row.columns = [
          {
            key: 'id',
            type: 'STRING',
            editable: false,
            editableOnCreate: true,
            unique: true,
            validationConfigs: [
              {
                validationType: 'notEmpty',
              },
            ],
          },
          ...row.columns,
        ]
      }
    }
    // if primaryKeyColType is not String, remove the primaryKeyColumn from row.columns to avoid problems on the ui when creating new records
    if (row.primaryKeyColType !== ColumnTypes.String) {
      if (!primaryKeyCol) {
        row.columns = row.columns.filter((col) => col.key !== 'id')
      }
    }
    // defaultSort の自動修正
    const validSortKeys = [
      ...colNames,
      ...(row.timestamps ? ['createdAt', 'updatedAt'] : []),
    ]

    if (!validSortKeys.includes(row.defaultSort_key)) {
      row.defaultSort_key = primaryKeyColName
      row.defaultSort_order = 'desc'
    }

    return row
  },
  afterSave: async (saved: any): Promise<boolean> => {
    // modelDefinitionsLoaderServiceを使用して$modelsをリロード
    await $core.$modelDefinitionsLoaderService.loadModelDefinitions([saved.tableName])
    // For component re-rendering on next frame
    setTimeout(() => {
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
    return true
  },
  afterDelete() {
    setTimeout(() => {
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
  },
  modelType: 'admin',
  bulkControlActions: {
    exportAsTypeDefs: {
      label: 'Type定義をエクスポート',
      function: async ({ modelName, virtualModelName, targetIds }) => {
        // @ts-ignore
        const models = Object.values($core.$models).filter(
          // @ts-ignore
          (model) => model.id && targetIds.includes(model.id),
        )
        $core.$modelsLoader.generateModelTypeDefinitionsAndDownloadAsFile(models)
      },
    },
  },
}

// _ 区切りを利用している column name を 取得
const mergeTargetColPropsNames: string[] = Object.keys(modelDefinitionColumns).filter(
  (colName) => colName.indexOf('_') >= 0,
)
const mergeColProps = (col) => {
  mergeTargetColPropsNames.forEach((colName) => {
    const nestingKeys = colName.split('_')
    // nestingKeys で指定された階層までの 値があるなら...
    const val = nestingKeys.reduce((val, key, index) => {
      if (typeof val === 'object' && val !== null && val?.[key] !== undefined) {
        const _v = val[key]
        delete val[key]
        return _v
      }
      return val?.[key]
    }, col)
    if (val !== undefined) {
      col[colName] = val
    }
  })
  return col
}

// Model 一覧に表示される Docs を追加
addModelDoc(
  'modelDefinitions',
  'モデル定義では、システムの基礎となるデータベースの構造を定義します。また、入力フォームの設定・データの一覧表示時の挙動を設定します。',
)

export type DBDefinedModelDefinition = Partial<DBDefinedModelDefinitionColumns>
